import React from "react";
// Customizable Area Start
import { Box,Grid ,Tabs,Tab,IconButton,CardContent,Card,Button} from "@mui/material";
import { styled } from "@mui/material/styles"; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {  arrow_Arrow_arrow_down, arrow_Arrow_up, award_a, award_a1, award_a2, award_a6, awrad_Award, flower_flower, glass_glass, hook_Hook, Menu_M, pizza_pizza, plus_Plus, wava_right } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../../blocks/dashboard/assets/style.css"
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
const cardsDataSafe = [
    {
      id: 1,
      title: 'Count Drinks',
      description: 'Keep track of # of drinks',
      dateRange: '5/1 - 5/15',
      icon: <img src={glass_glass.default} alt="Count Drinks" />, // Ensure `glass_glass` is imported correctly
    },
    {
      id: 2,
      title: 'Try to eat',
      description: 'Eat 8 meals',
      dateRange: '5/2 - 5/16',
      icon: <img src={pizza_pizza.default} alt="Try to eat" />, // Ensure `pizza_pizza` is imported correctly
    },
  ];
const WhiteBackgroundContainerSte = styled('div')({
  backgroundColor: 'white', 
  padding: "2rem", 
   height:"auto",
  marginLeft:"1rem",
  marginRight:"1rem",
  borderRadius:"16px" 
});


 
// Customizable Area End
import SaferuseStrategiesController, {
  Props,
  configJSON
} from "./SaferuseStrategiesController.web";




export default class SaferuseStrategies extends SaferuseStrategiesController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


   icons = [
        <img src={award_a.default} alt="awrad_award" />,
        <img src={award_a1.default} alt="awrad_award" />,
        <img src={award_a2.default} alt="awrad_award" />,
    ];
    cardShowdata = () =>{
        return(
            <>
          
                <Grid container spacing={2}>

                    {cardsDataSafe.map((card: any, index: any) => (
                        <Grid
                            item
                          
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={index}
                        >
                            <Card variant="outlined" style={{ position: 'relative', borderRadius: '8px', maxWidth: '100%', width: '310px' }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem" }}>
                                            <Box style={{ width: '40px',borderRadius: '8px', border: "1px solid #E3E3E3" }}>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>  {card.icon} </span>
                                            </Box>
                                            <Box>
                                                <span style={webStyles.drinksStyletitle}>{card.title}</span>
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                     fontFamily:'Chromatica-Regular' ,
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        color: '#252525',
                                        marginTop: "10px"
                                    }}>
                                        {card.description}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                        marginTop: 8,
                                        color: "#00A7D1",
                                       fontFamily:'Chromatica-Bold' ,
                                        fontSize: '16px',
                                        fontWeight: 700,
                                        lineHeight: '20px',


                                    }}>
                                        {card.dateRange}
                                    </Typography>
                                    <Box>
                                     
                                       {
                                        this.state.tabValue === 1 && 
                                        <>
                                          <Box sx={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                          <Box style={{ marginTop: '1rem' }}>
                                                <button className={
                                                        this.state.showAchievedStrategy === card.id ? 'activebtn' : 'defaultbtn'
                                                      }
                                                      onClick={() => this.handleToggle(card.id)}
                                               
                                                >
                                                    <Typography sx={webStyles.achievegoalstext}>
                                                    {this.state.showAchievedStrategy === card.id ? 'Strategy Achieved' : 'Achieved Strategy'}
                                                    </Typography>
                                                </button>
                                            </Box>
                                            </Box>
                                            </>
                                       }
                                       <Box> 
                                    
                                    </Box>
                                           
                                    </Box>
                                </CardContent>
                                <IconButton style={{ position: 'absolute', right: 8, top: 16, color: 'black' }}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
</Grid>
            </>
        )
    }
    subTabsinalcohoandall = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{ borderColor: 'divider', backgroundColor: "#F7F7F7"}}>

                            <Tabs
                                value={this.state.selectsafeValues}
                                onChange={this.handleStretigies}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="substance tabs"

                                TabIndicatorProps={{
                                    style: { display: 'none' } // Hide default indicator
                                }}
                            >
                                <Tab
                                    icon={<img src={awrad_Award.default} style={{ width: "16px", height: '28px' }} />}
                                    label="Alcohol"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                      alignItems:"stretch",
                                        flexDirection: 'row',                                   
                                       fontFamily:'Chromatica-Bold' ,
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '28px',
                                        color: "$252525",
                                        justifyContent: 'flex-start',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5',
                                        borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectsafeValues === 0 ? 'white' : 'transparent', // Background red when selected
                                        borderTopColor: this.state.selectsafeValues === 0 ? '#E5E5E5' : 'transparent', // Top border color when selected
                                        borderRightColor: this.state.selectsafeValues === 0 ? '#E5E5E5' : 'transparent', // Right border color when selected
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        // Adjust height
                                        padding: '8px 12px', // Adjust padding
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }// Smooth transition

                                    }}
                                />
                                <Tab
                                    icon={<img src={flower_flower.default} style={{ width: "37px", height: '37px',marginTop:"-6px"  }} />}
                                    label="Cannabis"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                        color: "$252525",
                                        flexDirection: 'row',
                                        alignItems:"stretch",
                                        justifyContent: 'flex-start',
                                       fontFamily:'Chromatica-Bold' ,
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px', borderBottom:"1px solid #E5E5E5",
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5',
                                        borderRadius: "1px 8px 0px 8px",
                                        backgroundColor: this.state.selectsafeValues === 1 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectsafeValues === 1 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectsafeValues === 1 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                            borderBottom:"none",
                                        }
                                    }}
                                />
                                <Tab
                                    icon={<img src={hook_Hook.default} style={{ width: "35px", height: '35px',marginTop:"-6px"   }} />}
                                    label="Tobacco or Nicotine"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                        color: "$252525",
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems:"stretch",
                                       fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectsafeValues === 2 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectsafeValues === 2 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectsafeValues === 2 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                                <Tab
                                    icon={<img src={arrow_Arrow_up.default} style={{ width: "34px", height: '34px',marginTop:"-6px"   }} />}
                                    label="Uppers"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        color: "$252525",
                                        alignItems:"stretch",
                                        justifyContent: 'flex-start',
                                       fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectsafeValues === 3 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectsafeValues === 3 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectsafeValues === 3 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                                <Tab
                                    icon={<img src={arrow_Arrow_arrow_down.default} style={{ width: "34px", height: '34px',marginTop:"-6px"  }} />}
                                    label="Downers"
                                    iconPosition="start"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        color: "$252525",
                                        alignItems:"stretch",
                                       fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
                                        textTransform: "none",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        justifyContent: 'flex-start',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectsafeValues === 4 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectsafeValues === 4 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectsafeValues === 4 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                                <Tab
                                    icon={<img src={plus_Plus.default} style={{ width: "34px", height: '34px',marginTop:"-6px"   }} />}
                                    label="Others"
                                    iconPosition="start"

                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                       fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
                                        textTransform: "none",
                                        color: "$252525",
                                        alignItems:"stretch",
                                        fontSize: '16px',
                                        fontWeight: 400,
                                        lineHeight: '20px',
                                        justifyContent: 'flex-start',
                                        borderTop: '1px solid #E5E5E5',
                                        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                        backgroundColor: this.state.selectsafeValues === 5 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectsafeValues === 5 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectsafeValues === 5 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                           fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                            </Tabs>
                       

                        </Box>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.selectsafeValues === 0 && <><this.cardShowdata/></>}
                            {this.state.selectsafeValues === 1 && <><div>ddddddd</div></>}
                        </Box>
                    </Grid>
                </Grid>
  
        </>
    )
   }
HeaderFunctionSte = () =>{
    return (
        <>
         <Grid
          item
          style={{
           ...webStyles.wholeGridStylestra
          }}
        >
          <Box
                        sx={{                  
                          ...webStyles.wholedivStylestra}}>
                        <Box
                            sx={{
                              ...webStyles.gapMstra
                            }}
                        >
                            <Box
                                sx={{
                                  ...webStyles.parentWellstra
                                }} >
                                <span
                                    style={{
                                      ...webStyles.well__wellStylesstra
                                    }}
                                >
                                    Well
                                </span>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                  ...webStyles.homeStylemenustra
                                   
                                }}
                            >
                                Hey, Tom Chandler</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenusafestr() }}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "70px",
                            }}
                            alt="icon"      
                        />
                    </Box>
        </Grid>
        </>
    )
}
    tabThisWeekfunctionbox = () =>{
    return (
        <>
            <Box>
                <Box style={{marginTop:"1rem"}}>
                    <Typography sx={webStyles.Statisticsstyles}>
                        Statistics
                    </Typography>
                </Box>
                <Box style={{ marginTop: "1rem" }}>
                    <Typography sx={webStyles.youaidYourStyleboxs}>This week, you said your Goals are to:</Typography>
                </Box>
               

     
                
        </Box>
        </>
    )
   }
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    
   
    return (
      //Merge Engine DefaultContainer
  <>
   <Grid  item
        style={{
          padding: '10px',
          background: '#f0f0f0',
        }}>  {this.HeaderFunctionSte()}</Grid>

        <WhiteBackgroundContainerSte>
        <img src={wava_right.default} style={{position:"absolute",top:"127px",right:0}}/>
                <Box sx={{display:"flex",justifyContent:'flex-start',alignItems:"center",gap:'0.5rem', padding: "1rem 0", }}
            onClick={() => { this.goToBackDashboards() }}
                >
                    <KeyboardBackspaceIcon />
                     <Typography style={webStyles.backStylebox}>Back</Typography>
                </Box>
               <Box style={{marginTop:"0.9rem"}}>
               <Box style={webStyles.addGoalsStylebox}>
                    <Box>
                         <Typography sx={webStyles.goalsStyleleftbox}>Safer-use Strategies</Typography>
                    </Box>
                    <Box >
                        <button
                        className="buttonStyle"
                        >
                           <div style={webStyles.iconflexbox}>
                           <Typography sx={webStyles.addGoalsStyleboxAddbox}>Add More Safer-use Strategies</Typography>
                           <AddCircleOutlineIcon style={{width:"16px",height:"16px",color:"#DADADA"}}/>
                           </div>
                        </button>
                     </Box>
                </Box>
               </Box>

             

                <Box>
                    <Box sx={{ width: '100%' }}>
                   
                        <Tabs
                            value={this.state.tabValue}
                            onChange={this.handleChangeSte}
                            aria-label="basic tabs example"
                            sx={{ borderBottom: 2, borderColor: 'divider',color:"#E3E3E3" }}
                            TabIndicatorProps={{
                                style: {
                                     backgroundColor: '#00A7D1', // Custom indicator color
                                },
                            }}
                        >
                            <Tab
                                label="This week"
                                sx={{
                                   fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#A0A0A0", // Text color
                                    textTransform: "none",
                                    '&.Mui-selected': {
                                        color: "#000",
                                       fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                        // Text color // Style for the selected tab
                                    }
                                }}
                            />
                            <Tab
                                label="Last week"
                                sx={{
                                   fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,  // Numeric value for font-weight
                                    lineHeight: "20px",

                                    textTransform: "none",
                                    color: "#A0A0A0", // Text color
                                    '&.Mui-selected': {
                                        color: "#000",
                                       fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px", // Style for the selected tab
                                    }
                                }}
                            />

                        </Tabs>
                        <Box>
            {this.tabThisWeekfunctionbox()}
            </Box>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.tabValue === 0 && <this.subTabsinalcohoandall/>}
                            {this.state.tabValue === 1 && <this.subTabsinalcohoandall/>}
                        </Box>
                    </Box>
                </Box>

              

          
          
            </WhiteBackgroundContainerSte>
            <Dialog onClose={this.handleClosesaferToggle} open={this.state.openCongratessafer}
                PaperProps={{
                    style: { borderRadius: 24 }, // Add border radius here
                }}
            >
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', // To center text as well
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                width: '80px',
                                height: '80px',
                                boxShadow: '0px 0px 18px 0px #F3A622',
                                background: '#F3A622',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '16px',
                                marginTop: "2rem", // Adds spacing below the image
                            }}
                        >
                            <div
                                style={{
                                  ...webStyles.submissonBox

                                }}
                            >
                                <img
                                    src={award_a6.default}
                                    alt="award_award"
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                        </Box>

                        <Typography sx={webStyles.Congratulationsstyleinasfer}>
                            Congratulations!
                        </Typography>
                        <Typography sx={webStyles.countingsaferStyle}>{configJSON.Yourcountingdrinksgoal}<br /> is achieved.</Typography>
                    </Box>

                    <IconButton
                        aria-label="close"
                        sx={{
                          ...webStyles.closeStyle
                        }}
                        onClick={this.handleClosesaferToggle}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>

          
          

  </>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    closeStyle:{
        position: 'absolute',
        right: 8,
        top: 8,
    },
  submissonBox:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },

  
  

   
  
    AchievedStyleget:{
        padding: "0.4rem",     
        width: "278px",          
        borderRadius: "8px",     
        border: "none",           
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
    },
   StylegetAchieved:{
        padding: "0.4rem",     
        width: "191px",          
        borderRadius: "26px",     
        border: "none",           
        background: "linear-gradient(99.09deg, #004463 2.64%, #00A7D1 100%)"
    },
    drinksStyletitle:{
        fontFamily:'Chromatica-bold' ,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
    },
    topparentGridsBox:{
        padding: '10px',
        backgroundColor: '#f0f0f0',
    },
    parentBoxes:{
        right: 0,
        marginRight: '30px',
        position: "absolute",
        top: 0,
    },
    heyToStyle:{
        position: "absolute",
        top: "20px",
        left: "260px",
        fontSize: "17px",
        width: '180px',
        color: "white",
        fontFamily: 'Chromatica-Bold',
    },
    SpringStyles:{
        fontSize: "40px", fontWeight: "normal", marginLeft: "5px" 
    },
    wellStyles:{
        fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px"
    },
    countingsaferStyle:{
        fontFamily:'Chromatica-Regular' ,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '24px',
      color:"#252525",
      marginBottom:"3rem",
      marginTop:"1rem"
    },
    Congratulationsstyleinasfer:{
        fontFamily: 'Josefin Sans',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        color:'#00A7D1',
    },
 
    childGrids:{
        color: "#ffffff",
        fontFamily: 'Josefin Sans',
        position: "absolute",
        top: "36px",
        left: '48px',
        width:'95%'
    },

    parentGrids:{
        height: '88px',
        backgroundColor: '#0F4257',
        margin: '10px',
        borderRadius: '16px',
        width: '99%',
    },
    youaidYourStyleboxs:{
      fontFamily:'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color:"#252525"
    },
    Statisticsstyles:{
      fontFamily:'Chromatica-Regular',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        color:"#000000"
    },
    iconflexbox:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:'0.5rem'
    },
    buttonStylebox:{
        width: "194px",         
        padding:"0.5rem",            
        borderRadius: "8px 0 0 0", 
        opacity: 0
    },
    integrationBox:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:'0.4rem'
    },
    goalsStyleleftbox:{
       fontFamily: "Josefin Sans",
        fontSize: "32px",
        fontWeight: 600, // Numeric value for font-weight
        lineHeight: "32px",
        color:"#00A7D1"
    },
    AchievedStyle:{
        padding: "0.4rem",     
        width: "131px",          
        borderRadius: "8px",     
        border: "none",           
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
      },
    addGoalsStyleboxAddbox:{
       fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "24px",
        color:'#ffffff',
       
    },
  
    stilStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color:"#1D5E79",
        
     },
     achievegoalstext:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
        color:"white",
        textAlign:'center'
        
     },
   
    addGoalsStylebox:{
        display:"flex",justifyContent:'space-between',alignItems:"center"
    },
    backStylebox:{
       fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "20px"
    },
    flexpropertybox: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
  },
    testStyleforHeyTombox: {
       fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#ffffff",
        marginLeft: "1.3rem"
    },
   
  threebythreeStylebox:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    
  },
  flexBoxparent:{
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  labelstylestepperbox:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStylebox:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStylebox:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1"

},












wholedivStylestra:{
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    padding: "0 20px",
    height: "100%"
  },
  wholeGridStylestra:{
    height: '88px',
    backgroundColor: '#0F4257',
    borderRadius: '16px',
    width: '98.5%',
    margin: '10px',
  },
  well__wellStylesstra:{
    marginLeft: "40px",
    fontFamily: "Josefin Sans",
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#EBFF00",
    fontSize: "40px",
   },
   homeStylemenustra:{
    mt: "3px",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#FFFFFF",
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
  },
  gapMstra:{
    gap: "20px",
    display: "flex",
    alignItems: "center",
  },
  parentWellstra:{
    fontFamily: "Josefin Sans",
    color: "#ffffff",
    fontSize: "40px",
    fontWeight: 300,
  },
};

// Customizable Area End