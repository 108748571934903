// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Box,
  Button,
  Typography,
  Grid,
  Tabs,
  Tab,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import * as Yup from "yup";
import Select, { ValueType } from "react-select";
import { Edit as EditIcon, Close as CloseIcon } from "@mui/icons-material";
import { styled } from "@mui/styles";
import { Formik, FormikErrors, FormikTouched } from "formik";
import Spinner from "./components/Spinner.web";
import { arrow, menuIcon, textLabel, alcohol, cannabis, tobacco, uppers, downers, others ,substanceBottom,info, button_add, button_minus, loginArrow, arrowright, calendar,} from "./assets";
import CustomFormController, {
  Props,
  Touched,
  Error,
  Dropdown,
  Data,
} from "./CustomFormController.web";
import AlcohalSubstance from "../../goalmanagement/src/AlcohalSubstance.web";
import OtherSubstance from "../../goalmanagement/src/OtherSubstance.web";
import Cannabis from "../../goalmanagement/src/Cannabis.web";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  tobacoMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "153px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobacoMeasureType === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobacoMeasureType === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobacoMeasureType"
        name="tobacoMeasureType"
        value={this.state.tobacoMeasureType}
        onChange={(e) => this.setState({ tobacoMeasureType: e.target.value })}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        data-test-id="tobacoMeasureType"
      >
        <Box sx={getStyle("Cigarettes")}>
          <FormControlLabel
            value="Cigarettes"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Cigarettes"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetTobaccoOthersTextValue}
            data-test-id="resetTobaccoOthersTextValue"
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Others"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoReduceState = () => {
    const getStyle = (value: string) => ({
      width: "227px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobacoReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobacoReduceState === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobacoReduceState"
        name="tobacoReduceState"
        value={this.state.tobacoReduceState}
        onChange={(e) => this.setState({ tobacoReduceState: e.target.value })}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
          zIndex: "2",
        }}
        data-test-id="tobacoReduceState"
      >
        <Box sx={getStyle("reduceHowMuch")}>
          <FormControlLabel
            value="reduceHowMuch"
            onClick={this.resetTobaccoHowOften}
            data-test-id="resetTobaccoHowOften"  
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Reduce how much"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("reduceHowOften")}>
          <FormControlLabel
            value="reduceHowOften"
            onClick={this.resetTobaccoSafer}
            data-test-id="resetTobaccoSafer" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Reduce how often"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("Safer/healthier")}>
          <FormControlLabel
            value="Safer/healthier"
            onClick={this.resetTobaccoStopCompletely}
            data-test-id="resetTobaccoStopCompletely" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Safer/healthier"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("stopCompletely")}>
          <FormControlLabel
            value="stopCompletely"
            onClick={this.resetTobaccoOthers}
            data-test-id="resetTobaccoOthers" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Stop completely"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            onClick={this.resetTobaccoHowMuch}
            data-test-id="resetTobaccoHowMuch" 
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Other"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  cigarettesReduceType = () => {
    const getStyle = (value: string) => ({
      width: value === "numberOfCigarettes" ? "240px" : "153px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.cigarettesReduceType === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.cigarettesReduceType === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="cigarettesReduceType"
        name="cigarettesReduceType"
        value={this.state.cigarettesReduceType}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
      >
        <Box sx={getStyle("numberOfCigarettes")}>
          <FormControlLabel
            value="numberOfCigarettes"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Number of Cigarettes"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetNumberOfCigarettesValue}
            data-test-id="resetNumberOfCigarettesValue"
          />
        </Box>
        <Box sx={getStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Others"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetTobaccoreduceHowMuchTextValue}
            data-test-id="resetTobaccoreduceHowMuchTextValue"
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoReducsHowMuch = () => {
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#F5F5F5",
            p: "8px 16px",
            zIndex: "2",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
            gap: "10px",
            mt: "15px",
          }}
        >
          <Box
            component="img"
            src={info.default}
            alt="info"
            sx={{ width: "24px", height: "24px" }}
          />
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
            }}
          >
            When you think about reducing, how would you want to measure that?
            ex., number of cigarettes, other [please type in]? [choose one]
          </Typography>
        </Box>

        {this.cigarettesReduceType()}
        {this.state.cigarettesReduceType === "numberOfCigarettes" ? (
          <>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Cigarettes did you have on a typical
              day?
            </Typography>
            <Box
              sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
                onClick={() =>
                  this.handleCountChange("lastWeekCigarettesCount", -1)
                }
                data-test-id="lastWeekCigarettesCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                {this.state.lastWeekCigarettesCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                data-test-id="lastWeekCigarettesCountAdd"
                onClick={() =>
                  this.handleCountChange("lastWeekCigarettesCount", 1)
                }
              />
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#252525",
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex",  mt: "15px" , width: "40%", height: "30px",}}
            >
              <Box
                component="img"
                onClick={() =>
                  this.handleCountChange("nextweekCigarettesReduceCount", -1)
                }
                src={button_minus.default}
            
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
                alt="button_minus"
                data-test-id="nextweekCigarettesReduceCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                {this.state.nextweekCigarettesReduceCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                onClick={() =>
                  this.handleCountChange("nextweekCigarettesReduceCount", 1)
                }
                data-test-id="nextweekCigarettesReduceCountAdd"
              />
            </Box>
          </>
        ) : (
          <TextField
            placeholder="Enter here..."
            multiline
            value={this.state.tobaccoreduceHowMuchTextValue}
            onChange={this.handleInputChange}
            name="tobaccoreduceHowMuchTextValue"
            InputProps={{
              disableUnderline: false,
              sx: {
                height: "58px",
                "&:after": {
                  borderBottom: "1px solid #E3E3E3",
                },
                ".MuiInputBase-input": {
                  pt: "20px",
                },
                "&:before": {
                  borderBottom: "1px solid #E3E3E3",
                },
                "&:hover:not(.Mui-disabled):before": {
                  borderBottom: "1px solid #E3E3E3",
                },
              },
            }}
            inputProps={{
              style: {
                fontSize: "16px",
                fontFamily: "Chromatica-Regular",
                fontWeight: 500,
              },
              sx: {
                "&::placeholder": {
                  fontSize: "16px",
                  color: "#939393",
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                },
              },
            }}
            rows={1}
            variant="standard"
            fullWidth
            sx={{
              width: "286px",
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
              fontWeight: 500,
              lineHeight: "24px",
              mt: "20px",
              color: "#939393",
            }}
          />
        )}
      </>
    );
  };

  TobaccoReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the past 7 days, how many days did you use [what they entered
          in supplement ex. cigarettes, vapes, etc]
        </Typography>
        <Box sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr: "8px",width:'32px',height:'32px'
            }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysCigarettesCount", -1)
            }
            data-test-id="pastSevenDaysCigarettesCountMinus"
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              width: "51px",
              height: "32px",
              borderRadius: "4px",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            {this.state.pastSevenDaysCigarettesCount}
          </Box>
          <Box
            component="img"
            src={button_add.default}
            alt="button_add"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysCigarettesCount", 1)
            }
            data-test-id="pastSevenDaysCigarettesCountAdd"
          />
        </Box>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the NEXT 7 days, how many days do you want to use [what they
          entered in supplement]?
        </Typography>
        <Box sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr: "8px",width:'32px',height:'32px'
            }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysCigarettesCount", -1)
            }
            data-test-id="nextSevenDaysCigarettesCountminus"
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              width: "51px",
              height: "32px",
              borderRadius: "4px",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            {this.state.nextSevenDaysCigarettesCount}
          </Box>
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysCigarettesCount", 1)
            }
            data-test-id="nextSevenDaysCigarettesCountadd"
          />
        </Box>
      </>
    );
  };

  tobaccoStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.isTakingBreakCigarettes == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.isTakingBreakCigarettes == value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="isTakingBreakCigarettes"
        name="isTakingBreakCigarettes"
        value={this.state.isTakingBreakCigarettes}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        data-test-id="isTakingBreakCigarettes"
      >
        <Box sx={getStyle("true")}>
          <FormControlLabel
            value={"true"}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Yes"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("false")}>
          <FormControlLabel
            value={"false"}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="No"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  tobaccoBreakDate = () => {
    const getStyle = (value: string) => ({
      p: "8px 12px",
      border: `1px solid ${
        this.state.tobaccoBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.tobaccoBreakSelectedDate == value
          ? "#EFF8FB"
          : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="tobaccoBreakDay"
        name="tobaccoBreakDay"
        value={this.state.tobaccoBreakSelectedDate}
        onChange={this.handleTobaccoBreakDateChange}
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
          zIndex: "2",
        }}
        data-test-id="tobaccoBreakDay"
      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={getStyle(date.format("YYYY-MM-DD"))}>
            <FormControlLabel
              key={index}
              value={date.format("YYYY-MM-DD")}
              control={
                <Radio
                  sx={{
                    "&.Mui-checked": {
                      color: "#00A7D1",
                    },
                    color: "#E3E3E3",
                  }}
                />
              }
              label={
                index === 0
                  ? `Today, ${date.format("ddd D MMMM")}`
                  : date.format("ddd, D MMMM")
              }
              componentsProps={{ typography: typographyProps }}
            />
          </Box>
        ))}
        <Box sx={getStyle("OtherDay")}>
          <FormControlLabel
            value="OtherDay"
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            label="Other day"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  TobaccoStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
            maxWidth: "890px",
            minWidth:'400px',
            height: "48px",
          }}
        >
          This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from [what they entered in supplement] at this point?
        </Typography>
        {this.tobaccoStopCompletelyMeasureType()}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Way to commit to taking a break from [what they mentioned in
          supplement]! When day this week do you want to start?
        </Typography>
        {this.tobaccoBreakDate()}
        {this.state.tobaccoBreakSelectedDate == "OtherDay" && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              width: "286px",
              borderBottom: "1px solid #E3E3E3",
            }}
          >
            <input
              type="text"
              style={{
                border: "none",
                width: "100%",
                fontFamily: "Chromatica-Regular",
                fontSize: "16px",
                padding: "10px 0",
                outline: "none",
                marginRight: "10px",
                backgroundColor: "transparent",
              }}
              placeholder="Choose Day"
              value={this.state.tobaccoCalendarSelectedDate || ""}
              readOnly
            />
            <img
              src={calendar.default}
              alt="Open Calendar"
              style={{ cursor: "pointer", padding: "10px" }}
            />
          </div>
        )}
      </>
    );
  };

  TobaccoOthers = () => {
    return (
      <>
        <Typography
          sx={{
            mt: "30px",
            zIndex: "2",
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
          }}
        >
          What do you want to see happen with your use?
        </Typography>
        <TextField
          placeholder="Type here..."
          multiline
          value={this.state.tobaccoOthersTextValue}
          onChange={this.handleInputChange}
          name="tobaccoOthersTextValue"
          InputProps={{
            disableUnderline: false,
            sx: {
              "&:after": {
                borderBottom: "1px solid #E3E3E3",
              },
              ".MuiInputBase-input": {
                paddingBottom: "5px",
              },
              "&:before": {
                borderBottom: "1px solid #E3E3E3",
              },
              "&:hover:not(.Mui-disabled):before": {
                borderBottom: "1px solid #E3E3E3",
              },
            },
          }}
          inputProps={{
            style: {
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
            },
            sx: {
              "&::placeholder": {
                fontSize: "16px",
                color: "#939393",
                fontFamily: "Chromatica-Bold",
                fontWeight: 700,
              },
            },
          }}
          sx={{
            fontWeight: 700,
            lineHeight: "24px",
            mt: "25px",
            color: "#939393",
            width: "816px",
            height: "145px",
            fontSize: "16px",
            fontFamily: "Chromatica-Regular",
          }}
          rows={5}
          variant="standard"
          fullWidth
        />
      </>
    );
  };

  selectedTobaccoReduceState = () => {
    const { tobacoReduceState } = this.state;

    switch (tobacoReduceState) {
      case "reduceHowMuch":
        return this.tobaccoReducsHowMuch();

      case "reduceHowOften":
        return this.TobaccoReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor: "pointer",
              }}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "stopCompletely":
        return this.TobaccoStopCompletely();

      case "Others":
        return this.TobaccoOthers();

      default:
        return null;
    }
  };

  Tobacco = () => {
    return (
      <>
        {this.state.tobacoReduceState == "reduceHowMuch" && (
          <>
            <Box
              sx={{
                backgroundColor: "#F5F5F5",
                p: "8px 16px",
                zIndex: "2",
                position: "relative",
                borderRadius: "16px",
              }}
            >
              <Box
                component="img"
                src={info.default}
                alt="Logo"
                sx={{ position: "absolute", top: 8, left: 15 }}
              />
              <Typography
                sx={{
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  pl: "30px",
                }}
              >
                "Tobacco" refers to cigarettes, cigars, dip or chew, snus, etc.
                Vapes, e-cigarettes and nicotine patches, lozenges, gums all
                contain nicotine. These are typically safer than tobacco
                products and can be helpful in moving away from tobacco products
                that are more dangerous, like smoking. No matter what you want
                to cut back on, we can help. What are you looking to cut back
                on?
              </Typography>
            </Box>
            {this.tobacoMeasureType()}
            {this.state.tobacoMeasureType === "Others" && (
              <TextField
                value={this.state.tobaccoOtherMeasureTypeTextValue}
                onChange={this.handleInputChange}
                name="tobaccoOtherMeasureTypeTextValue"
                variant="standard"
                rows={1}
                fullWidth
                placeholder="Enter here..."
                multiline
                sx={{
                  lineHeight: "24px",
                  mt: "20px",
                  mb: "10px",
                  color: "#939393",
                  fontSize: "16px",
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  width: "286px",
                }}
               
                inputProps={{
                  style: {
                    fontSize: "16px",
                    fontFamily: "Chromatica-Regular",
                    fontWeight: 500,
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      color: "#939393",
                      fontFamily: "Chromatica-Regular",
                      fontWeight: 500,
                    },
                  },
                }}

                InputProps={{
                  disableUnderline: false,
                  sx: {
                    height: "58px",
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
              />
            )}
          </>
        )}
        {this.tobaccoReduceState()}
        {this.selectedTobaccoReduceState()}
        <Button
          sx={{
            padding: "16px",
            borderRadius: "16px",
            textTransform: "none",
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            fontSize: "18px",
            fontWeight: 700,
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            width: "348px",
            height: "56px",
            mb: "15px",
            mt: "25px",
          }}
          onClick={this.moveToUppers}
          data-test-id="login-add-goals"
          fullWidth
          variant="contained"
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 700,
              lineHeight: "24px",
              fontFamily: "Chromatica-Regular",
            }}
          >
            Add Goal
          </Typography>
          <Box
            component="img"
            src={loginArrow.default}
            alt="loginArrow"
            sx={{ ml: "2px" }}
          />
        </Button>
      </>
    );
  };


  upperReduceState = () => {
    const uppersGetStyle = (value: string) => ({
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.upperReduceState === value ? "#EFF8FB" : "transparent",
      width: "227px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.upperReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
        aria-label="upperReduceState"
        name="upperReduceState"
        value={this.state.upperReduceState}
        onChange={(e) => this.setState({ upperReduceState: e.target.value })}
        sx={{ mt: "15px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px", }}
        data-test-id="upperReduceState"
      >
        <Box sx={uppersGetStyle("UppersReduceHowMuch")}>
          <FormControlLabel
            value="UppersReduceHowMuch"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Reduce how much"
            onClick={this.resetUppersHowOften}
            data-test-id="resetUppersHowOften"     
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("UppersReduceHowOften")}>
          <FormControlLabel
            value="UppersReduceHowOften"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Reduce how often"
            onClick={this.resetUppersSafer}
            data-test-id="resetUppersSafer"  
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("Safer/healthier")}>
          <FormControlLabel
            value="Safer/healthier"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Safer/healthier"
            onClick={this.resetUppersStopCompletely}
            data-test-id="resetUppersStopCompletely" 
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("UppersStopCompletely")}>
          <FormControlLabel
            value="UppersStopCompletely"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Stop completely"
            onClick={this.resetUppersOthers}
            data-test-id="resetUppersOthers" 
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
          />
        </Box>
        <Box sx={uppersGetStyle("Others")}>
          <FormControlLabel
            value="Others"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Other"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetUppersHowMuch}
            data-test-id="resetUppersHowMuch" 
          />
        </Box>
      </RadioGroup>
    );
  };

  upperMetricValue = () => {
    const getStyle = (value: string) => ({
      width: "124px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.upperMetricValue === value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.upperMetricValue === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="upperMetricValue"
        name="upperMetricValue"
        value={this.state.upperMetricValue}
        onChange={(e) => this.setState({ upperMetricValue: e.target.value })}
        sx={{ mt: "10px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px", }}
        data-test-id="upperMetricValue"
      >
        <Box sx={getStyle("ounces")}>
          <FormControlLabel
            value="ounces"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Ounces"
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetUppersDollarAndHits"
            onClick={this.resetUppersDollarAndHits}
          />
        </Box>
        <Box sx={getStyle("dollars")}>
          <FormControlLabel
            value="dollars"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="$$"
            onClick={this.resetUppersOunceAndHits}
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetUppersOunceAndHits"
          />
        </Box>
        <Box sx={getStyle("hits")}>
          <FormControlLabel
            value="hits"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Hits"
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetUppersDollarAndOunces}
            data-test-id="resetUppersDollarAndOunces"
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedUpperMetricValue = () => {
    const { upperMetricValue } = this.state;
    switch (upperMetricValue) {
      case "ounces":
        return (
          <>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Ounces did you have on a typical day
              when you use?
            </Typography>
            <Box
              sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
                onClick={() =>
                  this.handleCountChange("lastWeekUppersOuncesCount", -1)
                }
                data-test-id="lastWeekOuncesCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                {this.state.lastWeekUppersOuncesCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                onClick={() => this.handleCountChange("lastWeekUppersOuncesCount", 1)}
                data-test-id="lastWeekOuncesCountAdd"
              />
            </Box>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                sx={{
                  width:'32px',height:'32px', mr: "8px",
                }}
                onClick={() =>
                  this.handleCountChange("nextweekUppersOuncessReduceCount", -1)
                }
                data-test-id="nextweekOuncessReduceCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                {this.state.nextweekUppersOuncessReduceCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                onClick={() =>
                  this.handleCountChange("nextweekUppersOuncessReduceCount", 1)
                }
                data-test-id="nextweekOuncessReduceCountAdd"
              />
            </Box>
          </>
        );
      case "dollars":
        return (
          <>
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                color: "#252525",
                fontSize: "16px",
                zIndex: "2",
                mt: "20px",
              }}
            >
             Over the last week, how many $$ did you have on a typical day when you use?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                rows={1}
                value={this.state.lastWeekUppersDollarsTextValue}
                onChange={this.handleInputChange}
                name="lastWeekUppersDollarsTextValue"
                data-test-id="lastWeekUppersDollarsTextValue"
                variant="standard"
                fullWidth
                sx={{
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  mt: "20px",
                  mb: "10px",
                  width: "286px",
                  color: "#939393",
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontWeight: 500,
                      fontFamily: "Chromatica-Regular",
                      fontSize: "16px",
                      color: "#939393",
                    },
                  },
                }}
              />
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                sx={{
                  mt: "20px",
                  lineHeight: "24px",
                  mb: "10px",
                  color: "#939393",
                  width: "286px",
                  fontSize: "16px",
                  fontWeight: 500,
                  fontFamily: "Chromatica-Regular",
                }}
                onChange={this.handleInputChange}
                name="nextweekUppersDollarsReduceTextValue"
                value={this.state.nextweekUppersDollarsReduceTextValue}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      fontFamily: "Chromatica-Regular",
                      color: "#939393",
                      fontWeight: 500,
                    },
                  },
                }}
                rows={1}
                fullWidth
                variant="standard"
              />
          </>
        );
      case "hits":
        return (
          <>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
              }}
            >
              Over the last week, how many Hits did you have on a typical day
              when you use?
            </Typography>
            <Box
              sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange("lastWeekUppersHitsCount", -1)
                }
                data-test-id="lastWeekUppersHitsCountMinus"
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                  width: "51px",
                }}
              >
                {this.state.lastWeekUppersHitsCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                onClick={() => this.handleCountChange("lastWeekUppersHitsCount", 1)}
                data-test-id="lastWeekUppersHitsCountAdd"
              />
            </Box>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}
            >
              <Box
                component="img"
                src={button_minus.default}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange("nextweekUppersHitsReduceCount", -1)
                }
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
                data-test-id="nextweekUppersHitsReduceCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                  lineHeight: "24px",
                  fontWeight: 400,
                  fontSize: "16px",
                }}
              >
                {this.state.nextweekUppersHitsReduceCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                data-test-id="nextweekUppersHitsReduceCountAdd"
                sx={{ ml: "8px" ,width:'32px',height:'32px'}}
                onClick={() =>
                  this.handleCountChange("nextweekUppersHitsReduceCount", 1)
                }
              />
            </Box>
          </>
        );;
    }
  };

  uppersReducesHowMuch = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "15px",
          }}
        >
          When you think about reducing, how would you want to measure that?
          Ounces, $$, hits? [choose one]
        </Typography>
        {this.upperMetricValue()}
        {this.selectedUpperMetricValue()}
      </>
    );
  };

  uppersReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the past 7 days, how many days did you use uppers?
        </Typography>
        <Box sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr: "8px",width:'32px',height:'32px'
            }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysUppersCount", -1)
            }
            data-test-id="pastSevenDaysUppersCountMinus"
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              width: "51px",
              height: "32px",
              borderRadius: "4px",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            {this.state.pastSevenDaysUppersCount}
          </Box>
          <Box
            component="img"
            src={button_add.default}
            alt="button_add"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
            onClick={() =>
              this.handleCountChange("pastSevenDaysUppersCount", 1)
            }
            data-test-id="pastSevenDaysUppersCountAdd"
          />
        </Box>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Out of the NEXT 7 days, how many days do you want to use uppers?
        </Typography>
        <Box sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            alt="button_minus"
            sx={{
              mr: "8px", width:'32px',height:'32px'
            }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysUppersCount", -1)
            }
            data-test-id="nextSevenDaysUppersCountminus"
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              width: "51px",
              height: "32px",
              borderRadius: "4px",
              textAlign: "center",
              alignContent: "center",
            }}
          >
            {this.state.nextSevenDaysUppersCount}
          </Box>
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysUppersCount", 1)
            }
            data-test-id="nextSevenDaysUppersCountadd"
          />
        </Box>
      </>
    );
  };

  uppersStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      border: `1px solid ${
        this.state.isTakingBreakUppers == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.isTakingBreakUppers == value ? "#EFF8FB" : "transparent",
      ml: value !== "yes" ? "10px" : "0px",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="isTakingBreakUppers"
        name="isTakingBreakUppers"
        value={this.state.isTakingBreakUppers}
        onChange={this.handleCigarettesReduceTypeChange}
        sx={{ mt: "15px" }}
        data-test-id="isTakingBreakUppers"
      >
        <Box sx={getStyle("yes")}>
          <FormControlLabel
            value={"yes"}
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Yes"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
        <Box sx={getStyle("no")}>
          <FormControlLabel
            value={"no"}
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="No"
            componentsProps={{ typography: typographyProps }}
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedUppersBreakDate = () => {
    const UppersSelectedDatesStyle = (value: string) => ({
      p: "8px 12px",
      border: `1px solid ${
        this.state.uppersBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      borderRadius: "32px",
      height: "40px",
      backgroundColor:
        this.state.uppersBreakSelectedDate == value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
      },
    };

    return (
      <RadioGroup
        row
        aria-label="uppersBreakDay"
        name="uppersBreakDay"
        value={this.state.uppersBreakSelectedDate}
        onChange={this.handleUppersBreakDateChange}
        sx={{ mt: "15px", display: "felx",
        justifyContent: "flex-start",
        gap: "10px",zIndex:'2' }}
        data-test-id="uppersBreakDay"
      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={UppersSelectedDatesStyle(date.format("YYYY-MM-DD"))}>
            <FormControlLabel
              key={index}
              value={date.format("YYYY-MM-DD")}
              control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
              label={
                index === 0
                  ? `Today, ${date.format("ddd D MMMM")}`
                  : date.format("ddd, D MMMM")
              }
              componentsProps={{ typography: typographyProps }}
              sx={{ fontWeight: 400, color: "#252525", lineHeight: "24px" }}
            />
          </Box>
        ))}
        <Box sx={UppersSelectedDatesStyle("OtherDay")}>
          <FormControlLabel
            value="OtherDay"
            control={<Radio sx={{
              '&.Mui-checked': {
                  color: '#00A7D1',
              },
              color: '#E3E3E3'
          }}
 />}
            label="Other day"
            componentsProps={{ typography: typographyProps }}
            sx={{ fontWeight: 400, color: "#252525", lineHeight: "24px" }}
          />
        </Box>
      </RadioGroup>
    );
  };

  uppersStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
            maxWidth: "890px",
            minWidth:'400px'
          }}
        >
          This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from using uppers at this point?
        </Typography>
        {this.uppersStopCompletelyMeasureType()}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            lineHeight: "24px",
            fontWeight: 400,
            fontSize: "16px",
            mt: "20px",
            zIndex: "2",
          }}
        >
          Way to commit to taking a break from uppers! When day this week do you
          want to start?
        </Typography>
        {this.selectedUppersBreakDate()}
        {this.state.uppersBreakSelectedDate == "OtherDay" && 
        <div
        style={{
          marginTop: "20px",
          borderBottom: "1px solid #E3E3E3",
          display: "flex",
          alignItems: "center",
          width: "286px",
        }}
      >
        <input
          type="text"
          readOnly
          style={{
            border: "none",
            padding: "10px 0",
            outline: "none",
            marginRight: "10px",
            backgroundColor: "transparent",
            width: "100%",
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          }}
          value={this.state.tobaccoCalendarSelectedDate || ""}
          placeholder="Choose Day"

        />
        <img
          src={calendar.default}
          style={{ cursor: "pointer", padding: "10px" }}
          alt="Open Calendar"
        />
      </div>
        }
      </>
    );
  };

  uppersOthers = () => {
    return (
      <>
      <Typography
        sx={{
          mt: "30px",
          zIndex: "2",
          lineHeight: "24px",
          fontWeight: 400,
          fontSize: "16px",
          color: "#252525",
          fontFamily: "Chromatica-Regular",
        }}
      >
        What do you want to see happen with your use?
      </Typography>
      <TextField
        placeholder="Type here..."
        multiline
        value={this.state.uppersOthersTextValue}
        onChange={this.handleInputChange}
        name="uppersOthersTextValue"
        InputProps={{
          sx: {
            "&:after": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:before": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #E3E3E3",
            },
            ".MuiInputBase-input": {
              paddingBottom: "5px",
            },
          },
          disableUnderline: false,
        }}
        inputProps={{
          style: {
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          },
          sx: {
            "&::placeholder": {
              fontFamily: "Chromatica-Bold",
              fontWeight: 700,
              color: "#939393",
              fontSize: "16px",
            },
          },
        }}
        sx={{
          width: "816px",
          height: "145px",
          fontSize: "16px",
          fontFamily: "Chromatica-Regular",
          fontWeight: 700,
          mt: "25px",
          lineHeight: "24px",
          color: "#939393",
        }}
        rows={5}
        fullWidth
        variant="standard"
      />
    </>
    );
  };

  selectedUppersReduceState = () => {
    const { upperReduceState } = this.state;

    switch (upperReduceState) {
      case "UppersReduceHowMuch":
        return this.uppersReducesHowMuch();

      case "UppersReduceHowOften":
        return this.uppersReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor: "pointer",
              }}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "UppersStopCompletely":
        return this.uppersStopCompletely();

      case "Others":
        return this.uppersOthers();

      default:
        return null;
    }
  };


  Uppers = () => {
    return (
      <>
        <>
          <Box
            sx={{
              p: "8px 16px",
              backgroundColor: "#F5F5F5",
              borderRadius: "16px",
              zIndex: "2",
              alignItems: "center",
              gap: "10px",
              display: "flex",
            }}
          >
            <Box
              component="img"
              src={info.default}
              sx={{ width: "24px", height: "24px" }}
              alt="info"
            />
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                alignItems: "center",
                display: "flex",
              }}
            >
              "Uppers" refers to stimulants like [Ritalin, Adderall, meth,
              speed, coke]
            </Typography>
          </Box>
        </>
        {this.upperReduceState()}
        {this.selectedUppersReduceState()}
        <Button
          fullWidth
          variant="contained"
          onClick={this.moveToDowners}
          data-test-id="moveToDowners"
          sx={{
            fontFamily: "Chromatica-Regular",
            height: "56px",
            mt: "25px",
            mb: "15px",
            width: "348px",
            padding: "16px",
            borderRadius: "16px",
            textTransform: "none",
            fontSize: "18px",
            fontWeight: 700,
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            lineHeight: "24px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            Add Goal
          </Typography>
          <Box
            component="img"
            alt="loginArrow"
            src={loginArrow.default}
            sx={{ ml: "2px" }}
          />
        </Button>
      </>
    );
  }

  DownersReduceState = () => {
    const DownersGetStyle = (value: string) => ({
      height: "40px",
      borderRadius: "32px",
      backgroundColor:
        this.state.downersReduceState === value ? "#EFF8FB" : "transparent",
      p: "8px 12px",
      border: `1px solid ${
        this.state.downersReduceState === value ? "#00A7D1" : "#E3E3E3"
      }`,
      width: "227px",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontWeight: 400,
        fontSize: "16px",
      },
    };

    return (
      <RadioGroup
        sx={{
          mt: "15px",
          display: "felx",
          gap: "10px",
          justifyContent: "flex-start",
        }}
        value={this.state.downersReduceState}
        onChange={(e) => this.setState({ downersReduceState: e.target.value })}
        data-test-id="downersReduceState"
        row
        aria-label="downersReduceState"
        name="downersReduceState"
      >
        <Box sx={DownersGetStyle("downersReduceHowMuch")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersReduceHowMuch"
            label="Reduce how much"
            sx={{ color: "#252525", lineHeight: "24px" }}
            componentsProps={{ typography: typographyProps }}
            onClick={this.resetDownersHowOften}
            data-test-id="resetDownersHowOften" 
          />
        </Box>
        <Box sx={DownersGetStyle("downersReduceHowOften")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersReduceHowOften"
            label="Reduce how often"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersSafer}
            data-test-id="resetDownersSafer" 
          />
        </Box>
        <Box sx={DownersGetStyle("Safer/healthier")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="Safer/healthier"
            label="Safer/healthier"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersStopCompletely}
            data-test-id="resetDownersStopCompletely" 
          />
        </Box>
        <Box sx={DownersGetStyle("downersStopCompletely")}>
          <FormControlLabel

            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="downersStopCompletely"
            label="Stop completely"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersOthers}
            data-test-id="resetDownersOthers" 
          />
        </Box>
        <Box sx={DownersGetStyle("Others")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="Others"
            label="Other"
            componentsProps={{ typography: typographyProps }}
            sx={{ color: "#252525", lineHeight: "24px" }}
            onClick={this.resetDownersHowMuch}
            data-test-id="resetDownersHowMuch" 
          />
        </Box>
      </RadioGroup>
    );
  };

  DownersMetricValue = () => {
    const DownersGetStyle = (value: string) => ({
      width: "124px",
      border: `1px solid ${
        this.state.downersMetricValue === value ? "#00A7D1" : "#E3E3E3"
      }`,
      p: "8px 12px",
      height: "40px",
      borderRadius: "32px",
      backgroundColor:
        this.state.downersMetricValue === value ? "#EFF8FB" : "transparent",
    });

    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        color: "#252525",
        lineHeight: "24px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
       
        value={this.state.downersMetricValue}
        sx={{
          mt: "10px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        onChange={(e) => this.setState({ downersMetricValue: e.target.value })}
        data-test-id="downersMetricValue"
        aria-label="downersMetricValue"
        name="downersMetricValue"
      >
        <Box sx={DownersGetStyle("ounces")}>
          <FormControlLabel

            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            value="ounces"
            componentsProps={{ typography: typographyProps }}
            label="Ounces"
            data-test-id="resetDownersDollarAndHits"
            onClick={this.resetDownersDollarAndHits}
          />
        </Box>
        <Box sx={DownersGetStyle("dollars")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            componentsProps={{ typography: typographyProps }}
            value="dollars"
            label="$$"
            data-test-id="resetDownersOunceAndHits"
            onClick={this.resetDownersOunceAndHits}
          />
        </Box>
        <Box sx={DownersGetStyle("hits")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            label="Hits"
            value="hits"
            componentsProps={{ typography: typographyProps }}
            data-test-id="resetDownersDollarAndOunces"
            onClick={this.resetDownersDollarAndOunces}
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedDownersMetricValue = () => {
    const { downersMetricValue } = this.state;
    switch (downersMetricValue) {
      case "ounces":
        return (
          <>
            <Typography
              sx={{
                lineHeight: "24px",
                color: "#252525",
                fontWeight: 400,
                fontFamily: "Chromatica-Regular",
                mt: "20px",
                fontSize: "16px",
                zIndex: "2",
              }}
            >
              Over the last week, how many Ounces did you have on a typical day
              when you use?
            </Typography>
            <Box
              sx={{ display: "flex", height: "30px", mt: "15px" , width: "40%"}}
            >
              <Box
                component="img"
                alt="button_minus"
                src={button_minus.default}
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
                data-test-id="lastWeekDownersOuncesCountMinus"
                onClick={() =>
                  this.handleCountChange("lastWeekDownersOuncesCount", -1)
                }
              />
              <Box
                sx={{
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  lineHeight: "24px",
                  border: "1px solid #E3E3E3",
                  fontSize: "16px",
                  fontWeight: 400,
                  width: "51px",
                  height: "32px",
                  borderRadius: "4px",
                  alignContent: "center",
                  textAlign: "center",
                }}
              >
                {this.state.lastWeekDownersOuncesCount}
              </Box>
              <Box
                component="img"
                alt="button_add"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                src={button_add.default}
                onClick={() =>
                  this.handleCountChange("lastWeekDownersOuncesCount", 1)
                }
                data-test-id="lastWeekDownersOuncesCountAdd"
              />
            </Box>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex",height: "30px", width: "40%",  mt: "15px" }}
            >
              <Box
                src={button_minus.default}
                component="img"
                sx={{
                  mr: "8px", width:'32px',height:'32px',
                }}
                alt="button_minus"
                onClick={() =>
                  this.handleCountChange(
                    "nextweekDownersOuncessReduceCount",
                    -1
                  )
                }
                data-test-id="nextweekDownersOuncessReduceCountMinus"
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  fontWeight: 400,
                  color: "#252525",
                  lineHeight: "24px",
                  width: "51px",
                  fontFamily: "Chromatica-Regular",
                  height: "32px",
                  borderRadius: "4px",
                  fontSize: "16px",
                  textAlign: "center",
                  alignContent: "center",
                }}
              >
                {this.state.nextweekDownersOuncessReduceCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                alt="button_add"
                onClick={() =>
                  this.handleCountChange("nextweekDownersOuncessReduceCount", 1)
                }
                data-test-id="nextweekDownersOuncessReduceCountAdd"
                sx={{ height:'32px' ,ml: "8px",width:'32px',}}
              />
            </Box>
          </>
        );
      case "dollars":
        return (
          <>
            <Typography
              sx={{
                lineHeight: "24px",
                fontWeight: 400,
                fontFamily: "Chromatica-Regular",
                fontSize: "16px",
                zIndex: "2",
                mt: "20px",
                color: "#252525",
              }}
            >
             Over the last week, how many $$ did you have on a typical day when you use?
            </Typography>
            <TextField
                multiline
                rows={1}
                placeholder="Type here..."
                fullWidth
                value={this.state.lastWeekDownersDollarsTextValue}
                onChange={this.handleInputChange}
                name="lastWeekDownersDollarsTextValue"
                variant="standard"
                sx={{
                  fontFamily: "Chromatica-Regular",
                  fontWeight: 500,
                  fontSize: "16px",
                  lineHeight: "24px",
                  mt: "20px",
                  mb: "10px",
                  width: "286px",
                  color: "#939393",
                }}
               
                inputProps={{
                  style: {
                    fontWeight: 500,
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                  },
                  sx: {
                    "&::placeholder": {
                      fontWeight: 500,
                      fontFamily: "Chromatica-Regular",
                      fontSize: "16px",
                      color: "#939393",
                    },
                  },
                }}
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    height: "58px",
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
              />
            <Typography
              sx={{
                fontWeight: 400,
                mt: "20px",
                zIndex: "2",
                color: "#252525",
                fontSize: "16px",
                lineHeight: "24px",
                fontFamily: "Chromatica-Regular",
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <TextField
                placeholder="Type here..."
                multiline
                value={this.state.nextweekDownersDollarsReduceTextValue}
                onChange={this.handleInputChange}
                name="nextweekDownersDollarsReduceTextValue"
                InputProps={{
                  disableUnderline: false,
                  sx: {
                    height: "58px",
                    "&:hover:not(.Mui-disabled):before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    "&:after": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                    ".MuiInputBase-input": {
                      pt: "20px",
                    },
                    "&:before": {
                      borderBottom: "1px solid #E3E3E3",
                    },
                  },
                }}
                inputProps={{
                  style: {
                    fontFamily: "Chromatica-Regular",
                    fontSize: "16px",
                    fontWeight: 500,
                  },
                  sx: {
                    "&::placeholder": {
                      fontSize: "16px",
                      fontFamily: "Chromatica-Regular",
                      color: "#939393",
                      fontWeight: 500,
                    },
                  },
                }}
                sx={{
                  lineHeight: "24px",
                  mt: "20px",
                  color: "#939393",
                  width: "286px",
                  fontSize: "16px",
                  mb: "10px",
                  fontWeight: 500,
                  fontFamily: "Chromatica-Regular",
                }}
                rows={1}
                variant="standard"
                fullWidth
              />
          </>
        );
      case "hits":
        return (
          <>
            <Typography
              sx={{
                fontWeight: 400,
                mt: "20px",
                fontSize: "16px",
                color: "#252525",
                lineHeight: "24px",
                zIndex: "2",
                fontFamily: "Chromatica-Regular",
              }}
            >
              Over the last week, how many Hits did you have on a typical day
              when you use?
            </Typography>
            <Box
              sx={{  height: "30px", mt: "15px",display: "flex", width: "40%", }}
            >
              <Box
                src={button_minus.default}
                component="img"
                onClick={() =>
                  this.handleCountChange("lastWeekDownersHitsCount", -1)
                }
                alt="button_minus"
                data-test-id="lastWeekDownersHitsCountMinus"
                sx={{
                  width:'32px',height:'32px', mr: "8px",
                }}
              />
              <Box
                sx={{
                  border: "1px solid #E3E3E3",
                  fontFamily: "Chromatica-Regular",
                  height: "32px",
                  borderRadius: "4px",
                  color: "#252525",
                  alignContent: "center",
                  textAlign: "center",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontWeight: 400,
                  width: "51px",
                }}
              >
                {this.state.lastWeekDownersHitsCount}
              </Box>
              <Box
                src={button_add.default}
                component="img"
                sx={{ ml: "8px",width:'32px',height:'32px' }}
                alt="button_add"
                data-test-id="lastWeekDownersHitsCountAdd"
                onClick={() => this.handleCountChange("lastWeekDownersHitsCount", 1)}
              />
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                color: "#252525",
                fontSize: "16px",
                mt: "20px",
                zIndex: "2",
                fontWeight: 400,
              }}
            >
              What would you like to reduce that amount to this next week?
            </Typography>
            <Box
              sx={{ display: "flex", mt: "15px", width: "40%", height: "30px" }}
            >
              <Box
                src={button_minus.default}
                component="img"
                onClick={() =>
                  this.handleCountChange("nextweekDownersHitsReduceCount", -1)
                }
                alt="button_minus"
                data-test-id="nextweekDownersHitsReduceCountMinus"
                sx={{
                  mr: "8px",width:'32px',height:'32px'
                }}
              />
              <Box
                sx={{
                  color: "#252525",
                  fontFamily: "Chromatica-Regular",
                  width: "51px",
                  height: "32px",
                  border: "1px solid #E3E3E3",
                  borderRadius: "4px",
                  textAlign: "center",
                  alignContent: "center",
                  lineHeight: "24px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                {this.state.nextweekDownersHitsReduceCount}
              </Box>
              <Box
                component="img"
                src={button_add.default}
                data-test-id="nextweekDownersHitsReduceCountAdd"
                alt="button_add"
                sx={{ width:'32px',height:'32px',ml: "8px" ,}}
                onClick={() =>
                  this.handleCountChange("nextweekDownersHitsReduceCount", 1)
                }
              />
            </Box>
          </>
        );
    }
  };

  downersReducesHowMuch = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "15px",
            fontSize: "16px",
          }}
        >
          When you think about reducing, how would you want to measure that?
          Ounces, $$, hits? [choose one]
        </Typography>
        {this.DownersMetricValue()}
        {this.selectedDownersMetricValue()}
      </>
    );
  };

  downersReduceHowOften = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "20px",
            zIndex: "2",
            fontSize: "16px",
          }}
        >
          Out of the past 7 days, how many days did you use downers?
        </Typography>
        <Box sx={{ width: "40%",display: "flex",  height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            sx={{
              mr: "8px",width:'32px',height:'32px'
            }}
            alt="button_minus"
            onClick={() =>
              this.handleCountChange("pastSevenDaysDownersCount", -1)
            }
            data-test-id="pastSevenDaysDownersCountMinus"
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontSize: "16px",
              height: "32px",
              width: "51px",
              fontWeight: 400,
              textAlign: "center",
              alignContent: "center",
              borderRadius: "4px",
            }}
          >
            {this.state.pastSevenDaysDownersCount}
          </Box>
          <Box
            component="img"
            alt="button_add"
            src={button_add.default}
            onClick={() =>
              this.handleCountChange("pastSevenDaysDownersCount", 1)
            }
            data-test-id="pastSevenDaysDownersCountAdd"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
          />
        </Box>
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            fontSize: "16px",
            zIndex: "2",
            mt: "20px",
          }}
        >
          Out of the NEXT 7 days, how many days do you want to use downers?
        </Typography>
        <Box sx={{ display: "flex", width: "40%", height: "30px", mt: "15px" }}>
          <Box
            component="img"
            src={button_minus.default}
            sx={{
              mr: "8px",width:'32px',height:'32px'
            }}
            alt="button_minus"
            data-test-id="nextSevenDaysDownersCountminus"
            onClick={() =>
              this.handleCountChange("nextSevenDaysDownersCount", -1)
            }
          />
          <Box
            sx={{
              border: "1px solid #E3E3E3",
              color: "#252525",
              lineHeight: "24px",
              fontFamily: "Chromatica-Regular",
              fontSize: "16px",
              width: "51px",
              fontWeight: 400,
              borderRadius: "4px",
              alignContent: "center",
              textAlign: "center",
              height: "32px",
            }}
          >
            {this.state.nextSevenDaysDownersCount}
          </Box>
          <Box
            component="img"
            src={button_add.default}
            alt="button_minus"
            data-test-id="nextSevenDaysDownersCountadd"
            sx={{ ml: "8px",width:'32px',height:'32px' }}
            onClick={() =>
              this.handleCountChange("nextSevenDaysDownersCount", 1)
            }
          />
        </Box>
      </>
    );
  };

  DownersStopCompletelyMeasureType = () => {
    const getStyle = (value: string) => ({
      width: "100px",
      p: "8px 12px",
      borderRadius: "32px",
      border: `1px solid ${
        this.state.isTakingBreakDowners == value ? "#00A7D1" : "#E3E3E3"
      }`,
      backgroundColor:
        this.state.isTakingBreakDowners == value ? "#EFF8FB" : "transparent",
      height: "40px",
    });

    const typographyProps = {
      sx: {
        fontSize: "16px",
        fontFamily: "Chromatica-Regular",
        color: "#252525",
        lineHeight: "24px",
        fontWeight: 400,
      },
    };

    return (
      <RadioGroup
        row
        sx={{
          mt: "15px",
          display: "felx",
          justifyContent: "flex-start",
          gap: "10px",
        }}
        aria-label="isTakingBreakDowners"
        name="isTakingBreakDowners"
        value={this.state.isTakingBreakDowners}
        onChange={this.handleCigarettesReduceTypeChange}
        data-test-id="isTakingBreakDowners"
      >
        <Box sx={getStyle("yes")}>
          <FormControlLabel
            control={
              <Radio
                sx={{
                  color: "#E3E3E3",
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                }}
              />
            }
            value={"yes"}
            componentsProps={{ typography: typographyProps }}
            label="Yes"
          />
        </Box>
        <Box sx={getStyle("no")}>
          <FormControlLabel
            value={"no"}
            label="No"
            componentsProps={{ typography: typographyProps }}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
          />
        </Box>
      </RadioGroup>
    );
  };

  selectedDownersBreakDate = () => {
    const DownersSelectedDatesStyle = (value: string) => ({
      border: `1px solid ${
        this.state.downersBreakSelectedDate == value ? "#00A7D1" : "#E3E3E3"
      }`,
      p: "8px 12px",
      height: "40px",
      backgroundColor:
        this.state.downersBreakSelectedDate == value
          ? "#EFF8FB"
          : "transparent",
      borderRadius: "32px",
     
    });
    const typographyProps = {
      sx: {
        fontFamily: "Chromatica-Regular",
        fontSize: "16px",
        fontWeight: 400,
        color: "#252525",
        lineHeight: "24px",
      },
    };

    return (
      <RadioGroup
        row
        name="DownersBreakDay"
        aria-label="DownersBreakDay"
        onChange={this.handleDownersBreakDateChange}
        data-test-id="DownersBreakDay"
        
        value={this.state.downersBreakSelectedDate}
        sx={{
          display: "felx",
          mt: "15px",
          gap: "10px",
          zIndex:'2',
          justifyContent: "flex-start",
        }}

      >
        {this.state.tobaccoBreakDay.map((date, index) => (
          <Box sx={DownersSelectedDatesStyle(date.format("YYYY-MM-DD"))}>
            <FormControlLabel
              componentsProps={{ typography: typographyProps }}
              key={index}
              control={
                <Radio
                  sx={{
                    color: "#E3E3E3",
                    "&.Mui-checked": {
                      color: "#00A7D1",
                    },
                  }}
                />
              }
              label={
                index === 0
                  ? `Today, ${date.format("ddd D MMMM")}`
                  : date.format("ddd, D MMMM")
              }
              value={date.format("YYYY-MM-DD")}
              sx={{        fontFamily: "Chromatica-Regular",
              fontSize: "16px", fontWeight: 400, color: "#252525", lineHeight: "24px" }}
            />
          </Box>
        ))}
        <Box sx={DownersSelectedDatesStyle("OtherDay")}>
          <FormControlLabel
            componentsProps={{ typography: typographyProps }}
            control={
              <Radio
                sx={{
                  "&.Mui-checked": {
                    color: "#00A7D1",
                  },
                  color: "#E3E3E3",
                }}
              />
            }
            value="OtherDay"
            label="Other day"
            sx={{         fontFamily: "Chromatica-Regular",
            fontSize: "16px",fontWeight: 400, color: "#252525", lineHeight: "24px" }}
          />
        </Box>
      </RadioGroup>
    );
  };

  DownersStopCompletely = () => {
    return (
      <>
        <Typography
          sx={{
            color: "#252525",
            lineHeight: "24px",
            fontSize: "16px",
            fontWeight: 400,
            fontFamily: "Chromatica-Regular",
            mt: "20px",
            maxWidth: "890px",
            zIndex: "2",
            minWidth:'400px',
            height: "48px",
          }}
        >
          This is a good goal. It's also a really big goal. Some people approach
          this by reducing or using more safely first. Are you sure you want to
          take a break from using downers at this point?
        </Typography>
        {this.DownersStopCompletelyMeasureType()}
        <Typography
          sx={{
            color: "#252525",
            fontFamily: "Chromatica-Regular",
            fontWeight: 400,
            lineHeight: "24px",
            mt: "20px",
            zIndex: "2",
            fontSize: "16px",
          }}
        >
          Way to commit to taking a break from your downers use! When day this
          week do you want to start?
        </Typography>
        {this.selectedDownersBreakDate()}
        {this.state.downersBreakSelectedDate == "OtherDay" && 
        <div
        style={{
          display: "flex",
          marginTop: "20px",
          width: "286px",
          borderBottom: "1px solid #E3E3E3",
          alignItems: "center",
        }}
      >
        <input
          placeholder="Choose Day"
          type="text"      
          readOnly
          value={this.state.tobaccoCalendarSelectedDate || ""}
          style={{
            border: "none",
            width: "100%",
            fontSize: "16px",
            padding: "10px 0",
            outline: "none",
            fontFamily: "Chromatica-Regular",
            marginRight: "10px",
            backgroundColor: "transparent",
          }}
        />
        <img
          src={calendar.default}
          style={{ cursor: "pointer", padding: "10px" }}
          alt="Open Calendar"
        />
      </div>
        }
      </>
    );
  };

  DownersOthers = () => {
    return (
      <>
      <Typography
        sx={{
          mt: "30px",
          lineHeight: "24px",
          zIndex: "2",
          fontSize: "16px",
          fontWeight: 400,
          fontFamily: "Chromatica-Regular",
          color: "#252525",
        }}
      >
        What do you want to see happen with your use?
      </Typography>
      <TextField
        placeholder="Type here..."
        multiline
        value={this.state.DownersOthersTextValue}
        onChange={this.handleInputChange}
        name="DownersOthersTextValue"
        InputProps={{
          sx: {
            "&:before": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:after": {
              borderBottom: "1px solid #E3E3E3",
            },
            "&:hover:not(.Mui-disabled):before": {
              borderBottom: "1px solid #E3E3E3",
            },
            ".MuiInputBase-input": {
              paddingBottom: "5px",
            },
          },
          disableUnderline: false,
        }}
        inputProps={{
          sx: {
            "&::placeholder": {
              color: "#939393",
              fontWeight: 700,
              fontSize: "16px",
              fontFamily: "Chromatica-Bold",
            },      
          },
          style: {
            fontFamily: "Chromatica-Regular",
            fontSize: "16px",
          },
        }}
        rows={5}
        fullWidth
        variant="standard"
        sx={{
          width: "816px",
          fontSize: "16px",
          fontFamily: "Chromatica-Regular",
          fontWeight: 700,
          height: "145px",
          mt: "25px",
          lineHeight: "24px",
          color: "#939393",
        }}
      />
    </>
    );
  };

  selectedDownersReduceState = () => {
    const { downersReduceState } = this.state;

    switch (downersReduceState) {
      case "downersReduceHowMuch":
        return this.downersReducesHowMuch();

      case "downersReduceHowOften":
        return this.downersReduceHowOften();

      case "Safer/healthier":
        return (
          <Typography
            sx={{
              color: "#252525",
              lineHeight: "24px",
              fontWeight: 400,
              fontSize: "16px",
              fontFamily: "Chromatica-Regular",
              mt: "20px",
              zIndex: "2",
            }}
            data-test-id="Safer/healthier"
          >
            Check out our list of safer use strategies{" "}
            <span
              style={{
                fontWeight: 500,
                fontFamily: "Chromatica-Bold",
                borderBottom: "1px solid #252525",
                cursor: "pointer",
              }}
            >
              Here
            </span>{" "}
            to choose your goals for the next week.
          </Typography>
        );

      case "downersStopCompletely":
        return this.DownersStopCompletely();

      case "Others":
        return this.DownersOthers();

      default:
        return null;
    }
  };




  Downers = () => {
    return (
      <>
        <>
          <Box
            sx={{
              backgroundColor: "#F5F5F5",
              p: "8px 16px",
              zIndex: "2",
              borderRadius: "16px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <Box
              component="img"
              src={info.default}
              alt="info"
              sx={{ width: "24px", height: "24px" }}
            />
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                fontWeight: 400,
                lineHeight: "24px",
                color: "#252525",
                fontSize: "16px",
                alignItems: "center",
                display: "flex",
              }}
            >
              "Downers" refers to drugs that cause a depressant effect, like
              [Oxy, fentanyl, Xanax, Kratom]
            </Typography>
          </Box>
        </>
        {this.DownersReduceState()}
        {this.selectedDownersReduceState()}
        <Button
          variant="contained"
          
          sx={{
            height: "56px",
            fontFamily: "Chromatica-Regular",
            mb: "15px",
            mt: "25px",
            width: "348px",
            padding: "16px",
            background: "linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)",
            borderRadius: "16px",
            fontWeight: 700,
            fontSize: "18px",
            lineHeight: "24px",
            textTransform: "none",
          }}
          onClick={this.moveToOthers}
          data-test-id="moveToOthers"
          fullWidth
        >
          <Typography
            sx={{
              fontFamily: "Chromatica-Regular",
              lineHeight: "24px",
              fontWeight: 700,
              fontSize: "18px",
            }}
          >
            Add Goal
          </Typography>
          <Box
            alt="loginArrow"
            component="img"
            src={loginArrow.default}
            sx={{ ml: "2px" }}
          />
        </Button>
      </>
    );
  }

  selecttedTab = () => {
    const { selectedTab } = this.state;
    const renderSelectedTabContent = () => {
      if (selectedTab === "tobaccoOrNicotine") {
        return this.Tobacco();
      } else if (selectedTab === "uppers") {
        return this.Uppers();
      } else if (selectedTab === "downers") {
        return this.Downers()
      }
      return null;
    };
    return (
      <React.Fragment>
        {renderSelectedTabContent()}
        {selectedTab === "alcohol" && (
          <>
          <AlcohalSubstance navigation={undefined} id={""} />
           <Button
           data-test-id="addgoalsalcohol"
         
           onClick={()=>{this.setState({selectedTab:'cannabis'})}}
               variant="contained"
               sx={{
                padding: "10px",
                width: '348px',
                height: '56px',
                borderRadius: "16px",
                boxShadow: 'none',
                marginTop: '25px',
                marginBottom: '30px',
                background:
                    "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                cursor: 'pointer',
                backgroundColor: '#EFF8FB',
                fontSize: "18px",
                lineHeight: "20px",
                fontWeight: 400,
                fontFamily: 'Chromatica-Bold',
              textTransform: "none"}}>
               <span style={{ marginRight: '5px' }}>Add Goal</span>
               <img src={arrowright.default} />
           </Button>
           </>
        )}
        {selectedTab === "cannabis" && (
          <>
           <Cannabis navigation={undefined} id={""}/>
           <Button
           data-test-id="addgoals"
          variant="contained"
          onClick={()=>{this.setState({selectedTab:'tobaccoOrNicotine'})}}
          sx={{
            padding: "10px",
            borderRadius: "16px",
            boxShadow: 'none',
            background:
                "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
            
            backgroundColor: '#EFF8FB',
            fontSize: "18px",
            lineHeight: "20px",
            marginTop: '25px',
            marginBottom: '30px',
            cursor: 'pointer',
            width: '348px',
            height: '56px',
            fontWeight: 400,
            fontFamily: 'Chromatica-Bold',
            textTransform: "none"}}>
               <span style={{ marginRight: '5px' }}>Add Goal</span>
               <img src={arrowright.default} />
           </Button>
           </>
        )}
         {selectedTab === "Others" && (
           <OtherSubstance navigation={undefined} id={""}/>
        )}
      </React.Fragment>
    );
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid
        container
        sx={{
          backgroundColor: "#F0F0F0",
          padding: "10px",
          overflowY: "scroll",
          height: "100%",
        }}
        id="modal-content"
      >
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#0F4257",
            borderRadius: "16px",
            height: "88px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              width: "50%",
              height: "100%",
              alignContent: "center",
            }}
          >
            <Box
              sx={{
                fontSize: "40px",
                color: "#ffffff",
                fontFamily: "Josefin Sans",
                fontWeight: 300,
              }}
            >
              <span
                style={{
                  fontSize: "40px",
                  marginLeft: "40px",
                  fontFamily: "Josefin Sans",
                  fontWeight: 700,
                  marginBottom: "4px",
                  color: "#EBFF00",
                  lineHeight: "40px",
                }}
              >
                Well
              </span>
              Spring
            </Box>
            <Typography
              sx={{
                fontFamily: "Chromatica-Regular",
                fontWeight: 700,
                fontSize: "20px",
                lineHeight: "20px",
                color: "#FFFFFF",
                mt: "3px",
              }}
            >
              Hey, Tom Chandler
            </Typography>
          </Box>
          <Box
            component="img"
            src={menuIcon.default}
            onClick={() => {
              this.hamburgerMenu();
            }}
            alt="menuIcon"
            sx={{
              position: "absolute",
              top: "50%",
              right: "2%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
            }}
            data-test-id="hamburgerMenu"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "16px",
            minHeight:
              this.state.selectedTab === "tobaccoOrNicotine" &&
              this.state.tobacoReduceState == "reduceHowMuch"
                ? "100vh"
                : "87vh",
            position: "relative",
            mt: "10px",
            height: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              px: 4,
              paddingLeft:'58px'
            }}
          >
            <Box
              component="img"
              src={arrow.default}
              alt="Logo"
              onClick={() => {
                this.substanceGoals();
              }}
              sx={{ position: "absolute", top: 30, left: 60 }}
              data-test-id="arrowIcon"
            />
            <Typography
              onClick={() => {
                this.substanceGoals();
              }}
              sx={{
                position: "absolute",
                top: 25,
                left: 80,
                cursor: "pointer",
                fontFamily: "Chromatica-Bold",
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: 500,
              }}
              data-test-id="BackButton"
            >
              Back
            </Typography>
            <Box
              component="img"
              src={textLabel.default}
              alt="Logo"
              sx={{
                position: "absolute",
                top: 42,
                left: 58,
                zIndex: "1",
                height: "180px",
                width: "120px",
              }}
            />
            <Box
              component="img"
              src={substanceBottom.default}
              alt="substanceBottomImg"
              sx={{ position: "absolute", right: 0, bottom: 0 }}
            />
            <Typography
              sx={{
                color: "#00A7D1",
                fontFamily: "Josefin Sans",
                lineHeight: "32px",
                fontWeight: 600,
                fontSize: "32px",
                zIndex: "2",
                mt: "145px",
              }}
            >
              Substance Related Goals
            </Typography>
            <Typography
              sx={{
                color: "#252525",
                fontFamily: "Chromatica-Regular",
                lineHeight: "24px",
                fontWeight: 400,
                fontSize: "16px",
                mt: "10px",
                zIndex: "2",
              }}
            >
              It's great that you're thinking of ways to reduce your risks.
              You're worth it!
            </Typography>

            <Box
              sx={{
                width: "100%",
                bgcolor: "#F7F7F7",
                mt: "20px",
                mb: "20px",
                zIndex: "2",
              }}
            >
              <Tabs
                value={this.state.selectedTab}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{
                  overflow: "hidden",
                  "& .MuiTabs-indicator": {
                    display: "none",
                  },
                  "& .MuiTab-root": {
                    color: "black",
                    fontSize: "16px",
                    fontWeight: 400,
                    fontFamily: "Chromatica-Regular",
                    marginRight: "4px",
                    padding: "8px 16px",
                    textTransform: "none",
                    "&.Mui-selected": {
                      bgcolor: "white",
                      color: "black",
                      zIndex: 1,
                      borderRadius: "10px 10px 0 0",
                      border: "1px solid #E5E5E5",
                      borderBottom: "0px",
                      margin: 0,
                      fontWeight: 700,
                    },
                    "&:not(.Mui-selected)": {
                      borderBottom: "1px solid #E5E5E5",
                      margin: 0,
                    },
                  },
                  "& .MuiTab-iconWrapper": {
                    marginRight: "15px",
                  },
                  "& .MuiTabScrollButton-root": {
                    display: "none",
                  },
                }}
                data-test-id="selectedTab"
              >
               {this.state.categoryNameStore?.includes("Alcohol") &&
                <Tab
                  label="Alcohol"
                  icon={
                    <Box component="img" src={alcohol.default} alt="alcohol" />
                  }
                  iconPosition="start"
                  value="alcohol"
                  sx={{ margin: 0, borderLeft: "0 !important" }}
                />}
                {this.state.categoryNameStore?.includes("Cannabis")  &&
                <Tab
                  label="Cannabis"
                  icon={
                    <Box
                      component="img"
                      src={cannabis.default}
                      alt="cannabis"
                    />
                  }
                  iconPosition="start"
                  value="cannabis"
                  sx={{ margin: 0 }}
                />
                 }
                 {this.state.categoryNameStore?.includes("Tobacco or Nicotine") &&
                <Tab
                  label="Tobacco or Nicotine"
                  icon={
                    <Box component="img" src={tobacco.default} alt="tobacco" />
                  }
                  iconPosition="start"
                  value="tobaccoOrNicotine"
                  sx={{ margin: 0 }}
                />}
                {this.state.categoryNameStore?.includes("Uppers") &&
                <Tab
                  label="Uppers"
                  icon={
                    <Box component="img" src={uppers.default} alt="uppers" />
                  }
                  iconPosition="start"
                  value="uppers"
                  sx={{ margin: 0 }}
                />}
                 {this.state.categoryNameStore?.includes("Downers") &&
                <Tab
                  label="Downers"
                  icon={
                    <Box component="img" src={downers.default} alt="downers" />
                  }
                  iconPosition="start"
                  value="downers"
                  sx={{ margin: 0 }}
                />}
                 {this.state.categoryNameStore?.includes("Others") &&
                <Tab
                  label="Others"
                  icon={
                    <Box component="img" src={others.default} alt="others" />
                  }
                  iconPosition="start"
                  value="Others"
                  sx={{ margin: 0 }}
                />}
                <Tab sx={{ cursor: "none", pointerEvents: "none" }} />
                <Tab sx={{ cursor: "none", pointerEvents: "none" }} />
                <Tab sx={{ cursor: "none", pointerEvents: "none" }} />
                <Tab sx={{ cursor: "none", pointerEvents: "none" }} />
                <Tab sx={{ cursor: "none", pointerEvents: "none" }} />
              </Tabs>
            </Box>
            {this.selecttedTab()}
          </Box>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

const userTypeList: Dropdown[] = [
  { label: "Tenant", value: "Tenant" },
  { label: "Owner", value: "Owner" },
  { label: "Supervisor", value: "Supervisor" },
];

const ratingList: Dropdown[] = [
  { label: "1", value: "1" },
  { label: "2", value: "2" },
  { label: "3", value: "3" },
  { label: "4", value: "4" },
  { label: "5", value: "5" },
];

const genderList: Dropdown[] = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const SubmitButton = styled(Button)({
  backgroundColor: "rgb(98, 0, 238)",
  color: "white",
  borderRadius: "10px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  fontSize: "18px",
  padding: "6px 31px",
  "&:hover": {
    color: "white",
    backgroundColor: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 27px",
  },
});

const CancelButton = styled(Button)({
  backgroundColor: "white",
  padding: "6px 17px",
  border: "1px solid rgb(98, 0, 238)",
  color: "rgb(98, 0, 238)",
  fontSize: "18px",
  borderRadius: "8px",
  marginRight: "15px",
  textTransform: "none",
  fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  "&:hover": {
    backgroundColor: "white",
    color: "rgb(98, 0, 238)",
  },
  "@media(max-width:600px)": {
    padding: "7px 13px",
  },
});

const webStyle = {
  tableData: {
    fontSize: "16px",
    color: "#000",
  },
  statusColumn: {
    display: "flex",
    justifyContent: "center",
    color: "#000",
    alignItems: "center",
    fontSize: "16px",
  },
  noRecord: {
    fontSize: "18px",
    color: "#000",
  },
  mainTitle: {
    marginBottom: "5px",
    color: "#1b3c69",
    fontSize: "30px",
  },
  mainWrapper: {
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
  },
  pagination: {
    marginTop: "15px",
    display: "flex",
    justifyContent: "end",
  },
  buttonStyle: {
    fontSize: "18px",
    borderRadius: "8px",
    padding: "6px 17px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "white",
    cursor: "pointer",
  },
};
// Customizable Area End
