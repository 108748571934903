import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";


// Customizable Area Start

import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";


export interface Props {
  // Customizable Area Start
  navigation: any;
  id: string;
  logOutBlockInnerStyle?: React.CSSProperties;
  logOutBlockContainorStyle?: React.CSSProperties;
  logOutBlock?: () => JSX.Element | React.ReactFragment;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
 
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  navigation: any;
  id: string;
  // Customizable Area End
}

export const colors = {
  darkBlue: '#122247',
  lightGrey: '#95979E',
  blue: '#0028FF',
  white: '#ffffff',
  lightBlue: '#E8F5FE',
  black: '#000000',
  lightBlue1: '#C6E5FD',
  darkblue1: '#2850C4',
  cream: '#F4DCCF',
  lightCream: '#C2B1A5',
  green: '#57C898',
  placeholderTextColor: '#8F92A1',
  grey: '#A9A9A9',
  lightGreen: '#55C699',
  grey1: '#E8E8E8',
  bg_Header: '#0D2564',
  btn_blue: '#0028FF',

  light_blue: '#C7E5FD',
  dark_blue: '#0D2666',

  light_cream: '#F4DDCE',
  light_orange: '#F08A59',
  light_red: '#ED6669',
  light_grey: '#E8E8E8',
  dark_grey: '#414141',
  light_grey_1: '#e0e0e0',
  extra_light_blue: '#D1D6E2',

  lable: '#8F92A1',

  calender_darkBlue: '#0129FF',
  calender_lightBlue: '#E8F5FE',
  calender_lightSkin: '#F4DDCE',
  calender_black: '#000000',
  calender_white: '#ffffff',
  calender_lightBlue1: '#C6E5FD',
  calender_darkBlue1: '#0B2066',
  calender_darkBlue2: '#203BC4',
  calender_cream: '#F4DDCE',
  calender_lightcream: '#C2B1A5',
  calender_lightGray: '#F5F5F5',
  calender_arrowBackGroundColor: '#E9E8ED',
  calender_arrowColor: '#91949E',
  calender_green: '#60CC99'
};

export const configJSON = require("./config");
// Customizable Area End




export default class HamburgerMenuController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];
    this.state = {
      enableField: false,
      selectCheckbox: false,
      selectedButton: "Interviews",
      reminderswitch: false,
      loader: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }
  // Customizable Area End


  async receive(from: string, message: Message) {



    // Customizable Area Start
    // Customizable Area End

  }



  // Customizable Area Start
  

  // Customizable Area End

}
