import React from "react";
// Customizable Area Start
import { Box,Grid ,Tabs,Tab,IconButton,CardContent,Card,Button} from "@mui/material";
import { styled } from "@mui/material/styles"; 
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Typography from "@mui/material/Typography";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {  arrow_Arrow_arrow_down, arrow_Arrow_up, award_a, award_a1, award_a2, award_a6, awrad_Award, flower_flower, glass_glass, hook_Hook, Menu_M, plus_Plus, Watch_Watch, wava_right } from "./assets";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import "../../../blocks/dashboard/assets/style.css";
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
const cardsData= [
    {
        id:1,
      title: 'Reduce Drinks',
      description: 'Reduce from 6 to 3 per day',
      dateRange: '4/7 - 4/22',
      icon: <img src={glass_glass.default}/>,
    },
    {
        id:2,
      title: 'Get more rest',
      description: 'Go to bed at 11 on work days',
      dateRange: '4/8 - 4/23',
      icon: <img src={Watch_Watch.default}/>,
    },
    
   
  ]
const WhiteBackgroundContainer = styled('div')({
  backgroundColor: 'white', 
  padding: "2rem", 
   height:"auto",
  marginLeft:"1rem",
  marginRight:"1rem",
  borderRadius:"16px" 
});


 
// Customizable Area End
import GoalsInformationController, {
  Props,
  configJSON
} from "./GoalsInformationController.web";



export default class GoalsInformation extends GoalsInformationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
    steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


    icons = [
        <img src={award_a.default} alt="awrad_award" />,
        <img src={award_a1.default} alt="awrad_award" />,
        <img src={award_a2.default} alt="awrad_award" />,
    ];
    cardShowdata = () => {
        return (
            <>
                <Grid container spacing={2}>

                    {cardsData.map((card: any, index: any) => (
                        <Grid
                            item

                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            key={index}
                        >
                            <Card variant="outlined" style={{ position: 'relative', 
                                borderRadius: '8px', maxWidth: '100%', width: '310px' ,
                              
                                }}>
                                <CardContent>
                                    <Typography variant="h5" component="div">
                                        <Box style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "0.6rem" }}>
                                            <Box style={{ width: '40px', borderRadius: '8px', border: "1px solid #E3E3E3" }}>
                                                <span style={{ display: "flex", justifyContent: "center", alignItems: 'center' }}>  {card.icon} </span>
                                            </Box>
                                            <Box>
                                                <span style={webStyles.drinksStyle}>
                                                    {card.title}</span>
                                            </Box>
                                        </Box>
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                     ...webStyles.descriptionStyle
                                    }}>
                                        {card.description}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" style={{
                                      ...webStyles.daterangeStyle


                                    }}>
                                        {card.dateRange}
                                    </Typography>
                                  <Box>
                                  {
                                        this.state.value === 1 ? <>
 <Box
                                        style={{
                                          ...webStyles.flexboxStylenew
                                        }}
                                    >
                                        <Box>
                                            <Button
                                                sx={{
                                                  ...webStyles.stillworkingStyle
                                                }}
                                                onClick={this.handleOpenwell}
                                            >
                                                <Typography sx={webStyles.stillGoalStyle}>{configJSON.StillWorking}</Typography>
                                            </Button>
                                        </Box>
                                        <Box>
                                            <button
                                                style={{ ...webStyles.AchievedGoalStyle, cursor: "pointer" }}
                                               
                                                
                                                onClick={this.handleOpen}// Hide section on button click
                                            >
                                                <Typography sx={webStyles.achievegoalstext}>
                                                   {configJSON.AchievedGoal}
                                                </Typography>
                                            </button>
                                        </Box>
                                        

                                    </Box>
                                        </>:""
                                    }
                                  </Box>
                                   
                                </CardContent>
                                <IconButton style={{ position: 'absolute', right: 8, top: 16 }}>
                                    <MoreVertIcon />
                                </IconButton>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </>
        )
    }
    tabSeprate = () => {
        return (
            <>
                <Tab
                    icon={<img src={hook_Hook.default} style={{ width: "35px", height: '35px', marginTop: "-6px" }} />}
                    label="Tobacco or Nicotine"
                    iconPosition="start"
                    sx={{
                      ...webStyles.tab1,
                        backgroundColor: this.state.selectValues === 2 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 2 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 2 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
                <Tab
                    icon={<img src={arrow_Arrow_up.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                    label="Uppers"
                    iconPosition="start"
                    sx={{
                      ...webStyles.tab2,
                        backgroundColor: this.state.selectValues === 3 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 3 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 3 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
                <Tab
                    icon={<img src={arrow_Arrow_arrow_down.default} style={{ width: "34px", height: '34px', marginTop: "-6px" }} />}
                    label="Downers"
                    iconPosition="start"
                    sx={{
                      ...webStyles.tab3,
                        backgroundColor: this.state.selectValues === 4 ? 'white' : 'transparent',
                        borderTopColor: this.state.selectValues === 4 ? '#E5E5E5' : 'transparent',
                        borderRightColor: this.state.selectValues === 4 ? '#E5E5E5' : 'transparent',
                        transition: 'all 0.3s ease',
                        gap: "0.4rem",
                        '&.Mui-selected': {
                            color: "#252525",
                            fontFamily: 'Chromatica-Bold',
                            fontSize: "16px",
                            fontWeight: 700,
                            lineHeight: "20px",
                        }
                    }}
                />
            </>
        )
    }
    subTabsinalcohoandall = () => {
        return (
            <>
                <Grid container>
                    <Grid item md={12}>
                        <Box sx={{ borderColor: 'divider', backgroundColor: "#F7F7F7"}}>

                            <Tabs
                                value={this.state.selectValues}
                                onChange={this.handleChangethird}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="substance tabs"

                                TabIndicatorProps={{
                                    style: { display: 'none' } // Hide default indicator
                                }}
                            >
                                <Tab
                                    icon={<img src={awrad_Award.default} style={{ width: "16px", height: '28px' }} />}
                                    label="Alcohol"
                                    iconPosition="start"
                                    sx={{
                                     ...webStyles.tabs1,
                                        backgroundColor: this.state.selectValues === 0 ? 'white' : 'transparent', // Background red when selected
                                        borderTopColor: this.state.selectValues === 0 ? '#E5E5E5' : 'transparent', // Top border color when selected
                                        borderRightColor: this.state.selectValues === 0 ? '#E5E5E5' : 'transparent', // Right border color when selected
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        // Adjust height
                                        padding: '8px 12px', // Adjust padding
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }// Smooth transition

                                    }}
                                />
                                <Tab
                                    icon={<img src={flower_flower.default} style={{ width: "37px", height: '37px',marginTop:"-6px"  }} />}
                                    label="Cannabis"
                                    iconPosition="start"
                                    sx={{
                                       ...webStyles.tabs2,
                                        backgroundColor: this.state.selectValues === 1 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectValues === 1 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectValues === 1 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                            borderBottom:"none",
                                        }
                                    }}
                                />
                              {this.tabSeprate()}
                                <Tab
                                    icon={<img src={plus_Plus.default} style={{ width: "34px", height: '34px',marginTop:"-6px"   }} />}
                                    label="Others"
                                    iconPosition="start"

                                    sx={{
                                      ...webStyles.tabs3,
                                        backgroundColor: this.state.selectValues === 5 ? 'white' : 'transparent',
                                        borderTopColor: this.state.selectValues === 5 ? '#E5E5E5' : 'transparent',
                                        borderRightColor: this.state.selectValues === 5 ? '#E5E5E5' : 'transparent',
                                        transition: 'all 0.3s ease',
                                        gap: "0.4rem",
                                        '&.Mui-selected': {
                                            color: "#252525",
                                            fontFamily:'Chromatica-Bold' ,
                                            fontSize: "16px",
                                            fontWeight: 700,
                                            lineHeight: "20px",
                                        }
                                    }}
                                />
                            </Tabs>
                       

                        </Box>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.selectValues === 0 && <><this.cardShowdata/></>}
                            {this.state.selectValues === 1 && <><div>ddddddd</div></>}
                        </Box>
                    </Grid>
                </Grid>
  
        </>
    )
   }
HeaderFunction = () =>{
    return (
        <>
         <Grid
          item
          style={{
           ...webStyles.wholeGridStylegoals
          }}
        >
          <Box
                        sx={{                  
                          ...webStyles.wholedivStylegoals}}>
                        <Box
                            sx={{
                              ...webStyles.gapMgoals
                            }}
                        >
                            <Box
                                sx={{
                                  ...webStyles.parentWellgoals
                                }} >
                                <span
                                    style={{
                                      ...webStyles.well__wellStylesgoals
                                    }}
                                >
                                    Well
                                </span>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                  ...webStyles.homeStylemenugoals
                                   
                                }}
                            >
                                Hey, Tom Chandler</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenunext() }}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "70px",
                            }}
                            alt="icon"      
                        />
                    </Box>
        </Grid>
        </>
    )
}
    tabThisWeekfunction = () =>{
    return (
        <>
            <Box>
               
                <Box>
                    <Box sx={{ width: '100%',marginTop:'1.5rem' }}>
                        <Tabs
                        data-test-id="handleTab"
                            value={this.state.values}
                            onChange={this.handleChangesecond}
                            aria-label="basic tabs example"

                            TabIndicatorProps={{
                                style: {
                                    position: 'absolute',
                                    height: '2px',
                                    bottom: '0px',
                                    width: '100%',
                                    border:"none",
                                     backgroundColor: 'white', 
                                },
                            }}

                           
                        >
                             <Tab
                                label="Substance Related Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400,
                                    lineHeight: "20px",
                                    color: "#A0A0A0", 
                                    textTransform: "none",
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 0 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 0 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px",
                                    }
                                }}
                            />
                           
                          
                        
                          <Tab
                                label="Other Life Goals"
                                sx={{
                                    fontFamily:'Chromatica-Bold' ,
                                    fontSize: "16px",
                                    fontWeight: 400, borderBottom:"1px solid #E5E5E5",
                                    lineHeight: "20px",
                                    textTransform: "none",
                                    color: "#A0A0A0", 
                                    borderTop: '1px solid #E5E5E5',
                                    borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
                                    backgroundColor: this.state.values === 1 ? 'white' : 'transparent',
                                    borderTopColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    borderRightColor: this.state.values === 1 ? '#E5E5E5' : 'transparent',
                                    '&.Mui-selected': {
                                        color: "#000",
                                        fontFamily:'Chromatica-Bold' ,
                                        fontSize: "16px",
                                        fontWeight: 700,
                                        lineHeight: "20px", // Style for the selected tab
                                    }
                                }}
                            />
                         

                        </Tabs>
                        <Box style={{marginTop:'1rem'}}>
                            {this.state.values === 0 && <this.subTabsinalcohoandall/>}
                        </Box>
                    </Box>
                </Box>

     
                
        </Box>
        </>
    )
   }
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    
   
    return (
      //Merge Engine DefaultContainer
  <>
  <Grid  item
        style={{
          padding: '10px',
          background: '#f0f0f0',
        }}>
 {this.HeaderFunction()}
  </Grid>
 
        <WhiteBackgroundContainer>
            <img src={wava_right.default} style={{position:"absolute",top:"126px",right:0}}/>
                <Box sx={{display:"flex",justifyContent:'flex-start',alignItems:"center",gap:'0.5rem', padding: "1rem 0",position: "relative", zIndex: 2 }}
            onClick={() => { this.goToBackDashboard() }}
                >
                    <KeyboardBackspaceIcon />
                     <Typography style={webStyles.backStyle}>Back</Typography>
                </Box>
               <Box style={{marginTop:"0.9rem"}}>
               <Box style={webStyles.addGoalsStyle}>
                    <Box>
                         <Typography sx={webStyles.goalsStyleleft}>Goals</Typography>
                    </Box>
                    <Box sx={{position: "relative", zIndex: 2}}>
                        <button
                        style={{
                            padding: "0.5rem",     
                            width: "194px",          
                            borderRadius: "8px",     
                            border: "none",           
                            background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
                        }}
                        >
                           <div style={webStyles.iconflex}>
                           <Typography sx={webStyles.addGoalsStyleAdd}>Add More Goals</Typography>
                           <AddCircleOutlineIcon style={{width:"16px",height:"16px",color:"#DADADA"}}/>
                           </div>
                        </button>
                     </Box>
                </Box>
               </Box>

                <Box>
                    <Box sx={{ width: '100%' }}>
                        <Tabs
                           sx={{ ...webStyles.basicStyle}}
                           aria-label="basic tabs example"
                            onChange={this.handleChange}
                            TabIndicatorProps={{
                                style: {
                                     backgroundColor: '#00A7D1',
                                     marginTop:"-10px" // Custom indicator color
                                },
                            }}
                            value={this.state.value}
                         
                        >
                            <Tab
                                label="This week"
                                sx={{
                                  ...webStyles.selectStyleMUI
                                }}
                            />
                            <Tab
                                label="Last week"
                                sx={{
                                  ...webStyles.lastWeekStyleMui
                                }}
                            />

                        </Tabs>
                   <Box style={{marginTop:"1rem"}}>
                   <Box>
                    <Typography sx={webStyles.Statisticsstyle}>
                        Statistics
                    </Typography>
                </Box>
                <Box style={{ marginTop: "1rem" }}>
                    <Typography sx={webStyles.youaidyourStyle}>This week, you said your Goals are to:</Typography>
                </Box>
                      
                    </Box>  <Box sx={{ p: 3 }}>
                            {this.state.value === 0 && <this.tabThisWeekfunction/>}
                            {this.state.value === 1 && <this.tabThisWeekfunction/>}
                           
                        </Box>
                    </Box>
                </Box>

              

          
          
        </WhiteBackgroundContainer>
        <Dialog onClose={this.handleClose} open={this.state.openCongrates}
                PaperProps={{
                    style: { borderRadius: 24 }, // Add border radius here
                }}
            >
                <DialogTitle>
                    <Box
                        sx={{
                          ...webStyles.dialogStyleparent
                        }}
                    >
                        <Box
                            sx={{
                            ...webStyles.dialogContentStyle
                            }}
                        >
                            <div
                                style={{
                                ...webStyles.flex__box

                                }}
                            >
                                <img
                                    src={award_a6.default}
                                    alt="award_award"
                                    style={{ textAlign: 'center' }}
                                />
                            </div>
                        </Box>

                        <Typography sx={webStyles.Congratulationsstyle}>
                            Congratulations!
                        </Typography>
                        <Typography sx={webStyles.countingStyle}>Your counting drinks goal <br /> is achieved.</Typography>
                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={this.handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>
            <Dialog onClose={this.handleClosewell} open={this.state.openWell}
                PaperProps={{
                    style: { borderRadius: 24 },
                    background: "#0F4257"// Add border radius here
                }}
            >
                <DialogTitle sx={{ background: "#0F4257" }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center', // To center text as well
                            width: '100%',
                        }}
                    >

                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                            <Typography sx={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 700,
                                fontSize: '40px',
                                lineHeight: '40px',
                                letterSpacing: '-0.04em',
                                color: "#EBFF00",
                                marginTop: "1.2rem",
                            }}>Well</Typography>
                            <Typography style={{
                                fontFamily: "Josefin Sans",
                                fontWeight: 700,
                                fontSize: '40px',
                                lineHeight: '40px',
                                letterSpacing: '-0.04em',
                                color: "white",
                                marginTop: "1.2rem",
                            }}>Spring</Typography>
                        </div>
                        <Typography sx={{ ...webStyles.Congratulationsstyleone, marginTop: "1.2rem" }}>
                            Way to keep going! Do you want to make any<br />
                            changes in striving to meet this goal?!
                        </Typography>

                    </Box>
                    <Box
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "0.8rem",
                            marginTop: "1rem",
                            marginBottom: "2rem"
                        }}
                    >
                        <Box>
                            <button
                                style={{ ...webStyles.Styleyes, cursor: "pointer" }}
                                onClick={this.handleButtonClick} // Hide section on button click
                            >
                                <Typography sx={{ ...webStyles.achievegoalstext, color: "white" }}>
                                    Yes
                                </Typography>
                            </button>
                        </Box>

                        <Box>
                            <Button
                                sx={{
                                    width: "169px",
                                    borderRadius: "8px",
                                    padding: "0.6rem",
                                    textTransform: "none",
                                    border: "1px solid #ffffff",
                                    cursor: "pointer",
                                }}
                            >
                                <Typography sx={webStyles.noStyle}>No</Typography>
                            </Button>
                        </Box>


                    </Box>

                    <IconButton
                        aria-label="close"
                        onClick={this.handleClosewell}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            </Dialog>
     
        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
    wholedivStylegoals:{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        display: "flex",
        padding: "0 20px",
        height: "100%"
      },
      wholeGridStylegoals:{
        height: '88px',
        backgroundColor: '#0F4257',
        borderRadius: '16px',
        width: '98.5%',
        margin: '10px',
      },
      well__wellStylesgoals:{
        marginLeft: "40px",
        fontFamily: "Josefin Sans",
        lineHeight: "40px",
        fontWeight: 700,
        marginBottom: "4px",
        color: "#EBFF00",
        fontSize: "40px",
       },
       homeStylemenugoals:{
        mt: "3px",
        fontSize: "20px",
        lineHeight: "20px",
        color: "#FFFFFF",
        fontFamily: "Chromatica-Bold",
        fontWeight: 700,
      },
      gapMgoals:{
        gap: "20px",
        display: "flex",
        alignItems: "center",
      },
      parentWellgoals:{
        fontFamily: "Josefin Sans",
        color: "#ffffff",
        fontSize: "40px",
        fontWeight: 300,
      },








    tabs3:{
        display: 'flex',
        flexDirection: 'row',
        fontFamily:'Chromatica-Bold' ,borderBottom:"1px solid #E5E5E5",
        textTransform: "none",
        color: "$252525",
        alignItems:"stretch",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    tabs2:{
        display: 'flex',
        color: "$252525",
        flexDirection: 'row',
        alignItems:"stretch",
        justifyContent: 'flex-start',
        fontFamily:'Chromatica-Bold' ,
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px', borderBottom:"1px solid #E5E5E5",
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5',
        borderRadius: "1px 8px 0px 8px",
    },
    countingStyle:{
        fontFamily:'Chromatica-Regular' ,
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '24px',
      color:"#252525",
      marginBottom:"3rem",
      marginTop:"1rem"
    },
    drinksStyle:{
        fontFamily:'Chromatica-Regular' ,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px'
    },
    noStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '24px',
        color:"white"
    },
    topParentGrid:{
        padding: '10px',
        backgroundColor: '#f0f0f0',
    },
    parentBox:{
        right: 0,
        marginRight: '30px',
        position: "absolute",
        top: 0,

    },
    Congratulationsstyleone:{
        fontFamily: 'Chromatica-Regular',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '24px',
        color:'white'
    },
    tab3:{
        display: 'flex',
        flexDirection: 'row',
        color: "$252525",
        alignItems: "stretch",
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        justifyContent: 'flex-start',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    heyTomStyle:{
        position: "absolute",
        top: "20px",
        left: "260px",
        fontSize: "17px",
        width: '180px',
        color: "white",
        fontFamily: 'Chromatica-Bold',
    },
    SpringStyle:{
        fontSize: "40px", fontWeight: "normal", marginLeft: "5px" 
    },
    wellStyle:{
        fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px"
    },
    childGrid:{
        color: "#ffffff",
        fontFamily: 'Josefin Sans',
        position: "absolute",
        top: "36px",
        left: '48px',
        width:'95%'
    },
    daterangeStyle:{
        marginTop: 8,
        color: "#00A7D1",
        fontFamily: 'Chromatica-Bold',
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '20px',
    },

    parentGrid:{
        height: '88px',
        backgroundColor: '#0F4257',
        margin: '10px',
        borderRadius: '16px',
        width: '99%',
    },
    tab2:{
        display: 'flex',
        flexDirection: 'row',
        color: "$252525",
        alignItems: "stretch",
        justifyContent: 'flex-start',
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    youaidyourStyle:{
        fontFamily:'Chromatica-Regular',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        color:"#252525"

    },
    Congratulationsstyle:{
        fontFamily: 'Josefin Sans',
        fontSize: '40px',
        fontWeight: 700,
        lineHeight: '40px',
        letterSpacing: '-0.04em',
        color:'#00A7D1',
    },
  
    Statisticsstyle:{
        fontFamily:'Chromatica-Regular' ,
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        color:"#000000"
    },
    tab1:{
        display: 'flex',
        color: "$252525",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: "stretch",
        fontFamily: 'Chromatica-Bold', borderBottom: "1px solid #E5E5E5",
        textTransform: "none",
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '20px',
        borderTop: '1px solid #E5E5E5',
        borderRight: '1px solid #E5E5E5', borderRadius: "1px 8px 0px 0px",
    },
    basicStyle:{
        borderBottom: 2, borderColor: 'divider',color:"#E3E3E3" 
    },
    iconflex:{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap:'0.5rem'
    },
    buttonStyleadd:{
        width: "194px",          // Fixed width of 194px
    padding:"0.5rem",              // Gap between children elements (works in flexbox or grid)
        borderRadius: "8px 0 0 0",  // Border-radius (top-left rounded)
        opacity: 0
    },
    goalsStyleleft:{
        fontFamily: "Josefin Sans",
        fontSize: "32px",
        fontWeight: 600, // Numeric value for font-weight
        lineHeight: "32px",
        color:"#00A7D1"
    },
    addGoalsStyleAdd:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "24px",
        color:'#ffffff',
       
    },
    Styleyes:{
        padding: "0.8rem",     
        width: "169px",          
        borderRadius: "8px",     
        border: "none",       
        color:"white",    
        background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
    },
    addGoalsStyle:{
        display:"flex",justifyContent:'space-between',alignItems:"center"
    },
    backStyle:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 700, 
        lineHeight: "20px"
    },
    flexproperty: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
  },
    testStyleforHeyTom: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        color: "#ffffff",
        marginLeft: "1.3rem"
    },
    HeyTomStyle: {
        fontFamily:'Chromatica-Bold' ,
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        color: "#EBFF00"
    },
    tabs1:{
        display: 'flex',
        alignItems:"stretch",
          flexDirection: 'row',                                   
          fontFamily:'Chromatica-Bold' ,
          textTransform: "none",
          fontSize: '16px',
          fontWeight: 400,
          lineHeight: '28px',
          color: "$252525",
          justifyContent: 'flex-start',
          borderTop: '1px solid #E5E5E5',
          borderRight: '1px solid #E5E5E5',
          borderRadius: "1px 8px 0px 0px",
    },
    selectStyleMUI:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "20px",
        color: "#A0A0A0", // Text color
        textTransform: "none",
        '&.Mui-selected': {
            color: "#000",
            fontFamily:'Chromatica-Bold' ,
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px",
        },
    },
    lastWeekStyleMui:{
        fontFamily:'Chromatica-Bold' ,
        fontSize: "16px",
        fontWeight: 400,  
        lineHeight: "20px",

        textTransform: "none",
        color: "#A0A0A0", // Text color
        '&.Mui-selected': {
            color: "#000",
            fontFamily:'Chromatica-Bold' ,
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "20px", // Style for the selected tab
        }
    },
  threebythreeStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    

  },
  flex__box:{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  labelstylestepper:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
  dialogStyleparent:{
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center', // To center text as well
    width: '100%',
  },
  descriptionStyle:{
    fontFamily:'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#252525',
    marginTop: "10px"
  },
YourStyle:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1"

},
stillGoalStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"#1D5E79",
    
 },
 flexboxStylenew:{
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "0.8rem",
    marginTop: "1rem",
 },
 AchievedGoalStyle:{
    padding: "0.4rem",     
    width: "131px",          
    borderRadius: "8px",     
    border: "none",           
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
  },
 achievegoalstext:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"white",
    textAlign:'center'
    
 },
 dialogContentStyle:{
    width: '80px',
    height: '80px',
    boxShadow: '0px 0px 18px 0px #F3A622',
    background: '#F3A622',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '16px',
    marginTop: "2rem", // Adds spacing below the image
 },

addGoalsStylebox:{
    display:"flex",justifyContent:'space-between',alignItems:"center"
},
stillworkingStyle:{
    width: "131px",
    borderRadius: "8px",
    padding: "0.4rem",
    textTransform: "none",
    border: "1px solid #1D5E79",
    cursor: "pointer",
}
};

// Customizable Area End