import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import dayjs from "dayjs";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  // Customizable Area Start
  navigation: any;
  id: any;
  children?: React.ReactNode;
  // Customizable Area End
}

// Customizable Area Start
export interface Touched {
  firstName: boolean;
  lastName: boolean;
  phoneNumber: boolean;
  email: boolean;
  organization: boolean;
  teamName: boolean;
  userType: boolean;
  rating: boolean;
  gender: boolean;
  address: boolean;
  country: boolean;
  state: boolean;
  city: boolean;
}

export interface Error {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
}

export interface ResponseJson {
  id: string;
  attributes: {
    first_name: string;
    last_name: string;
    phone_number: string;
    email: string;
    organization: string;
    team_name: string;
    i_am: string;
    stars_rating: string;
    gender: string;
    address: string;
    country: string;
    state: string;
    city: string;
    file: {
      file_name: string;
    };
  };
}

export interface Dropdown {
  label: string;
  value: string;
}

export interface Data {
  userId: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: string;
}
// Customizable Area End

interface S {
  // Customizable Area Start
  loading: boolean;
  categoryNameStore:any,
  userId: string;
  data: Data[];
  filterData: Data[];
  page: number;
  dataLength: number;
  rowsPerPage: number;
  openDialogName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  organization: string;
  teamName: string;
  userType: string;
  rating: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  file: File | null | string;
  isChange: boolean;
  tobacoMeasureType:string
  tobacoReduceState:string
  cigarettesReduceType:string
  lastWeekCigarettesCount:number
  nextweekCigarettesReduceCount:number
  pastSevenDaysCigarettesCount:number
  nextSevenDaysCigarettesCount:number
  isTakingBreakCigarettes:string,
  tobaccoBreakDay: dayjs.Dayjs[]; 
  tobaccoBreakSelectedDate:string;
  selectedTab:any,
  tobaccoCalendar:boolean,
  tobaccoCalendarSelectedDate:any,
  tobaccoOtherMeasureTypeTextValue:string,
  tobaccoreduceHowMuchTextValue:string
  tobaccoOthersTextValue:string,
  upperReduceState:string,
  upperMetricValue:string,
  lastWeekUppersOuncesCount:number,
  nextweekUppersOuncessReduceCount:number,
  pastSevenDaysUppersCount:number,
  nextSevenDaysUppersCount:number,
  isTakingBreakUppers:string,
  uppersBreakSelectedDate:string,
  uppersCalendarSelectedDate:any,
  lastWeekUppersHitsCount:number,
  nextweekUppersHitsReduceCount:number,
  lastWeekUppersDollarsTextValue:string,
  nextweekUppersDollarsReduceTextValue:string,
  uppersOthersTextValue:string,
  downersReduceState:any,
  downersMetricValue:string,
  lastWeekDownersOuncesCount:number,
  nextweekDownersOuncessReduceCount:number,
  pastSevenDaysDownersCount:number,
  nextSevenDaysDownersCount:number,
  isTakingBreakDowners:string,
  downersBreakSelectedDate:string
  lastWeekDownersHitsCount:number,
  nextweekDownersHitsReduceCount:number,
  lastWeekDownersDollarsTextValue:string,
  nextweekDownersDollarsReduceTextValue:string,
  DownersOthersTextValue:string,
  downersCalendarSelectedDate:any,
  isUppersApiCalled:boolean,
  isTobaccoApiCalled:boolean,
  isDownersApiCalled:boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getUserListApiCallId: string = "";
  getCategoryValueExtrat:string="";
  addUserApiCallId: string = "";
  editUserApiCallId: string = "";
  getUppersApiCallId: string = "";
  postUppersApiCallId: string = "";
  postTobaccoApiCallId: string = "";
  getTobaccoApiCallId: string = "";
  postDownersApiCallId: string = "";
  getDownersApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      categoryNameStore:[],
      userId: "",
      data: [],
      page: 0,
      dataLength: 0,
      rowsPerPage: 1,
      filterData: [],
      openDialogName: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
      email: "",
      organization: "",
      teamName: "",
      userType: "",
      rating: "",
      gender: "",
      address: "",
      country: "",
      state: "",
      city: "",
      file: null,
      isChange: false,
      tobacoMeasureType: "Others",
      tobacoReduceState: "reduceHowMuch",
      cigarettesReduceType: "Others",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
      isTakingBreakCigarettes: "true",
      tobaccoBreakDay: [],
      tobaccoBreakSelectedDate: "",
      selectedTab: "",
      tobaccoCalendar: false,
      tobaccoCalendarSelectedDate: null,
      tobaccoOtherMeasureTypeTextValue: "",
      tobaccoreduceHowMuchTextValue: "",
      tobaccoOthersTextValue: "",
      upperReduceState: "UppersReduceHowMuch",
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      isTakingBreakUppers: "yes",
      uppersBreakSelectedDate: "",
      uppersCalendarSelectedDate: null,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      uppersOthersTextValue: "",
      downersReduceState: "downersReduceHowMuch",
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "yes",
      downersBreakSelectedDate: "",
      downersCalendarSelectedDate: null,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      DownersOthersTextValue:'',
      isUppersApiCalled:false,
      isTobaccoApiCalled:false,
      isDownersApiCalled:false
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {

        switch (apiRequestCallId) {
          case this.postUppersApiCallId:
            this.postUppersResponse(responseJson)
            break;
          case this.getUppersApiCallId:
            this.getUppersApiResponse(responseJson)
            break;
          case this.postTobaccoApiCallId:
            this.postTobaccoApiResponse(responseJson)
            break;
          case this.getTobaccoApiCallId:
            this.getTobaccoApiResponse(responseJson)
            break;
          case this.getCategoryValueExtrat:
            this.handleCategory(responseJson)
          case this.postDownersApiCallId:
            this.postDownersResponse(responseJson)  
            break;
          case this.getDownersApiCallId:
            this.getDownersApiResponse(responseJson)
            break;      
        } 
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleCategory = (responseJson: any) => {
    const categoryNames = responseJson?.categories?.map((category: any) => category?.category_name);
    this.setState({categoryNameStore:categoryNames})
    const predefinedSequence = ['Alcohol', 'Cannabis', 'Tobacco or Nicotine', 'Uppers', 'Downers', 'Others'];
const firstMatchingCategory = predefinedSequence.find(category => categoryNames?.includes(category));

console.log(firstMatchingCategory, "changeme");
if(firstMatchingCategory==='Tobacco or Nicotine'){
  this.setState({ selectedTab:'tobaccoOrNicotine'})
}
else if(firstMatchingCategory==='Alcohol'){
  this.setState({ selectedTab:'alcohol'})
}
else if(firstMatchingCategory==='Cannabis'){
  this.setState({ selectedTab:'cannabis'})
}
else if(firstMatchingCategory==='Uppers'){
  this.setState({ selectedTab:'uppers'})
}
else if(firstMatchingCategory==='Downers'){
  this.setState({ selectedTab:'downers'})
}
else if(firstMatchingCategory==='Others'){
  this.setState({ selectedTab:'Others'})
}
  };
  
  postUppersResponse=(responseJson:any)=>{
    const { categoryNameStore } = this.state;
    if (
      responseJson.message ===
      "Substance goal response saved successfully"
    ) {
      if (categoryNameStore.includes("Downers")) {
        this.setState({ selectedTab: "downers" });
      } else if (categoryNameStore.includes("Others")) {
        this.setState({ selectedTab: "Others" });
      } else {
        console.log("No category found for tab switching");
      }
    }
  }
  getUppersApiResponse=(responseJson:any)=>{
    const UppersResponses = responseJson?.responses;
    const selectedState =
      UppersResponses?.SelectedReduceState?.selectedState;
    const SelectedUpperMetricValue =
      UppersResponses?.selected_value?.selectedValue?.upperMetricValue;
    const SelectedStateValue =
      UppersResponses?.selected_value?.selectedValue;
      if(selectedState){
        this.setState({
          upperReduceState: selectedState,
        });
      }
    if (selectedState == "UppersReduceHowMuch") {
      this.setState({
        upperMetricValue: SelectedUpperMetricValue,
      });
      if (SelectedUpperMetricValue == "ounces") {
        this.setState({
          lastWeekUppersOuncesCount:
            SelectedStateValue?.lastWeekUppersOuncesCount,
          nextweekUppersOuncessReduceCount:
            SelectedStateValue?.nextweekUppersOuncessReduceCount,
        });
      } else if (SelectedUpperMetricValue == "dollars") {
        this.setState({
          lastWeekUppersDollarsTextValue:
            SelectedStateValue?.lastWeekUppersDollarsTextValue,
          nextweekUppersDollarsReduceTextValue:
            SelectedStateValue?.nextweekUppersDollarsReduceTextValue,
        });
      } else if (SelectedUpperMetricValue == "hits") {
        this.setState({
          lastWeekUppersHitsCount:
            SelectedStateValue?.lastWeekUppersHitsCount,
          nextweekUppersHitsReduceCount:
            SelectedStateValue?.nextweekUppersHitsReduceCount,
        });
      }
    } else if (selectedState == "UppersReduceHowOften") {
      this.setState({
        pastSevenDaysUppersCount:
          SelectedStateValue?.pastSevenDaysUppersCount,
        nextSevenDaysUppersCount:
          SelectedStateValue?.nextSevenDaysUppersCount,
      });
    } else if (selectedState == "UppersStopCompletely") {
      this.setState({
        isTakingBreakUppers: SelectedStateValue?.isTakingBreakUppers,
        uppersBreakSelectedDate:
          SelectedStateValue?.uppersBreakSelectedDate,
        uppersCalendarSelectedDate:
          SelectedStateValue?.uppersCalendarSelectedDate,
      });
    } else if (selectedState == "Others") {
      this.setState({
        uppersOthersTextValue: SelectedStateValue?.uppersOthersTextValue,
      });
    }
  }

  postTobaccoApiResponse=(responseJson:any)=>{
    const { categoryNameStore } = this.state;

    if (
      responseJson.message ===
      "Substance goal response saved successfully"
    ) {
      if (categoryNameStore.includes("Uppers")) {
        this.setState({ selectedTab: "uppers" });
      } else if (categoryNameStore.includes("Downers")) {
        this.setState({ selectedTab: "downers" });
      } else if (categoryNameStore.includes("Others")) {
        this.setState({ selectedTab: "Others" });
      } else {
        console.log("No category found for tab switching");
      }
    }
}
  getTobaccoApiResponse=(responseJson:any)=>{
    const TobaccoResponses = responseJson?.responses;
    const selectedState =
      TobaccoResponses?.SelectedReduceState?.selectedState;
    const SelectedStateValue =
      TobaccoResponses?.selected_value?.selectedValue;
      console.log(SelectedStateValue,'SelectedStateValue')
      const tobacoMeasureType = SelectedStateValue?.tobacoMeasureType;
      const cigarettesReduceType = SelectedStateValue?.cigarettesReduceType;
      if(selectedState){
      this.setState({
        tobacoReduceState: selectedState,
      });
    }
      if (selectedState == "reduceHowMuch") {
        this.setState({
          cigarettesReduceType: cigarettesReduceType,
          tobacoMeasureType: tobacoMeasureType
        });
        if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "numberOfCigarettes") {
          this.setState({
            lastWeekCigarettesCount: SelectedStateValue?.lastWeekCigarettesCount,
            nextweekCigarettesReduceCount: SelectedStateValue?.nextweekCigarettesReduceCount
          });
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "numberOfCigarettes") {
          this.setState({
            tobaccoOtherMeasureTypeTextValue: SelectedStateValue?.tobaccoOtherMeasureTypeTextValue,
            lastWeekCigarettesCount: SelectedStateValue?.lastWeekCigarettesCount,
            nextweekCigarettesReduceCount: SelectedStateValue?.nextweekCigarettesReduceCount
          });
        } else if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "Others") {
          this.setState({
            tobaccoreduceHowMuchTextValue: SelectedStateValue?.tobaccoreduceHowMuchTextValue,
          });
        } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "Others") {
          this.setState({
            tobaccoOtherMeasureTypeTextValue: SelectedStateValue?.tobaccoOtherMeasureTypeTextValue,
            tobaccoreduceHowMuchTextValue: SelectedStateValue?.tobaccoreduceHowMuchTextValue,
          });
        }
      } else if (selectedState == "reduceHowOften") {
        this.setState({
          pastSevenDaysCigarettesCount:
            SelectedStateValue?.pastSevenDaysCigarettesCount,
          nextSevenDaysCigarettesCount:
            SelectedStateValue?.nextSevenDaysCigarettesCount,
        });
      } else if (selectedState == "stopCompletely") {
        this.setState({
          isTakingBreakCigarettes: SelectedStateValue?.isTakingBreakCigarettes,
          tobaccoBreakSelectedDate:
            SelectedStateValue?.tobaccoBreakSelectedDate,
          tobaccoCalendarSelectedDate:
            SelectedStateValue?.tobaccoCalendarSelectedDate,
        });
      } else if (selectedState == "Others") {
        this.setState({
          tobaccoOthersTextValue: SelectedStateValue?.tobaccoOthersTextValue,
        });
      }
  }

  postDownersResponse=(responseJson:any)=>{
    const { categoryNameStore } = this.state;
    const nextTab = categoryNameStore.includes("Others");
    if (
      responseJson.message ==
      "Substance goal response saved successfully"  && nextTab
    ) {
      this.setState({ selectedTab: "Others" });
    }
  }

  getDownersApiResponse=(responseJson:any)=>{
    const DownersResponses = responseJson?.responses;
    const selectedState =
      DownersResponses?.SelectedReduceState?.selectedState;
    const SelectedStateValue =
      DownersResponses?.selected_value?.selectedValue;
    const SelectedDownersMetricValue = SelectedStateValue?.downersMetricValue;
      if(selectedState){
        this.setState({
          downersReduceState: selectedState,
        });
      }
    if (selectedState == "downersReduceHowMuch") {
      this.setState({
        downersMetricValue: SelectedDownersMetricValue,
      });
      if (SelectedDownersMetricValue == "ounces") {
        this.setState({
          lastWeekDownersOuncesCount:
            SelectedStateValue?.lastWeekDownersOuncesCount,
          nextweekDownersOuncessReduceCount:
            SelectedStateValue?.nextweekDownersOuncessReduceCount,
        });
      } else if (SelectedDownersMetricValue == "dollars") {
        this.setState({
          lastWeekDownersDollarsTextValue:
            SelectedStateValue?.lastWeekDownersDollarsTextValue,
          nextweekDownersDollarsReduceTextValue:
            SelectedStateValue?.nextweekDownersDollarsReduceTextValue,
        });
      } else if (SelectedDownersMetricValue == "hits") {
        this.setState({
          lastWeekDownersHitsCount:
            SelectedStateValue?.lastWeekDownersHitsCount,
          nextweekDownersHitsReduceCount:
            SelectedStateValue?.nextweekDownersHitsReduceCount,
        });
      }
    } else if (selectedState == "downersReduceHowOften") {
      this.setState({
        pastSevenDaysDownersCount:
          SelectedStateValue?.pastSevenDaysDownersCount,
        nextSevenDaysDownersCount:
          SelectedStateValue?.nextSevenDaysDownersCount,
      });
    } else if (selectedState == "downersStopCompletely") {
      this.setState({
        isTakingBreakDowners: SelectedStateValue?.isTakingBreakDowners,
        downersBreakSelectedDate:
          SelectedStateValue?.downersBreakSelectedDate,
        downersCalendarSelectedDate:
          SelectedStateValue?.downersCalendarSelectedDate,
      });
    } else if (selectedState == "Others") {
      this.setState({
        DownersOthersTextValue: SelectedStateValue?.DownersOthersTextValue,
      });
    }
  }

  async componentDidMount() {
    this.getCatergoriesValue();
    this.UppersGetApiCall();
    this.TobaccoGetApiCall();
    const today = dayjs();
    const upcomingDates = Array.from({ length: 5 }, (_, index) =>
      today.add(index, "day")
    );
    this.setState({
      tobaccoBreakDay: upcomingDates,
      tobaccoBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      uppersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      downersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
    });
    window.addEventListener("keydown", this.handleKeyDown);
  }
  async componentWillUnmount() {
    window.removeEventListener("keydown", this.handleKeyDown);
  }

  handleKeyDown(event: KeyboardEvent) {
    const focusedElement = document.activeElement as HTMLElement;
    const modalContent = document.getElementById("modal-content");
    if (
      focusedElement &&
      focusedElement.tagName === "INPUT" &&
      focusedElement.getAttribute("type") === "radio"
    ) {
      if (event.key === "ArrowDown" || event.key === "ArrowUp") {
        event.preventDefault();
      }
    }
    if (event.key === "ArrowDown" && modalContent) {
      modalContent.scrollBy({ top: 100, behavior: "smooth" });
    } else if (event.key === "ArrowUp" && modalContent) {
      modalContent.scrollBy({ top: -100, behavior: "smooth" });
    }
  }
  getCatergoriesValue = () => {
    let tokenHandle = localStorage.getItem("token") || "{}";
    const header = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: tokenHandle
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoryValueExtrat = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.categoryShowPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserListApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  };
  handleCountChange = (key: keyof S, value: number) => {
    this.setState((prevState) => {
      const currentCount = prevState[key] as number;
      if (currentCount === 0 && value < 0) {
        return null;
      }

      return {
        [key]: currentCount + value,
      } as unknown as Pick<S, keyof S>;
    });
  };

  async componentDidUpdate() {
    if (this.state.selectedTab === "uppers" && !this.state.isUppersApiCalled) {
      this.UppersGetApiCall();
      this.setState({ isUppersApiCalled: true });
    } else if(this.state.selectedTab === "tobaccoOrNicotine" && !this.state.isTobaccoApiCalled){
      this.TobaccoGetApiCall()
      this.setState({ isTobaccoApiCalled: true });
    } else if(this.state.selectedTab === "downers" && !this.state.isDownersApiCalled){
      this.DownersGetApiCall()
      this.setState({ isDownersApiCalled: true });
    }
  }

  handleCigarettesReduceTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value, name } = event.target;
    this.setState({ [name]: value } as Pick<S, "cigarettesReduceType">);
  };
  handleTobaccoBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ tobaccoBreakSelectedDate: event.target.value });
  };
  handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    this.setState({
      selectedTab: newValue,
    });
  };

  handleUppersBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ uppersBreakSelectedDate: event.target.value });
  };

  moveToUppers = () => {
    const header = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const selectedTobacoReduceState = this.state.tobacoReduceState;
    const tobacoMeasureType = this.state.tobacoMeasureType;
    const cigarettesReduceType = this.state.cigarettesReduceType;  
    let selectedValue;
    if (selectedTobacoReduceState == "reduceHowMuch") {
      if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "numberOfCigarettes") {
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          cigarettesReduceType: this.state.cigarettesReduceType,
          lastWeekCigarettesCountQuestion:
            "Over the last week, how many Cigarettes did you have on a typical day?",
            lastWeekCigarettesCount: this.state.lastWeekCigarettesCount,
            nextweekCigarettesReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
            nextweekCigarettesReduceCount:
            this.state.nextweekCigarettesReduceCount,
        };
      } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "numberOfCigarettes") {
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
          cigarettesReduceType: this.state.cigarettesReduceType,
          lastWeekCigarettesCountQuestion:
            "Over the last week, how many Cigarettes did you have on a typical day?",
            lastWeekCigarettesCount: this.state.lastWeekCigarettesCount,
            nextweekCigarettesReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
            nextweekCigarettesReduceCount:
            this.state.nextweekCigarettesReduceCount,
        };
      } else if (tobacoMeasureType == "Cigarettes" && cigarettesReduceType == "Others") {
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          cigarettesReduceType: this.state.cigarettesReduceType,
          tobaccoreduceHowMuchTextValue: this.state.tobaccoreduceHowMuchTextValue,        
        };
      } else if (tobacoMeasureType == "Others" && cigarettesReduceType == "Others") {
        selectedValue = {
          tobacoMeasureType: this.state.tobacoMeasureType,
          tobaccoOtherMeasureTypeTextValue: this.state.tobaccoOtherMeasureTypeTextValue,
          cigarettesReduceType: this.state.cigarettesReduceType,
          tobaccoreduceHowMuchTextValue: this.state.tobaccoreduceHowMuchTextValue, 
        };
      }
    } else if (selectedTobacoReduceState == "reduceHowOften") {
      selectedValue = {
        pastSevenDaysCigarettesCountQuestion:
          "Out of the past 7 days, how many days did you use [what they entered in supplement ex. cigarettes, vapes, etc]",
          pastSevenDaysCigarettesCount: this.state.pastSevenDaysCigarettesCount,
          nextSevenDaysCigarettesCountQuestion:
          "Out of the NEXT 7 days, how many days do you want to use [what they entered in supplement]?",
          nextSevenDaysCigarettesCount: this.state.nextSevenDaysCigarettesCount,        
      };
    } else if (selectedTobacoReduceState == "stopCompletely") {
      selectedValue = {
        isTakingBreakCigarettesQuestion:"This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from [what they entered in supplement] at this point?",
        isTakingBreakCigarettes: this.state.isTakingBreakCigarettes,
        tobaccoBreakSelectedDateQuestion:
          "Way to commit to taking a break from [what they mentioned in supplement]! When day this week do you want to start?",
        tobaccoBreakSelectedDate: this.state.tobaccoBreakSelectedDate,
        tobaccoCalendarSelectedDateQuestion:
          "Selected Tobacco stop completely calendar Date",
        tobaccoCalendarSelectedDate: this.state.tobaccoCalendarSelectedDate,
      };
    } else if (selectedTobacoReduceState == "Others") {
      selectedValue = {
        tobaccoOthersTextValueQuestion:
          "What do you want to see happen with your use?",
        tobaccoOthersTextValue: this.state.tobaccoOthersTextValue,
      };
    }

    const httpBody = {
      substance_goal_response: {
        category_name: "tobaccoOrNicotine",
        responses: {
          SelectedReduceState: {
            selectedState: this.state.tobacoReduceState,
          },
          selected_value: {
            selectedValue: selectedValue,
          },
        },
      },
    };

    const TobaccorequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postTobaccoApiCallId = TobaccorequestMessage.messageId;
    TobaccorequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TobaccoAPiEndPoints
    );

    TobaccorequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    TobaccorequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    TobaccorequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceAPiMethod
    );

    runEngine.sendMessage(TobaccorequestMessage.id, TobaccorequestMessage);

    return true;
  };

  substanceGoals() {
    this.props.navigation.navigate("SubstanceGoals");
  }
  hamburgerMenu() {
    this.props.navigation.navigate("HamburgerMenu");
  }

  handleDownersBreakDateChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    this.setState({ downersBreakSelectedDate: event.target.value });
  };

  TobaccoGetApiCall() {

    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getTobaccoApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getTobaccoApiCallId = getTobaccoApiCall.messageId;

    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.TobaccoGetApiendPoint
    );

    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getTobaccoApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getTobaccoApiCall.id, getTobaccoApiCall);
  }

  UppersGetApiCall() {
    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getUppersApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUppersApiCallId = getUppersApiCall.messageId;

    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UppersGetApiendPoint
    );

    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getUppersApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getUppersApiCall.id, getUppersApiCall);
  }

  DownersGetApiCall() {
    const headers = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const getDownersApiCall = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDownersApiCallId = getDownersApiCall.messageId;

    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DownersGetApiendPoint
    );

    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDownersApiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceGetApiMethod
    );
    runEngine.sendMessage(getDownersApiCall.id, getDownersApiCall);
  }


  moveToDowners = () => {
    const header = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const selectedUppersState = this.state.upperReduceState;
    const upperMetricValue = this.state.upperMetricValue;
    let selectedValue;
    if (selectedUppersState == "UppersReduceHowMuch") {
      if (upperMetricValue == "ounces") {
        selectedValue = {
          upperMetricValue: this.state.upperMetricValue,
          lastWeekUppersOuncesCountQuestion:
            "Over the last week, how many Ounces did you have on a typical day when you use?",
            lastWeekUppersOuncesCount: this.state.lastWeekUppersOuncesCount,
          nextweekUppersOuncessReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
          nextweekUppersOuncessReduceCount:
            this.state.nextweekUppersOuncessReduceCount,
        };
      } else if (upperMetricValue == "dollars") {
        selectedValue = {
          upperMetricValue: this.state.upperMetricValue,
          lastWeekUppersDollarsTextValueQuestion:
            "Over the last week, how many $$ did you have on a typical day when you use?",
          lastWeekUppersDollarsTextValue:
            this.state.lastWeekUppersDollarsTextValue,
          nextweekUppersDollarsReduceTextValueQuestion:
            "What would you like to reduce that amount to this next week?What is your goal?",
          nextweekUppersDollarsReduceTextValue:
            this.state.nextweekUppersDollarsReduceTextValue,
        };
      } else if (upperMetricValue == "hits") {
        selectedValue = {
          upperMetricValue: this.state.upperMetricValue,
          lastWeekUppersHitsCountQuestion:
            "Over the last week, how many Hits did you have on a typical day when you use?",
          lastWeekUppersHitsCount: this.state.lastWeekUppersHitsCount,
          nextweekUppersHitsReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
          nextweekUppersHitsReduceCount:
            this.state.nextweekUppersHitsReduceCount,
        };
      }
    } else if (selectedUppersState == "UppersReduceHowOften") {
      selectedValue = {
        pastSevenDaysUppersCountQuestion:
          "Out of the past 7 days, how many days did you use uppers?",
        pastSevenDaysUppersCount: this.state.pastSevenDaysUppersCount,
        nextSevenDaysUppersCountQuestion:
          "Out of the NEXT 7 days, how many days do you want to use uppers?",
        nextSevenDaysUppersCount: this.state.nextSevenDaysUppersCount,
      };
    } else if (selectedUppersState == "UppersStopCompletely") {
      selectedValue = {
        isTakingBreakUppersQuestion:
          "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from using uppers at this point?",
        isTakingBreakUppers: this.state.isTakingBreakUppers,
        uppersBreakSelectedDateQuestion:
          "Way to commit to taking a break from uppers! When day this week do you want to start?",
        uppersBreakSelectedDate: this.state.uppersBreakSelectedDate,
        uppersCalendarSelectedDateQuestion:
          "Selected Uppers stop completely calendar Date",
        uppersCalendarSelectedDate: this.state.uppersCalendarSelectedDate,
      };
    } else if (selectedUppersState == "Others") {
      selectedValue = {
        uppersOthersTextValueQuestion:
          "What do you want to see happen with your use?",
        uppersOthersTextValue: this.state.uppersOthersTextValue,
      };
    }

    const httpBody = {
      substance_goal_response: {
        category_name: "uppers",
        responses: {
          SelectedReduceState: {
            selectedState: this.state.upperReduceState,
          },
          selected_value: {
            selectedValue: selectedValue,
          },
        },
      },
    };

    const UppersrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postUppersApiCallId = UppersrequestMessage.messageId;
    UppersrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.UppersAPiEndPoints
    );

    UppersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    UppersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    UppersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceAPiMethod
    );

    runEngine.sendMessage(UppersrequestMessage.id, UppersrequestMessage);

    return true;
  };

  moveToOthers = () => {
    const header = {
      "Content-Type": configJSON.UppersApiContentType,
      token: localStorage.getItem("token"),
    };
    const selectedDownersState = this.state.downersReduceState;
    const downersMetricValue = this.state.downersMetricValue;
    let selectedValue;
    if (selectedDownersState == "downersReduceHowMuch") {
      if (downersMetricValue == "ounces") {
        selectedValue = {
          downersMetricValue: this.state.downersMetricValue,
          lastWeekDownersOuncesCountQuestion:
            "Over the last week, how many Ounces did you have on a typical day when you use?",
          lastWeekDownersOuncesCount: this.state.lastWeekDownersOuncesCount,
          nextweekDownersOuncessReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
          nextweekDownersOuncessReduceCount:
            this.state.nextweekDownersOuncessReduceCount,
        };
      } else if (downersMetricValue == "dollars") {
        selectedValue = {
          downersMetricValue: this.state.downersMetricValue,
          lastWeekDownersDollarsTextValueQuestion:
            "Over the last week, how many $$ did you have on a typical day when you use?",
          lastWeekDownersDollarsTextValue:
            this.state.lastWeekDownersDollarsTextValue,
          nextweekDownersDollarsReduceTextValueQuestion:
            "What would you like to reduce that amount to this next week?What is your goal?",
          nextweekDownersDollarsReduceTextValue:
            this.state.nextweekDownersDollarsReduceTextValue,
        };
      } else if (downersMetricValue == "hits") {
        selectedValue = {
          downersMetricValue: this.state.downersMetricValue,
          lastWeekDownersHitsCountQuestion:
            "Over the last week, how many Hits did you have on a typical day when you use?",
          lastWeekDownersHitsCount: this.state.lastWeekDownersHitsCount,
          nextweekDownersHitsReduceCountQuestion:
            "What would you like to reduce that amount to this next week?",
          nextweekDownersHitsReduceCount:
            this.state.nextweekDownersHitsReduceCount,
        };
      }
    } else if (selectedDownersState == "downersReduceHowOften") {
      selectedValue = {
        pastSevenDaysDownersCountQuestion:
          "Out of the past 7 days, how many days did you use downers?",
        pastSevenDaysDownersCount: this.state.pastSevenDaysDownersCount,
        nextSevenDaysDownersCountQuestion:
          "Out of the NEXT 7 days, how many days do you want to use downers?",
        nextSevenDaysDownersCount: this.state.nextSevenDaysDownersCount,
      };
    } else if (selectedDownersState == "downersStopCompletely") {
      selectedValue = {
        isTakingBreakDownersQuestion:
          "This is a good goal. It's also a really big goal. Some people approach this by reducing or using more safely first. Are you sure you want to take a break from using downers at this point?",
        isTakingBreakDowners: this.state.isTakingBreakDowners,
        downersBreakSelectedDateQuestion:
          "Way to commit to taking a break from your downers use! When day this week do you want to start?",
        downersBreakSelectedDate: this.state.downersBreakSelectedDate,
        downersCalendarSelectedDateQuestion:
          "Selected Downers stop completely calendar Date",
        downersCalendarSelectedDate: this.state.downersCalendarSelectedDate,
      };
    } else if (selectedDownersState == "Others") {
      selectedValue = {
        DownersOthersTextValueQuestion:
          "What do you want to see happen with your use?",
        DownersOthersTextValue: this.state.DownersOthersTextValue,
      };
    }

    const httpBody = {
      substance_goal_response: {
        category_name: "downers",
        responses: {
          SelectedReduceState: {
            selectedState: this.state.downersReduceState,
          },
          selected_value: {
            selectedValue: selectedValue,
          },
        },
      },
    };

    const DownersrequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postDownersApiCallId = DownersrequestMessage.messageId;
    DownersrequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DownersAPiEndPoints
    );

    DownersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    DownersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    DownersrequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.SubstanceAPiMethod
    );

    runEngine.sendMessage(DownersrequestMessage.id, DownersrequestMessage);

    return true;
  };



  resetTobaccoOthersTextValue = () => {
    this.setState({
      tobaccoOtherMeasureTypeTextValue: "",
    });
  };

  resetTobaccoreduceHowMuchTextValue = () => {
    this.setState({
      tobaccoreduceHowMuchTextValue: "",
    });
  };

  resetNumberOfCigarettesValue = () => {
    this.setState({
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
    });
  };

  resetUppersDollarAndHits = () => {
    this.setState({
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
    });
  };
  resetUppersOunceAndHits = () => {
    this.setState({
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
    });
  };

  resetUppersDollarAndOunces = () => {
    this.setState({
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
    });
  };

  resetUppersHowOften = () => {
    this.setState({
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
      isTakingBreakUppers: "yes",
      uppersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      uppersCalendarSelectedDate: null,
      uppersOthersTextValue: "",
    });
  };
  resetUppersSafer = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "yes",
      uppersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      uppersCalendarSelectedDate: null,
      uppersOthersTextValue: "",
    });
  };

  resetUppersStopCompletely = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "yes",
      uppersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      uppersCalendarSelectedDate: null,
      uppersOthersTextValue: "",
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
    });
  };

  resetUppersOthers = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      uppersOthersTextValue: "",
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
    });
  };

  resetUppersHowMuch = () => {
    this.setState({
      upperMetricValue: "ounces",
      lastWeekUppersOuncesCount: 0,
      nextweekUppersOuncessReduceCount: 0,
      lastWeekUppersHitsCount: 0,
      nextweekUppersHitsReduceCount: 0,
      lastWeekUppersDollarsTextValue: "",
      nextweekUppersDollarsReduceTextValue: "",
      isTakingBreakUppers: "yes",
      uppersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      uppersCalendarSelectedDate: null,
      pastSevenDaysUppersCount: 0,
      nextSevenDaysUppersCount: 0,
    });
  };

  resetTobaccoHowOften = () => {
this.setState({
  pastSevenDaysCigarettesCount: 0,
  nextSevenDaysCigarettesCount: 0,
  isTakingBreakCigarettes: "true",
  tobaccoBreakSelectedDate:  dayjs().format("YYYY-MM-DD"),
  tobaccoCalendarSelectedDate: null,
  tobaccoOthersTextValue: "",
})
  }
  resetTobaccoSafer = () => {
this.setState({
  tobacoMeasureType: "Others",
  tobaccoOtherMeasureTypeTextValue: "",
  cigarettesReduceType: "Others",
  tobaccoreduceHowMuchTextValue: "",
  lastWeekCigarettesCount: 0,
  nextweekCigarettesReduceCount: 0,
  isTakingBreakCigarettes: "true",
  tobaccoBreakSelectedDate:  dayjs().format("YYYY-MM-DD"),
  tobaccoCalendarSelectedDate: null,
  tobaccoOthersTextValue: "",
})
  }

  resetTobaccoStopCompletely = () => {
    this.setState({
      tobacoMeasureType: "Others",
      tobaccoOtherMeasureTypeTextValue: "",
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      isTakingBreakCigarettes: "true",
      tobaccoBreakSelectedDate:  dayjs().format("YYYY-MM-DD"),
      tobaccoCalendarSelectedDate: null,
      tobaccoOthersTextValue: "",
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
    })
  }

  resetTobaccoOthers = () => {
    this.setState({
      tobacoMeasureType: "Others",
      tobaccoOtherMeasureTypeTextValue: "",
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      tobaccoOthersTextValue: "",
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
    })
  }

  resetTobaccoHowMuch = () => {
    this.setState({
      tobacoMeasureType: "Others",
      tobaccoOtherMeasureTypeTextValue: "",
      cigarettesReduceType: "Others",
      tobaccoreduceHowMuchTextValue: "",
      lastWeekCigarettesCount: 0,
      nextweekCigarettesReduceCount: 0,
      isTakingBreakCigarettes: "true",
      tobaccoBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      tobaccoCalendarSelectedDate: null,
      pastSevenDaysCigarettesCount: 0,
      nextSevenDaysCigarettesCount: 0,
    })
  }


  resetDownersDollarAndHits = () => {
    this.setState({
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
    });
  }

  resetDownersOunceAndHits = () => {
    this.setState({
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
    });
  }

  resetDownersDollarAndOunces = () => {
    this.setState({
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
    });
  }

  resetDownersHowOften = () => {
    this.setState({
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "yes",
      downersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      downersCalendarSelectedDate: null,
      DownersOthersTextValue:'',
    });
  }

  resetDownersSafer = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      isTakingBreakDowners: "yes",
      downersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      downersCalendarSelectedDate: null,
      DownersOthersTextValue:'',
    });
  }

  resetDownersStopCompletely = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "yes",
      downersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      downersCalendarSelectedDate: null,
      DownersOthersTextValue:'',
    });
  }

  resetDownersOthers = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      DownersOthersTextValue:'',
    });
  }

  resetDownersHowMuch = () => {
    this.setState({
      downersMetricValue: "ounces",
      lastWeekDownersOuncesCount: 0,
      nextweekDownersOuncessReduceCount: 0,
      lastWeekDownersHitsCount: 0,
      nextweekDownersHitsReduceCount: 0,
      lastWeekDownersDollarsTextValue:'',
      nextweekDownersDollarsReduceTextValue:'',
      pastSevenDaysDownersCount: 0,
      nextSevenDaysDownersCount: 0,
      isTakingBreakDowners: "yes",
      downersBreakSelectedDate: dayjs().format("YYYY-MM-DD"),
      downersCalendarSelectedDate: null,
    });
  }


  handleInputChange = (event: { target: { name: any; value: any } }) => {
    const { name, value } = event.target;
    this.setState({ [name]: value } as Pick<
      S,
      "lastWeekUppersDollarsTextValue"
    >);
  };



  // Customizable Area End
}
