import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start


export const baseURL = require("../../../framework/src/config.js");




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  currentQuestion: number,
      selectedValue: string,
      questions:any;
      openCongratessafer:boolean
      openQustinary:boolean;
      buttonText:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AssessmentController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  deleteBulkUploadCallId: string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  
 
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   
   currentQuestion: 0,
      selectedValue: "",
      openQustinary:false,
      buttonText:"Next",
       questions :[
        {
          id:1
,          question: "1. I have been unhappy because of my drinking/drug use.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        {
          id:2,
          question: "2. I have neglected my responsibilities due to alcohol/drugs.",
          options: ["Never", "Once or a few times", "Once or twice a week", "Daily or almost daily"]
        },
        // Add more questions here...
      ],
      openCongratessafer:false
       
    
    
     
   
     
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
  

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
   this.handleOpensaferToggle()
  }
 
  introductionPages(){
    this.props.navigation.navigate("Categoriessubcategories")

  }
  hamburgerMenuass(){
    this.props.navigation.navigate("HamburgerMenu")
  }
  goToassessment(){
    this.setState({ openCongratessafer: false });
    this.props.navigation.navigate("Assessment")
   
  }
  goToDashboard(){
   
    this.props.navigation.navigate("DashboardInformation")
  }
  handleNextass = () => {
    if (this.state.currentQuestion < this.state.questions.length - 1) {
      this.setState((prevState) => ({
        currentQuestion: prevState.currentQuestion + 1,
        selectedValue: ""
      }));
    }
  };

  handleRadioChange = (event:any) => {
    this.setState({ selectedValue: event.target.value });
  };
  
  handleOpensaferToggle = () => {
    this.setState({ openCongratessafer: true });
  };
  handleOpensqustinary = () => {
    this.setState({ openQustinary: true ,buttonText: 'Complete'});
  };
  handleClosesqustinary  = () => {
    this.setState({ openQustinary: false });
  };
  handleClosesaferToggle  = () => {
    this.setState({ openCongratessafer: false });
  };
 
 
 
  // Customizable Area End
}