import React from 'react';
import {
    Grid,
    Button
  } from "@mui/material";

import LandingPageController, {
    Props,
} from './LandingPageController';
import {landingscreen } from './assets'

export const configJSON = require("./config.js");


export default class LandingPage extends LandingPageController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Grid
                item
                style={{
                    height: '100%',
                    background: `url(${landingscreen.default})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    position: "relative",
                    padding: "50px",
                }}
            >
                <Button data-test-id="intro" onClick={() => this.handleIntro()} style={{...webStyle.container,fontFamily:'Chromatica-Bold', textTransform:'capitalize',}}>
                    Enter
                </Button>
            </Grid>
        )
    }
}

const webStyle = {
    container: {
        cursor:'pointer',
        top:'-100px',
        fontWeight: 700, 
        borderRadius: '16px', 
        color: 'white', 
        width: '150px',
        height: '56px', 
        fontSize:'23px',
        background: 'linear-gradient(91.48deg, #BACA08 0%, #00A7D1 100%)'      
    },
}

