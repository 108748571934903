import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import dayjs from 'dayjs';
// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkedItems:any,
  checkedIte:any,
  gridItems: any, 
  scrollOffset:number,
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet:any,
  openCalender: string,
  openTime:boolean
  selectedDate:string,
  options: any,
  selectedValue:any,
  // Customizable Area End
}

interface SS {
  id: any;
}
interface NumberIndexedObject {
  [key: string]: string;
}

export default class OtherGoalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
    ];

    this.state = {
      scrollOffset: 0,
      gridItems: [], 
      checkedItems:{},
      checkedIte:{},
      options: [], 
      newDateData: "",
      timeSet:'',
      currDate: {},
      openCalender: "",
      openTime:false,
      selectedDate:"",
      selectedValue:'',
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.generateOptions();
    
      this.fetchGridItems();
    
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    // Customizable Area End
  }

  formatDate(date:any) {
    const dateMoment = moment(date); 
    const formattedDate = dateMoment.format('ddd, D MMMM'); 
    const isToday = dateMoment.isSame(moment(), 'day'); 
    return isToday ? formattedDate.replace(',', '') : formattedDate;
}

  generateOptions() {
    const today = new Date(); 
    const options = [
        { value: 'today', label: `Today, ${this.formatDate(today)}` },
    ];
    for (let i = 1; i <= 4; i++) {
        const nextDate = new Date(today); 
        nextDate.setDate(today.getDate() + i);
        options.push({
            value: `nextDay${i}`,
            label: `${this.formatDate(nextDate)}`,
        });
    }
    options.push({ value: 'other', label: 'Other Day' });
    this.setState({ options });
}
  handleChange = (event:any) => {
    this.setState({ selectedValue: event.target.value });
};
hamburgerPage(){
  this.props.navigation.navigate("HamburgerMenu")
}
goalsPage(){
  this.props.navigation.navigate("Goals")
}
handleScrollRight = () => {
  this.setState((prevState) => ({
    scrollOffset: Math.min(prevState.scrollOffset + 5, this.state.gridItems.length - 1),
  }))}

fetchGridItems = () => {
  const itemsShowing = [
      { id: 1, label: 'Alcohal' },
      { id: 2, label: 'Cannabis' },
      { id: 7, label: 'F' },
      { id: 3, label: 'Nicotinne' },
      { id: 4, label: 'C' },
      { id: 5, label: 'D' },
      { id: 6, label: 'E' },
      { id: 8, label: 'G' },
      { id: 10, label: 'I' },
      { id: 11, label: 'J' },
      { id: 12, label: 'K' },
      { id: 9, label: 'H' },
     
  ];
  
  this.setState({ 
      gridItems: itemsShowing 
  });
}
handleScrollLeft = () => {
this.setState((prevAns) => ({
  scrollOffset: Math.max(prevAns.scrollOffset - 5, 0), 
}));
};
openPopUp(key: number) {
  this.setState({
    openCalender: key.toString()
  });
}
openTimer() {
  this.setState({
    openTime: true
  });
}

closePopUp() {
  this.setState({
    openCalender: ""
  });
}
handleCheckbox = (id:any) => {
  this.setState((prevState) => ({
      checkedIte: {
          ...prevState.checkedIte,
          [id]: !prevState.checkedIte[id],
      },
  }));
}
  handleCheckboxChange = (id:any) => {
    this.setState((prevState) => ({
        checkedItems: {
            ...prevState.checkedItems,
            [id]: !prevState.checkedItems[id],
        },
    }));
  }
 
  convertToDayjsDate(dateString: string) {
    const [day, month, year] = dateString?.split('/')?.map(Number);
    return dayjs(new Date(year, month - 1, day)); 
  }
  handleDate(e: any) {
    let date = e["$d"];
    let finalDate = date?.getDate() + '/' + (date?.getMonth() + 1) + '/' + date?.getFullYear();
    this.setState({
      newDateData: finalDate
    });
  }
  handleTime(e:any){
    this.setState({
      timeSet: e,
      openTime: false
  });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  handlePopupTime(keyAnswer: any) {
    if (keyAnswer) {return true;} else {
      return false;
    }
  }
  confirmCal() {
    let data = { ...this.state.currDate };
    data[this.state.openCalender] = this.state.newDateData;
    this.setState({
      currDate: { ...data },
      openCalender: "",
      selectedDate: this.state.newDateData
    }, 
    );
  }
 handleColor(varibale:any){
 return varibale ? '#00A7D1' : '#E3E3E3'
 }
   getItemBorder  (itemId: string){
    return this.state.checkedItems[itemId] ? '2px solid transparent' : '2px solid #E3E3E3';
  };
  getItemColor  (itemId: string){
    return this.state.checkedItems[itemId]
    ? 'linear-gradient(#EFF8FB, #EFF8FB) padding-box, linear-gradient(#EBFF00, #00A7D1) border-box'
    : 'white'
  };
  handleTimeSet(){
   return this.state.timeSet ? moment(this.state.timeSet.$d).format('hh:mm A') : '';
  }
  // Customizable Area End
}
