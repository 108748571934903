import React from "react";
// Customizable Area Start
import { Box,Grid ,Tabs,Tab,IconButton,CardContent,Card, Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  Typography,
  FormControl,
  FormLabel} from "@mui/material";

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { styled } from "@mui/material/styles"; 
import { arrow_arrow, five_five, menu_menu_assessment, photo_photo, sun_sunset } from "./assets";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const StyledButtonqustiona = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  cursor:"pointer",
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});
const StyledButton = styled(Button)({
  background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
color: "#ffffff",
  padding: "16px",
  borderRadius: "16px",
  fontWeight: "bold",
  textTransform: "none",
  width:'348px',
  "&:hover": {
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)"
  }
});

// Customizable Area End
import AssessmentController, {
  Props,
  
} from "./AssessmentController.web"




export default class Assessment extends AssessmentController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
 
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    
   
    return (
      //Merge Engine DefaultContainer
  <>
   
   <Grid
        item
        style={{
          overflowY:'scroll',
        
          backgroundColor: "#F0F0F0",
          height:'100%',
          width:'100%',
        }}
      >
        <Grid
          item
          style={{
            height: '88px',
          
            borderRadius: '16px',
            width: '98.5%',
            backgroundColor: '#0F4257',
            margin: '10px',
          }}
        >
          <Box
            sx={{
              justifyContent: "space-between",
              height: "100%",
              alignItems: "center",
              width: "100%",
              display: "flex",
             
            
            }}
          >
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
              
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  color: "#ffffff",
                  fontWeight: 300,
                  fontFamily: "Josefin Sans",
                  fontSize: "40px",
               
                }}
              >
                <span
                  style={{
                    marginLeft: "40px",
                    fontFamily: "Josefin Sans",
                    fontWeight: 700,
                  
                    fontSize: "40px",
                    marginBottom: "4px",
                    color: "#EBFF00",
                    lineHeight: "40px",
                  }}
                >
                  Well
                </span>
                Spring
              </Box>
              <Typography
                sx={{
                  color: "#FFFFFF",
                  fontFamily: "Chromatica-Bold",
                  fontWeight: 700,
                  mt: "3px",
                  fontSize: "20px",
                  lineHeight: "20px",
                
               
                }}
              >
                Hey, Tom Chandler
              </Typography>
            </Box>
            <Box
              onClick={() => { this.hamburgerMenuass() }}
              component="img"
              src={menu_menu_assessment.default}
              alt="menuIcon"
              sx={{
                marginRight: "70px",
                cursor: "pointer",
             
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          style={{
            width: '98.5%',
            margin: '10px',
            marginTop: '10px',
            marginBottom: '10px',
            borderRadius: '15px',
           
            backgroundColor: 'white',
            position:"relative",zIndex:1 
          }}
        >
          <Box onClick={() => this.introductionPages()} sx={{ paddingTop: '30px', cursor: 'pointer' }} data-test-id="back-btn">
            <Box
              component="img"
              src={arrow_arrow.default}
              alt="Back Arrow"
              sx={{ position: "absolute", paddingTop: '6px', left: 60 }}
            />
          
            <Typography
              sx={{
                color:'#252525',
                fontFamily: 'Chromatica-Bold',
                position: "absolute",
                left: 82,
                cursor: "pointer",
              
              }}
            >
              Back
            </Typography>
          </Box>

          <Box sx={{ position: "relative",  height:'auto',
                   top: 20, marginLeft: '50px',left:'13px',zIndex:1 }}>
            <Box
              component="img"
              src={five_five.default}
              alt="Logo Top Left"
              sx={{ width: '120px', height: '180px' }}
            />
            <Typography
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
              
                lineHeight:"32px",
                fontSize: '32px',
                color: '#00A7D1',
                marginTop: '109px',
                fontFamily: 'Josefin Sans',
                fontWeight: 600,
              }}
            >
            Harm Reduction Questionnaire
            </Typography>
            <Typography sx={{
              fontFamily: 'Chromatica-Regular',
              fontSize: '16px',
              color: '#252525',
              fontWeight:400,
              marginBottom: '20px'
            }}
            >
            During the past 30 days, about how often has this happened to you?
            </Typography>
          
           
          </Box>
          <Box sx={{ padding:"3rem" }}>
        <FormControl component="fieldset">
          <FormLabel component="legend" sx={{position:'relative',zIndex:2}}>
            <Typography variant="h6" gutterBottom sx={
                    {
                      fontFamily: 'Chromatica-Regular',
                      fontSize: '16px',
                      fontWeight: 400,
                      lineHeight: '24px',
                      color: "#252525"

              }
            }>
              {this.state.questions[this.state.currentQuestion]?.question}
            </Typography>
          </FormLabel>
          <RadioGroup value={this.state.selectedValue} onChange={this.handleRadioChange} sx={{position:'relative',zIndex:2}}>
            {this.state.questions[this.state.currentQuestion]?.options.map((option:any, index:any) => (
            <div
            style={{
              border: this.state.selectedValue === option ? "1px solid #00A7D1" : "1px solid #E3E3E3",
              borderRadius: "32px",
              margin: "10px 0",
              padding: "8px 12px",
              width: option === "Never" ? "161px" : "224px", // Conditionally set width for "Never"
              display: "block",
            }}
          >
            <FormControlLabel
              key={index}
              value={option}
              control={<Radio />}
              label={option} // Use label as a string
              sx={{ '& .MuiFormControlLabel-label': { 
                fontFamily: 'Chromatica-Regular',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '24px',
                color:"#252525" } }}
            />
          </div>
          
            ))}
          </RadioGroup>
        </FormControl>

              <Box mt={3} textAlign="left" sx={{ position: 'relative', zIndex: 2 }}>
                <StyledButton
                  disabled={!this.state.selectedValue} // Disable button if no option is selected
                  onClick={this.handleOpensqustinary}
                >
                  <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center', gap: "0.3rem" }}>
                    <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>{this.state.buttonText}</span>

                    <span>
                      <KeyboardArrowRightIcon style={{ color: 'white', width: '24px', height: '24px' }} />
                    </span>

                  </div>
                </StyledButton>
        </Box>
              
      <Box
              sx={{
                position: 'absolute',
                bottom: '-30%', // Stick it to the bottom
                left: '0',
                width: '100%',
                background:"white"
              }}
            >
              <img
                src={sun_sunset.default}
                alt="Sunset"
                style={{
                  width: "100%",
                  height: 'auto',
                  position: 'relative',
                borderRadius:"16px"// Adjust this value to move the image upwards if needed
                }}
              />
            </Box>

         </Box>
        </Grid>
       
      </Grid>

      <Dialog onClose={this.handleClosesaferToggle} open={this.state.openCongratessafer}
                PaperProps={{
                    style: { borderRadius: 24,maxWidth:692,
                      overflowX:"hidden"

                     }, // Add border radius here
                }}
            >
                <DialogTitle>
                  <Box>
                  <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'left',
                            alignItems: 'left',
                             // To center text as well
                            width: '100%',
                            padding:'2rem'
                          
                        }}
                    >
                        

                        <Typography sx={webStyles.Congratulationsstyleinasfers}>
                        Harm Reduction Questionnaire
                        </Typography>
                        
                    </Box>
                    <Box sx={{paddingLeft:'2rem'}}>
                      <Typography sx={webStyles.subtancesStyle}>
                      WellSpring can help yu reduce your substance-related harm. Please answer <br/>the following questions so we can figure out your baseline and help you<br/> track your progress over time. It’s sometimes hard to look at substance-<br/>related harm, but that’s why you’re here. You got this.
                      </Typography>
                    </Box>
                  </Box>
    
                
                </DialogTitle>
                <Box
    sx={{
      width: '100%',
      height: '389px', // Adjust height based on your requirement
      backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${photo_photo.default})`,
      backgroundSize: 'cover', // Ensures the background covers the entire Box
      backgroundPosition: 'center', // Centers the image
      backgroundRepeat: 'no-repeat', // Prevents the image from repeating
      borderRadius: '0 0 24px 24px', // Matches the dialog's radius
      display: 'flex',
      flexDirection: 'column', // Set items in a column
      justifyContent: 'center', // Center content vertically
      alignItems: 'center', // Center content horizontally
      color: '#fff', // White text for contrast
    }}
  >
    <Typography
      sx={{
        fontFamily: 'Chromatica-Regular',
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '24px',
        color: "#ffffff",
        textAlign: 'center',
        marginBottom: '16px', // Adds space between text and button
      }}
    >
      This should take you about 5 minutes
    </Typography>

    <Box sx={{ textAlign: 'center' }}>
      <StyledButton // Disable button if no option is selected
      onClick={() => this.goToassessment()}
      >
        <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
          Start Questionnaire
        </span>
      </StyledButton>
    </Box>
  </Box>

            </Dialog>



            {/* rinku */}
            <Dialog onClose={this.handleClosesqustinary} open={this.state.openQustinary}
                PaperProps={{
                    style: { borderRadius: 24,maxWidth:622,
                      overflowX:"hidden",background: "#0F4257",padding:'3rem'


                     }, // Add border radius here
                }}
            >
                <DialogTitle>
                  <Box>
                  <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                             // To center text as well
                            width: '100%',
                          
                        }}
                    >
                        

                        <Typography sx={webStyles.Questionnairestyleinasfers}>
                        Questionnaire Completed
                        </Typography>
                        
                    </Box>
                    <Box sx={{marginTop:"1rem"}}>
                      <Typography sx={webStyles.subtancesStyle}>
                    <Typography style={{...webStyles.QustionStyleText}}>  Thanks so much for sharing where you are at.</Typography>
                    <Typography style={{...webStyles.QustionStyleText}}>  Head to your dashboard to see how this</Typography>

                  <Typography style={{ ...webStyles.QustionStyleText }}> compares to your previous scores.</Typography>                      </Typography>
              </Box>
            </Box>
            <div style={{
              display: "flex", justifyContent: 'center', alignItems: "center",
              marginTop: "2rem"
            }}>
              <StyledButtonqustiona
                disabled={!this.state.selectedValue}  onClick={() => this.goToDashboard()}>
                 
                <span style={{ color: '#ffffff', ...webStyles.Nextstylemove }}>
                  Go to Dashboard
                </span>
              </StyledButtonqustiona>
            </div>
    
                
                </DialogTitle>
    

            </Dialog>


     

          
          

  </>
      
    
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  subtancesStyle:{
    fontFamily: 'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    color:"#252525"
  },
  closeStyle:{
    position: 'absolute',
    right: 8,
    top: 8,
},
  Congratulationsstyleinasfers:{
    fontFamily: 'Josefin Sans',
    fontSize: '32px',
    fontWeight: 600,
    lineHeight: '32px',
    color:'#00A7D1',
},
Questionnairestyleinasfers:{
  fontFamily: 'Josefin Sans',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:'#EBFF00',
},
QustionStyleText:{
  fontFamily: 'Chromatica-Regular',
  fontSize: '20px',
  fontWeight: 500,
  lineHeight: '24px',
  color:'white',display:"flex",justifyContent:'center',alignItems:"center"
},
  Nextstylemove:{
    fontFamily: 'Chromatica-bold', 
    fontSize: '18px', 
    fontWeight: 700, 
    lineHeight: '24px',
   }
};

// Customizable Area End