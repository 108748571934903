import React from "react";
// Customizable Area Start

import { styled } from "@mui/material/styles"; 
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepConnector from "@mui/material/StepConnector";
import {  award_3, award_a4, award_a5, Menu_M,ThumbBoy_b, wave_wave } from "./assets";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box,Grid,IconButton,Button,Paper} from "@mui/material";
import "../../../blocks/dashboard/assets/style.css"
import CloseIcon from '@mui/icons-material/Close';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import "../../../blocks/dashboard/assets/style.css"
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';



const data = [
  { month: 'Jan', harm: 37 },
  { month: 'Feb', harm: 35 },
  { month: 'Mar', harm: 32 },
  { month: 'Apr', harm: 31 },
  { month: 'May', harm: 30 },
  { month: 'Jun', harm: 28 },
  { month: 'Jul', harm: 24 },
  { month: 'Aug', harm: 22 },
  { month: 'Sep', harm: 20 },
  { month: 'Oct', harm: 19 },
  { month: 'Nov', harm: 18 },
  { month: 'Dec', harm: 22 },
];
const StepIconste = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
  backgroundColor: "#ccc",
  zIndex: 1,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage: "linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  left: 'calc(-85% + 2px)',
  right: 'calc(15% + 2px)',
  display: 'block', // Display block as per your CSS
  borderColor: '#B7D340', // New border color
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  borderRadius:"36px",

  alternativeLabel: {
    top: 22,
    left: "-170px",
    right: "19px",
   
    
  },
  active: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  
  completed: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
}));
const ColorlibConnectorSafer = styled(StepConnector)(({ theme }) => ({
  
  display: 'block', // Display block as per your CSS
  borderColor: 'white',
  left: 'calc(-85% + 2px)',
  right: 'calc(15% + 2px)', // New border color
  borderTopStyle: 'solid', // Solid border top style
  borderTopWidth: '4px',
  flex: '1 1 auto',
  position: 'absolute',
  top: '37px',
  
  borderRadius:"36px",

  alternativeLabel: {
    left: "-170px",
    top: 22,
    right: "19px",
   
    
  },
  line: {
    display: 'block', 
    borderColor: 'red', 
    borderTopStyle: 'solid',
    borderTopWidth: '20px',
  },
  completed: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  active: {
    "& .MuiStepConnector-line": {
      backgroundColor: "linear-gradient(90deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  
  

}));


const ColorlibStepIcon = styled("div")<{ active?: boolean; completed?: boolean }>(({ theme, active, completed }) => ({
  backgroundColor: "#F3A622",
  zIndex: 1,
  color: "#fff",
  width:80,
  height: 80,
  display: "flex",
  
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(active && {
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(completed && {
    backgroundImage: "linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  }),
}));

const WhiteBackgroundContainer = styled('div')({
  background: 'white', 
  padding: '2rem', 
 height:"auto",
  marginLeft:"1rem",
  marginRight:"1rem",
  borderRadius:"16px" ,

});



 
// Customizable Area End
import DashboardControllerInformation, {
  Props,
  configJSON
} from "./DashboardControllerInformation.web";



export default class DashboardInformation extends DashboardControllerInformation {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    
    // Customizable Area End
  }
  // Customizable Area Start
  steps: string[] = ["Find housing", "Get more rest", "Reduce drinks"];


   icons = [
     <img src={award_3.default} alt="awrad_award"/>,
     <img src={award_a4.default} alt="awrad_award"/>,
     <img src={award_a5.default} alt="awrad_award"/>,
   ];
   headerMiddle = ()=>{
    return (
      <>
        <Grid
          item
          style={{
           ...webStyles.wholeGridStyle
          }}
        >
          <Box
                        sx={{                  
                          ...webStyles.wholedivStyle}}>
                        <Box
                            sx={{
                              ...webStyles.gapM
                            }}
                        >
                            <Box
                                sx={{
                                  ...webStyles.parentWell
                                }} >
                                <span
                                    style={{
                                      ...webStyles.well__wellStyle
                                    }}
                                >
                                    Well
                                </span>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                  ...webStyles.homeStylemenu
                                   
                                }}
                            >
                                Hey, Tom Chandler</Typography>
                        </Box>
                        <Box
                            src={Menu_M.default}
                            onClick={() => { this.hamburgerMenux() }}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "70px",
                            }}
                            alt="icon"      
                        />
                    </Box>
        </Grid>
      </>
    )
   }
 nextStepperFunc = () =>{
  return (
    <>
      <Grid item md={6} lg={6} sm={12} >
               <Paper style={{ width: "100%", height: "300px", background: "#DFEDF0", borderRadius: "16px" }}>
                <Box style={{ padding: "2rem" }} >
                  <Typography sx={webStyles.goalStyles}>Safer-Use Strategies</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeSteps}
                  connector={<ColorlibConnectorSafer />}
                  sx={webStyles.stepperStyle}
                >
                  {this.steps.map((label: any, index: any) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <StepIconste {...props} completed={this.state.activeSteps > index}>
                            {this.icons[index]} 
                          </StepIconste>
                        )}
                      >
                        <span style={webStyles.labelstylesteppers}>{label}</span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>

                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <div style={{ padding: '1.3rem', marginTop: '0.2rem' }}>
                        <Box style={{ width: "195px", background: "#CFE472", padding: "0.4rem", borderRadius: "40px", cursor: "pointer" }}>
                          <Button
                            data-test-id="handlemoreawrad"
                            onClick={this.handleNexts}
                          >
                            <Typography sx={webStyles.threebythreeStyles}>1/3 Task Completed!</Typography>
                          </Button>
                        </Box>
                      </div>
                      <div >
                        <Typography sx={webStyles.viewStyle} onClick={() => { this.goToSaferseStrategies() }}>{configJSON.viewMore}</Typography>

                      </div>
                    </div>

                    <div>
                    
                    </div>
                  </div>

                </div>
              </Paper>
           
            </Grid>
    </>
  )
 }
    CustomTooltip = (props:any) => {
    const { active, payload, label } = props; 
  
    if (active && payload && payload.length) {
      return (
        <div style={{
          backgroundColor: '#fff',
          border: '1px solid #ccc',
         
          padding: '10px',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          ...webStyles.tooltipStyle, 
        }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{label}</p>
          <p style={{ margin: 0 }}>{`Harm: ${payload[0].value}`}</p>
        </div>
      );
    }
    return null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    
    const CustomDot = (props:any) => {
      const { cx, cy } = props; // Extract properties
      return (
        <circle
          cx={cx}
          cy={cy}
          r={4} // Larger radius for active dots
          stroke={ '#00A7D1'} // Dark red for active, line color for inactive
          fill={ '#00A7D1'} // Dark red fill for active, white for inactive
        />
      );
    };
    const CustomLegend = ({ payload }:any) => {
      return (
      
        <>
      <div style={{textAlign:"center",marginTop:"1rem"}}> 
      {payload.map((entry:any, index:any) => (
            <li 
              key={`item-${index}`} 
              style={{ margin: '0 10px',   fontFamily: 'Chromatica-Regular',
                fontSize: '16px',
                fontWeight: 700,
                lineHeight: '20px',color:'#00A7D1'}} // Change the font size here
            >
            Months{/* Only display the name without the symbol */}
            </li>
          ))}
      </div>
          </>
      
      );
    };
    return (
      //Merge Engine DefaultContainer
  <>
   <Grid
        item
        style={{
          padding: '10px',
          background: '#f0f0f0',
        }}
      >
      {this.headerMiddle()}
        </Grid>
      
        <WhiteBackgroundContainer>
        <img src={wave_wave.default} style={{position:"absolute",top:"109px",left:0}}/>
          <Box style={{ marginBottom: "2rem" }}>
            <Typography style={{...webStyles.YourStyles,position: "relative", zIndex: 2 }}>Your Dashboard</Typography>
          </Box>
          <Grid container spacing={4}>
            <Grid item md={6} lg={6} sm={12} style={{position: "relative", zIndex: 2 }}>
              <Paper style={{ width: "100%", height: "300px", background: "#F1F7D0", borderRadius: "16px" }}>
                <Box style={{ padding: "2rem" }} >
                  <Typography sx={webStyles.goalStyles}>Goals</Typography>
                </Box>
                <Stepper
                  alternativeLabel
                  activeStep={this.state.activeStep}
                  connector={<ColorlibConnector />}
                  sx={webStyles.stepperStyle}
                >
                  {this.steps.map((label: any, index: any) => (
                    <Step key={label}>
                      <StepLabel
                        StepIconComponent={(props) => (
                          <ColorlibStepIcon {...props} completed={this.state.activeStep > index}>
                            {this.icons[index]} 
                          </ColorlibStepIcon>
                        )}
                      >
                        <span style={webStyles.labelstylesteppers}>{label}</span>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                <div>

                  <div>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center",gap:"0"}}>
                      <div style={{ padding: '1.3rem', marginTop: '0.2rem' }}>
                        <Box style={{ width: "195px", background: "#CFE472", padding: "0.4rem", borderRadius: "40px", cursor: "pointer" }}>
                          <Button
                            data-test-id="handlemoreawrad"
                            onClick={this.handleNext}
                          >
                            <Typography sx={webStyles.threebythreeStyles}>3/3 Task Completed!</Typography>
                          </Button>
                        </Box>
                      </div>
                      <Box>
                        <Typography sx={{...webStyles.viewStyle}}  onClick={() => { this.goTogoalsScreen() }}>{configJSON.viewMore}</Typography>

                      </Box>
                    </div>
                    
                  </div>

                </div>
              </Paper>
            </Grid>
          {this.nextStepperFunc()}
          </Grid>
          <Grid container style={{ marginTop: "2rem" }} spacing={2}>
            <Grid item md={12} lg={12}>
              <Paper style={{ background: "#5C61DB", borderRadius: "16px", height: "auto" }}>
                <Box sx={webStyles.flexpropertys}>

                  <Box sx={{ marginLeft: "1rem", position: "relative" }}>
                    <Box sx={{ padding: "1rem" }}>
                      <Typography sx={webStyles.HeyTomStyles}>Hey, Tom!</Typography>
                    </Box>
                    <Typography style={webStyles.testStyleforHeyToms}>
                      No one's path in life is perfect. Great job coming back to keep working on things! <br />
                      This is a huge step towards changing your substance-related harm.
                    </Typography>
                    <span style={{ color: "#5C61DB" }}>none</span>

                    {/* Image Box */}
                    <Box sx={{ position: "relative", zIndex: 1, textAlign: "right" }}>
                      <img
                        src={ThumbBoy_b.default}
                        alt="Thumbnail"
                        style={{
                          width: "239px",
                          height: "160px",
                          position: "absolute",
                          top: 0,
                          transform: "translate(100%, -100%)",
                          right: "-44%",
                        }} 
                      />
                    </Box>
                  </Box>
                </Box>

              </Paper>
            </Grid>

          </Grid>
          <Grid container style={{ marginTop: "2rem" }} spacing={2}>
            <Grid item md={12} lg={12}>
              <Paper style={{borderRadius: "16px", height: "auto" }}>
             <Box style={webStyles.leftAndrightbox}>
             <Box  sx={{ marginLeft: "1rem", marginTop:"0.6rem" }} onClick={this.handleOpenqustionary}>
                <Typography sx={webStyles.relatedHarmstyle}>Related Harm Reduction</Typography>
              </Box>
              <Box style={{paddingRight:"2rem", marginLeft: "1rem", marginTop:"0.6rem" }}>
                  <FormControl sx={{ m: 1, minWidth: 120 }}>
                    <Select
                    data-test-id="monthHandle"
                      value={this.state.month}
                      onChange={this.handleChangemonth}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      sx={{ width: '187px', height: '40px',borderRadius:"16px",border: "1px solid #E3E3E3" }}
                    >
                      <MenuItem value="">
                        month
                      </MenuItem>
                      <MenuItem value="January">January</MenuItem>
                        <MenuItem value="February">February</MenuItem>
                        <MenuItem value="March">March</MenuItem>
                        <MenuItem value="April">April</MenuItem>
                        <MenuItem value="May">May</MenuItem>
                        <MenuItem value="June">June</MenuItem>
                        <MenuItem value="July">July</MenuItem>
                        <MenuItem value="August">August</MenuItem>
                        <MenuItem value="September">September</MenuItem>
                        <MenuItem value="October">October</MenuItem>
                        <MenuItem value="November">November</MenuItem>
                        <MenuItem value="December">December</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                </Box>
                <div style={{ display: "flex", alignItems: "center" }}>
                <div className="vertical-text-container">
                <div className="vertical-text">
    <span style={{...webStyles.reductionStyle}}>Substance Related Harm</span>
  </div>
</div>
  <ResponsiveContainer width="100%" height={400} style={{ marginTop: "1rem" }}>
    <LineChart
      width={500}
      height={300}
      data={data}
      margin={{
        top: 5, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="month" style={webStyles.XandYbothStyle} />
      <YAxis style={webStyles.XandYbothStyle} />
      <Tooltip content={<this.CustomTooltip />} />
      <Legend content={<CustomLegend />} /> 
      <Line type="monotone" dataKey="harm" stroke="#00A7D1" activeDot={{ r: 8 }}  dot={<CustomDot />} 
       name="Months"

      />
    </LineChart>
  </ResponsiveContainer>
</div>

              </Paper>
            </Grid>

          </Grid>
        </WhiteBackgroundContainer>
        <Dialog onClose={this.handleClosestartqustionary} open={this.state.qustionStart}
          PaperProps={{
            style: { borderRadius: 24 },
            background: "#0F4257"// Add border radius here
          }}
        >
          <DialogTitle sx={{ background: "#0F4257" }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center', // To center text as well
                width: '100%',
              }}
            >

              <div style={{ display: "flex", justifyContent: "flex-start", alignItems: 'center' }}>
                <Typography sx={{
                  ...webStyles.SprinStylethird
                }}>Well</Typography>
                <Typography style={{
                  ...webStyles.wellStylethird
                }}>Spring</Typography>
              </div>
              <Typography sx={{ ...webStyles.QuestionnaireStyle, marginTop: "1.1rem" }}>
                Go to Harm Reduction Questionnaire to track your <br />
                progress every two weeks.
              </Typography>

            </Box>
            <Box
              style={{
                ...webStyles.flexBoxthird
              }}
            >
              <Box>
                <button
                  style={{ ...webStyles.harmChartTextStyle, cursor: "pointer" }}
                // Hide section on button click
                >
                  <Typography sx={{ ...webStyles.achievegoalstext, color: "white" }}>
                    Start Questionnaire
                  </Typography>
                </button>
              </Box>
            </Box>
            <IconButton
              aria-label="close"
              onClick={this.handleClosestartqustionary}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
        </Dialog>

        
  </>
      
    
      //Merge Engine End DefaultContainer
    );
    
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  wholedivStyle:{
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    display: "flex",
    height: "100%"
  },
  wholeGridStyle:{
    height: '88px',
    backgroundColor: '#0F4257',
    borderRadius: '16px',
    width: '98.5%',
    margin: '10px',
  },
  well__wellStyle:{
    marginLeft: "40px",
    fontFamily: "Josefin Sans",
    lineHeight: "40px",
    fontWeight: 700,
    marginBottom: "4px",
    color: "#EBFF00",
    fontSize: "40px",
   },
   homeStylemenu:{
    mt: "3px",
    fontSize: "20px",
    lineHeight: "20px",
    color: "#FFFFFF",
    fontFamily: "Chromatica-Bold",
    fontWeight: 700,
  },
  gapM:{
    gap: "20px",
    display: "flex",
    alignItems: "center",
  },
  parentWell:{
    fontFamily: "Josefin Sans",
    color: "#ffffff",
    fontSize: "40px",
    fontWeight: 300,
  },

  //above my style for header

  achievegoalstext:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
    color:"white",
    textAlign:'center'
    
 },

  harmChartTextStyle:{
    padding: "0.8rem",     
    width: "348px",          
    borderRadius: "8px",     
    border: "none",       
    color:"white",    
    background: "linear-gradient(91.26deg, #BACA08 1.42%, #00A7D1 99.81%)",
},
  QuestionnaireStyle:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '24px',
    color:"white"
  },
  reductionStyle:{
    fontFamily: 'Chromatica-Regular',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  viewStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    paddingRight: "2rem",
    
  },
  stepperStyle:{
    marginLeft: "-0.5rem", marginRight: "-0.5rem"
  },
  tooltipStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#00A7D1",
    borderRadius:"33px"
  },
 
  XandYbothStyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"
  },
  leftAndrightbox:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
   
  },

  relatedHarmstyle:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    color:"#000000",
    padding:'1rem'
  },
  flexpropertys:{
display:"flex",
justifyContent:"space-between",
alignItems:"center"
  },
  testStyleforHeyToms:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    color:"#ffffff",
    marginLeft:"1.3rem"
  },
  HeyTomStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: "24px",
    fontWeight: 700,
    lineHeight: "32px",
    color:"#EBFF00"
  },
  threebythreeStyles:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#000000",
    textTransform: 'lowercase',
    
  },
  labelstylesteppers:{
   fontFamily:'Chromatica-Bold' ,
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    color:"#252525"

  },
  goalStyles:{
    fontFamily:'Chromatica-Bold' ,
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    
  },
YourStyles:{
  fontFamily: 'Josefin Sans',
  fontSize: '32px',
  fontWeight: 600,
  lineHeight: '32px',
  color:"#00A7D1",

},

SprinStylethird:{
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"#EBFF00",
  marginTop:"1.2rem",
},
flexBoxthird:{
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "0.8rem",
  marginTop:"1rem",
  marginBottom:"2rem"
},
wellStylethird:{
  fontFamily: "Josefin Sans",
  fontWeight: 700,
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.04em',
  color:"white",
  marginTop:"1.2rem",
},
};



// Customizable Area End