export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const topBannerImg = require("../assets/topBannerImg.png");
export const bottomBannerImg = require("../assets/bottomBannerImg.png");
export const closeBtn = require("../assets/close.png");
export const rightImage = require("../assets/rightImage.png");
export const notation = require("../assets/notation.png");
export const arrow = require("../assets/arrow.png");
export const HidePassword = require("../assets/HidePassword.png");
