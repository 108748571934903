import React from 'react';
// Customizable Area Start

import {
  Grid , Box,Typography
} from "@mui/material";
import HamburgerMenuController, {colors, configJSON} from "./HamburgerMenuController.web";
import { closeBtn } from './assets';


// Customizable Area End


export default class HamburgerMenu extends HamburgerMenuController {

 
  // Customizable Area Start
 
  // Customizable Area End

  render() {

    const {
      logOutBlockInnerStyle,
      logOutBlockContainorStyle,
      logOutBlock,
    } = this.props;
    
    return (
      // Customizable Area Start
      
      <Grid
        item
        style={{
          height: '100%',
          backgroundColor: '#0F4257',
          padding: "50px",
        }}
      >
        
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            left: "20px",
            color: "#ffffff",
            fontFamily: 'Josefin Sans',
          }}
        >
          <Box component="span" sx={{ fontSize: "40px", fontWeight: "bold", color: "#EBFF00", marginLeft: "10px" }}>
            Well
          </Box>
          <Box component="span" sx={{ fontSize: "40px", fontWeight: "normal", marginLeft: "5px" }}>
            Spring
          </Box>

          <Box
            sx={{
              position: "fixed",
              top: "36px",
              left: "254px",
              fontSize: "20px",
              color: "white",
              fontFamily: 'Chromatica-Bold',
            }}
          >
            Hey, Tom Chandler
          </Box>
          
                        <img
                          src={closeBtn.default}
                          alt="Close"
                          style={{
                            position: "fixed",
                            top: "30px",
                            right: "25px",
                          }}
                          data-test-id="modal-close-btn"
                        />
        </Box>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            color: "#ffffff",
            fontFamily: 'Josefin Sans',
            textAlign: "center",
          }}
        >
          <Box component="span" sx={{ fontSize: "60px", cursor:'pointer',fontWeight: 900, color: "#ffffff", display: "block", marginTop: "10px" }}>
            Create a Goal
          </Box>

          <Box component="span" sx={{ paddingTop: '8px',cursor:'pointer', fontSize: "60px", fontWeight: 900, color: "#ffffff", display: "block", marginTop: "10px" }}>
            Review Goals
          </Box>
          <Box component="span" sx={{ paddingTop: '8px', cursor:'pointer',fontSize: "60px", fontWeight: 900, color: "#ffffff", display: "block", marginTop: "10px" }}>
            Safer Use Strategies
          </Box>
          <Box component="span" sx={{fontWeight: 900,textUnderlineOffset:'10px',cursor:'pointer', textDecorationThickness:'10px !important', textDecoration: "underline",paddingTop: '8px', fontSize: "60px",  color: "#EBFF00", display: "block", marginTop: "10px" }}>
            Dashboard
          </Box>
        </Box>


      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
 
// Customizable Area End
