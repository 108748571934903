import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start


export const baseURL = require("../../../framework/src/config.js");




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  termsAndCondition:boolean;
  activeStep:number;
  value:number;
  values:number;
  selectValues:number;
  AchievedGoal: boolean;
  openCongrates:boolean;
  showSectionyesandno:boolean;
  openWell:boolean





  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GoalsInformationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  deleteBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  
 
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      termsAndCondition:true,
      activeStep:0,
      value:0,
      values:0,
      selectValues:0,
      openCongrates:false,
      showSectionyesandno:false,
      openWell:false,
     
      AchievedGoal: false,
        // Add more cards as needed
      
     
   
     
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
  

    // Customizable Area End
  }

  // Customizable Area Start
  goToBackDashboard = () =>{
    this.props.navigation.navigate("DashboardInformation")
  }
  hamburgerMenunext(){
    this.props.navigation.navigate("HamburgerMenu")
  }
  handleChange = ( event:any,newValue: any) => {
    this.setState({ value: newValue });
  };
  handleChangesecond = (event:any ,newValues: any) => {
    this.setState({ values: newValues });
  };
  handleChangethird = (event:any ,newValues: any) => {
    this.setState({ selectValues: newValues });
  }
  handleButtonAchievedGoal = () => {
    this.setState((prevState) => ({
      AchievedGoal: !prevState.AchievedGoal, // Toggle the visibility of the section
    }));
  
  };
  handleOpen = () => {
    this.setState({ openCongrates: true });
  };
  handleClose = () => {
    this.setState({ openCongrates: false });
  };
  handleButtonClick = () => {
    this.setState((prevState) => ({
      showSectionyesandno: !prevState.showSectionyesandno, // Toggle the visibility of the section
    }));
  };
  handleClosewell = () => {
    this.setState({ openWell: false });
  };
  handleOpenwell = () => {
    this.setState({ openWell: true });
  };
  // Customizable Area End
}