import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


// Customizable Area Start


export const baseURL = require("../../../framework/src/config.js");




// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectsafeValues:number;
  tabValue:number;
  showSection:boolean;
  showSectionstill:boolean;
  AchievedStrtegies :boolean;
  open: boolean,
  openWell:boolean,
  showAchievedStrategy:any
  openCongratessafer:boolean
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SaferuseStrategiesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  deleteBulkUploadCallId: string = "";
  createBulkUploadCallId: string = "";
  getBulkUploadCallId: string = "";
  maxFileSize = 5e6;
  
 
 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
   
      selectsafeValues:0,
      openCongratessafer:false,
      tabValue:0,
      showSection: false,
      showSectionstill:true,
      AchievedStrtegies:false,open: false,
      openWell:false,
      showAchievedStrategy:null,
    
    
     
   
     
    
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
   
  

    // Customizable Area End
  }

  // Customizable Area Start
  handleStretigies = (event:any ,newValues: any) => {
    this.setState({ selectsafeValues: newValues });
  }
  goToBackDashboards = () =>{
    this.props.navigation.navigate("DashboardInformation")
  }
  handleOpensaferToggle = () => {
    this.setState({ openCongratessafer: true });
  };
  handleClosesaferToggle  = () => {
    this.setState({ openCongratessafer: false });
  };
 
  hamburgerMenusafestr(){
    this.props.navigation.navigate("HamburgerMenu")
  }
  handleChangeSte = ( event:any,newValue: any) => {
    this.setState({ tabValue: newValue });
  };
  handleToggle = (id:any) => {
    this.setState({ showAchievedStrategy: id , openCongratessafer: true,});
   
  };

  handleClosewell = () => {
    this.setState({ openWell: false });
  };
  handleOpenwell = () => {
    this.setState({ openWell: true });
  };
 


 
 
  // Customizable Area End
}