import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
// Customizable Area Start
import MessageEnum, {
    getName,
  } from "../../../framework/src/Messages/MessageEnum";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    checkedItems: any,
    checkedIte: any,
    gridItems: any,
    scrollOffset: number,
    currDate: NumberIndexedObject;
    newDateData: string,
    timeSet: any,
    openCalender: string,
    openTime: boolean,
    valueOther:string,
    selectedDate: string,
    options: any,
    optionsDate:any,
    drinkCount: number,
    drinkCountSecond: number,
    drinkCountSecondOften: number,
    drinkCountOften: number,
    selectedValue: any,
    selectedValueOption: any,
    selectedValueCalendar:any
    // Customizable Area End
}

interface SS {
    id: any;
}
interface NumberIndexedObject {
    [key: string]: string;
}

export default class AlcohalSUbstanceController extends BlockComponent<
    Props,
    S,
    SS
> {
    getCannabisData:string="";
    postCannabis:string=""
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.handleChangeValue = this.handleChangeValue.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            scrollOffset: 0,
            gridItems: [],
            checkedItems: {},
            checkedIte: {},
            options: [],
            optionsDate:[],
            newDateData: "",
            timeSet: '',
            currDate: {},
            openCalender: "",
            openTime: false,
            valueOther:'',
            selectedDate: "",
            selectedValue: 'Reduce how much',
            selectedValueOption: '',
            selectedValueCalendar:'',
            drinkCount: 0,
            drinkCountSecond: 0,
            drinkCountSecondOften: 0,
            drinkCountOften: 0
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.generateOptions();
        this.CannabisGet();
        this.generateOptionsCalendar();
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
        const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    
        if (isResponseMessage) {
          if (this.getCannabisData === responseData && !responseJson.errors) {
            this.setState({valueOther:responseJson?.responses?.answer})
          }
        };    
      }

    formatDate(date:any) {
        const dateMoment = moment(date); 
        const formattedDate = dateMoment.format('ddd, D MMMM'); 
        const isToday = dateMoment.isSame(moment(), 'day'); 
        return isToday ? formattedDate.replace(',', '') : formattedDate;
    }
    
      generateOptionsCalendar() {
        const today = new Date(); 
        const optionsDate = [
            { value: 'today', label: `Today, ${this.formatDate(today)}` },
        ];
        for (let i = 1; i <= 4; i++) {
            const nextDate = new Date(today); 
            nextDate.setDate(today.getDate() + i);
            optionsDate.push({
                value: `nextDay${i}`,
                label: `${this.formatDate(nextDate)}`,
            });
        }
        optionsDate.push({ value: 'other', label: 'Other Day' });
        this.setState({ optionsDate });
    }
   
    handleIncrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount + 1
        }));
    };
    handleIncrementSecond = () => {
        this.setState((earlierState) => ({
            drinkCountSecond: earlierState.drinkCountSecond + 1
        }));
    };

    handleIncrementOften = () => {
        this.setState((prevState) => ({
            drinkCountOften: prevState.drinkCountOften + 1
        }));
    };
    handleIncrementSecondOften = () => {
        this.setState((earlierState) => ({
            drinkCountSecondOften: earlierState.drinkCountSecondOften + 1
        }));
    };
    handleInputChangeOften = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCountOften: Number(value) });
        }
    };
    handleInputChangeSecondOften = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCountSecondOften: Number(value) });
        }
    };

    handleInputChange = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCount: Number(value) });
        }
    };
    handleInputChangeSecond = (event: any) => {
        const answer = event.target.value;
        if (!isNaN(answer) && answer >= 0) {
            this.setState({ drinkCountSecond: Number(answer) });
        }
    };
    handleDecrementOften = () => {
        this.setState((prevState) => ({
            drinkCountOften: prevState.drinkCountOften > 0 ? prevState.drinkCountOften - 1 : 0
        }));
    };
    handleDecrementSecondOften = () => {
        this.setState((prevState) => ({
            drinkCountSecondOften: prevState.drinkCountSecondOften > 0 ? prevState.drinkCountSecondOften - 1 : 0
        }));
    };
    handleDecrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount > 0 ? prevState.drinkCount - 1 : 0
        }));
    };
    handleDecrementSecond = () => {
        this.setState((prevState) => ({
            drinkCountSecond: prevState.drinkCountSecond > 0 ? prevState.drinkCountSecond - 1 : 0
        }));
    };
    handleChangeValue(event:any){
      this.setState({valueOther:event?.target?.value})
    }
    CannabisPost= () => {
        let data = localStorage.getItem("token") || "{}"
        const dataSend = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const httpBody = {
            substance_goal_response: {
                category_name: "Others",
                responses: {
                    answer:this.state.valueOther
                }
            }
        };
        const postCannabisData = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.postCannabis = postCannabisData.messageId;
    
        postCannabisData.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.postOthers}`
        );
    
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          dataSend
        );
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.postApiMethodType
        );
        postCannabisData.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(httpBody)
        );
        runEngine.sendMessage(postCannabisData.id, postCannabisData);
        
    }
      
    CannabisGet = () => {
        let data = localStorage.getItem("token") || "{}";
        const header = {
          "Content-Type": configJSON.validationApiContentType,
          token: data
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getCannabisData = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.endOthersPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.getApiMethodType
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    
      };
    generateOptions() {

        const options = [
            { value: 'Reduce how much', label: `Reduce how much` },
        ];
        options.push({ value: 'Reduce how often', label: 'Reduce how often' });
        options.push({ value: 'Safer/healthier', label: 'Safer/healthier' });
        options.push({ value: 'Stop completely', label: 'Stop completely' });
        options.push({ value: 'Other', label: 'Other' });
        this.setState({ options });
    }
    handleChange = (event: any) => {
        this.setState({ selectedValue: event.target.value });
    };
    handleChangeOption = (event: any) => {
        this.setState({ selectedValueOption: event.target.value });
    };
    handleChangeCalendar= (event: any) => {
        this.setState({ selectedValueCalendar: event.target.value });
    };

}
