import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import dayjs from 'dayjs';
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  checkedItems:any,
  formattedDateInter:string,
  valueOther:string,
  currDate: NumberIndexedObject;
  newDateData: string,
  timeSet:any,
  openCalender: string,
  openTime:boolean
  selectedDate:string,
  setTimeValue:string,
  options: any,
  selectedValue:any,
  getGoalsComplete:any,
  setValue:any,
  setCheckbox:boolean
  // Customizable Area End
}

interface SS {
  id: any;
}
interface NumberIndexedObject {
  [key: string]: string;
}

export default class GoalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getGoalsHandleValue=""
  postCategoryKey=""
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      checkedItems:{},
      options: [], 
      newDateData: "",
      timeSet:'',
      currDate: {},
      formattedDateInter:'',
      valueOther:'',
      openCalender: "",
      openTime:false,
      selectedDate:"",
      setTimeValue:'',
      selectedValue:'',
      getGoalsComplete:[],
      setValue:'',
      setCheckbox:false
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  async componentDidMount() {
    this.generateOptions();
    this.GoalsGet();
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const isResponseMessage = getName(MessageEnum.RestAPIResponceMessage) === message.id;
    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

    if (isResponseMessage) {
   
      if (this.getGoalsHandleValue === responseData && !responseJson.errors) {
        this.setState({
          getGoalsComplete: responseJson,
          setValue:responseJson?.goals?.goal_description,
          setCheckbox:responseJson?.goals?.daily_goal,
          formattedDateInter:responseJson?.goals?.target_date,
          timeSet:responseJson?.goals?.reminder_time
        });
      }
    };    
  }

  // Customizable Area Start
  introductionPage(){
    this.props.navigation.navigate("Categoriessubcategories")

  }
  OtherGoals(){
     this.props.navigation.navigate("OtherGoals")
  }
  hamburgerMenu(){
    this.props.navigation.navigate("HamburgerMenu")
  }
 
  formatDate(date:any) {
    const dateMoment = moment(date); 
    const formattedDate = dateMoment?.format('ddd, D MMMM'); 
    const isToday = dateMoment?.isSame(moment(), 'day'); 
    return isToday ? formattedDate?.replace(',', '') : formattedDate;
}

  GoalsSaveHandle = () => {
    let data = localStorage.getItem("token") || "{}"
    const dataSend = {
      "Content-Type": configJSON.getUserListApiContentType,
      token: data
    };
    const httpBody = {
      substance_related_goal: {
        goal_description: this.state.setValue,
        target_date:this.state.formattedDateInter,
        reminder_time: this.state.timeSet ,
        daily_goal:this.state.setCheckbox
      }
    };

    const getCategory = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.postCategoryKey = getCategory.messageId;

    getCategory.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.goalsEndPoint}`
    );

    getCategory.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      dataSend
    );
    getCategory.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addUserApiMethod
    );
    getCategory.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    runEngine.sendMessage(getCategory.id, getCategory);
    this.props.navigation.navigate("CustomForm")
  }
  GoalsGet = () => {
  let data = localStorage.getItem("token") || "{}";
  const header = {
    "Content-Type": configJSON.getUserListApiContentType,
    token: data
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.getGoalsHandleValue = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.goalsEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.getUserListApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  };


generateOptions() {
  const today = new Date();
  const options = [
      { 
          value: 'today', 
          label: `Today, ${this.formatDate(today)}`, 
          date: this.formatDateYYYYMMDD(today) 
      },
  ];
  for (let i = 1; i <= 4; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      options.push({
          value: `nextDay${i}`,
          label: `${this.formatDate(nextDate)}`,
          date: this.formatDateYYYYMMDD(nextDate) // Add the new field here
      });
  }
  options.push({ value: 'other', label: 'Other Day', date: '' }); // Add date field for 'Other Day'
  this.setState({ options });
}

formatDateYYYYMMDD(date:any) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); 
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}
  handleInputChange = async (event: any) => {
    const value = event?.target?.value;
    this.setState({ setValue: value })
  };

  handleChange = (event:any) => {
      this.setState({ formattedDateInter: event.date })
};
goalsPage(){
  this.props.navigation.navigate("Goals")
}

openPopUp(key: number) {
  this.setState({
    openCalender: key.toString()
  });
}
openTimer() {
  this.setState({
    openTime: true
  });
}

closePopUp() {
  this.setState({
    openCalender: ""
  });
}

  handleCheckboxChange = () => {
    if(this.state.setCheckbox){
      this.setState({setCheckbox:false})
    }
    else{
      this.setState({setCheckbox:true})
    }
  }
  substancePage(){
    this.props.navigation.navigate("SubstanceGoals")
  }
  convertToDayjsDate(dateString:string) {
    if (!dateString) return null; 
    return dayjs(dateString, 'DD/MM/YYYY'); 
  }
  handleDate(e: any) {
    const date = dayjs.isDayjs(e) ? e : dayjs(e);
    if (date.isValid()) {
      let finalDate = date.format("DD/MM/YYYY"); 
      this.setState({
        newDateData: finalDate
      });
    } 
  }
  handleTime(e:any){
    this.setState({
      timeSet: e,
      openTime: false
  });
  }
  handleData(key: any) {
    if (!key) {
      return "Select Date";
    } else {
      return key;
    }
  }
  handlePopupData(key: any) {
    if (key) {
      return true;
    } else {
      return false;
    }
  }
  handlePopupTime(keyAnswer: any) {
    if (keyAnswer) {return true;} else {
      return false;
    }
  }
  customForm(){
   this.GoalsSaveHandle();
  }
  confirmCal() {
    this.setState({formattedDateInter:this.state.selectedDate, openCalender: "",})
  }
  handleDataCalendar=(newDate:any)=>{
    const formattedDate = dayjs(newDate.$d).format('YYYY/MM/DD');
    this.setState({selectedDate:formattedDate})
  }
  handleDataChangeTime = (newDate: any) => {
    const formattedDate = dayjs(newDate).format('hh:mm A');
    this.setState({ setTimeValue: formattedDate });
  };
  handleUserTime=()=>{
    this.setState({timeSet:this.state.setTimeValue})
    this.setState({openTime:false})
  }
  handleClose=()=>{
    this.setState({openTime:false})
  }

  // Customizable Area End
}
