import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    options: any,
    subOptions:any,
    drinkCount: number,
    drinkCountSecond: number,
    drinkCountSecondOften: number,
    cannabisCount:number,
    drinkCountOften: number,
    CannabisSecond:number,
    selectedDate: string,
    optionsDate:any,
    selectedValue: any,
    selectedValueSubOption:any,
    selectedValueOption: any,
    selectedValueCalendar:any
}

interface SS {
    id: any;
}
interface NumberIndexedObject {
    [key: string]: string;
}

export default class CannabisController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
        ];

        this.state = {
            options: [],
            subOptions:[],
            optionsDate:[],
            selectedValue: 'Reduce how much',
            selectedValueSubOption:'Ounces',
            selectedValueOption: '',
            selectedValueCalendar:'',
            drinkCount: 0,
            drinkCountSecond: 0,
            drinkCountSecondOften: 0,
            CannabisSecond:0,
            selectedDate: "",
            cannabisCount:0,
            drinkCountOften: 0
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async componentDidMount() {
        this.generateOptions();
        this.generateOptionsCalendar();
        this.generateSubOptions();
        
    }
    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
    }
    generateOptionsCalendar() {
        const today = new Date(); 
        const optionsDate = [
            { value: 'today', label: `Today, ${this.formatDate(today)}` },
        ];
        for (let i = 1; i <= 4; i++) {
            const nextDate = new Date(today); 
            nextDate.setDate(today.getDate() + i);
            optionsDate.push({
                value: `nextDay${i}`,
                label: `${this.formatDate(nextDate)}`,
            });
        }
        optionsDate.push({ value: 'other', label: 'Other Day' });
        this.setState({ optionsDate });
    }
    formatDate(date:any) {
        const dateMoment = moment(date); 
        const formattedDate = dateMoment.format('ddd, D MMMM'); 
        const isToday = dateMoment.isSame(moment(), 'day'); 
        return isToday ? formattedDate.replace(',', '') : formattedDate;
    }
    handleCannabisSecondInc= () => {
        this.setState((prevState) => ({
            CannabisSecond: prevState.CannabisSecond + 1
        }));
    };
    handleIncrementCannabis = () => {
        this.setState((prevState) => ({
            cannabisCount: prevState.cannabisCount + 1
        }));
    };
    
     
    handleIncrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount + 1
        }));
    };
    handleIncrementSecond = () => {
        this.setState((earlierState) => ({
            drinkCountSecond: earlierState.drinkCountSecond + 1
        }));
    };

    handleIncrementOften = () => {
        this.setState((prevState) => ({
            drinkCountOften: prevState.drinkCountOften + 1
        }));
    };
    handleIncrementSecondOften = () => {
        this.setState((earlierState) => ({
            drinkCountSecondOften: earlierState.drinkCountSecondOften + 1
        }));
    };
    handleInputChangeOften = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCountOften: Number(value) });
        }
    };
    handleInputChangeSecondOften = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCountSecondOften: Number(value) });
        }
    };
    handleInputCannabisChange = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ cannabisCount: Number(value) });
        }
    };
    handleInputChange = (event: any) => {
        const value = event.target.value;
        if (!isNaN(value) && value >= 0) {
            this.setState({ drinkCount: Number(value) });
        }
    };
    handleInputChangeSecond = (event: any) => {
        const answer = event.target.value;
        if (!isNaN(answer) && answer >= 0) {
            this.setState({ drinkCountSecond: Number(answer) });
        }
    };
    handleDecrementOften = () => {
        this.setState((prevState) => ({
            drinkCountOften: prevState.drinkCountOften > 0 ? prevState.drinkCountOften - 1 : 0
        }));
    };

    handleCannabisSecondDec=() => {
        this.setState((prevState) => ({
            CannabisSecond: prevState.CannabisSecond > 0 ? prevState.CannabisSecond - 1 : 0
        }));
    };

    handleDecrementSecondOften = () => {
        this.setState((prevState) => ({
            drinkCountSecondOften: prevState.drinkCountSecondOften > 0 ? prevState.drinkCountSecondOften - 1 : 0
        }));
    };
    handleCannabisDecrement=()=>{
        this.setState((prevState) => ({
            cannabisCount: prevState.cannabisCount > 0 ? prevState.cannabisCount - 1 : 0
        }));
    };
    
    handleDecrement = () => {
        this.setState((prevState) => ({
            drinkCount: prevState.drinkCount > 0 ? prevState.drinkCount - 1 : 0
        }));
    };
    handleDecrementSecond = () => {
        this.setState((prevState) => ({
            drinkCountSecond: prevState.drinkCountSecond > 0 ? prevState.drinkCountSecond - 1 : 0
        }));
    };


    generateOptions() {

        const options = [
            { value: 'Reduce how much', label: `Reduce how much` },
        ];
        options.push({ value: 'Reduce how often', label: 'Reduce how often' });
        options.push({ value: 'Safer/healthier', label: 'Safer/healthier' });
        options.push({ value: 'Stop completely', label: 'Stop completely' });
        options.push({ value: 'Other', label: 'Other' });
        this.setState({ options });
    }
    generateSubOptions() {
        const subOptions = [
            { value: 'Ounces', label: `Ounces` },
        ];
        subOptions.push({ value: '$$', label: '$$', });
        subOptions.push({ value: 'Hits', label: 'Hits' });
        this.setState({ subOptions });
    }
    handleChange = (event: any) => {
        this.setState({ selectedValue: event.target.value });
    }
    handleInputCannabisSecond=(event: any) => {
        this.setState({ CannabisSecond: event.target.value });
    }
    handleChangeSubOption=(event:any)=>{
        this.setState({ selectedValueSubOption: event.target.value });
    }
    handleChangeOption = (event: any) => {
        this.setState({ selectedValueOption: event.target.value });
    };
    handleChangeCalendar= (event: any) => {
        this.setState({ selectedValueCalendar: event.target.value });
    };

}
