import React from 'react';
import {
    Grid,
    Box,
    Typography,
    Radio, FormControlLabel,
    FormControl,
    Button,
    Checkbox,
    TextField, Backdrop, CardActions, Card, CardContent
} from "@mui/material";
import GoalsController, {
    Props,
} from './GoalsController.web';
import { arrow, edit, quotes, three, substanceBottom, arrowright, time, calendar, menuIcon } from './assets';
import moment from 'moment';
import DatePickerData from '../../landingpage/src/DatePickerData.web';
import TimePickerData from '../../landingpage/src/TimePickerData.web';



export const configJSON = require("./config.js");


export default class SubstanceGoals extends GoalsController {
    constructor(props: Props) {
        super(props);

    }
    render() {

        
        const doesMatchFirstFive = this.state?.options
            .slice(0, 5)
            .some((opt: any) => opt.date === this.state?.formattedDateInter);

        const shouldSelectEmptyDate = !doesMatchFirstFive;
       
      
        return (
            <Grid
                item
                style={{
                    overflowY: 'scroll',
                    height: '100%',
                    backgroundColor: "#F0F0F0",
                }}
            >
                <Grid
                    item
                    style={{
                        height: '88px',
                        backgroundColor: '#0F4257',
                        borderRadius: '16px',
                        width: '98.5%',
                        margin: '10px',
                    }}
                >
                    <Box
                        sx={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            display: "flex",
                            padding: "0 20px",
                            height: "100%"
                        }}>
                        <Box
                            sx={{
                                gap: "20px",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Box
                                sx={{
                                    fontFamily: "Josefin Sans",
                                    color: "#ffffff",
                                    fontSize: "40px",
                                    fontWeight: 300,
                                }} >
                                <span
                                    style={{
                                        marginLeft: "40px",
                                        fontFamily: "Josefin Sans",
                                        lineHeight: "40px",
                                        fontWeight: 700,
                                        marginBottom: "4px",
                                        color: "#EBFF00",
                                        fontSize: "40px",
                                    }}
                                >
                                    Well
                                </span>
                                Spring
                            </Box>
                            <Typography
                                sx={{
                                    mt: "3px",
                                    fontSize: "20px",
                                    lineHeight: "20px",
                                    color: "#FFFFFF",
                                    fontFamily: "Chromatica-Bold",
                                    fontWeight: 700,

                                }}
                            >
                                Hey, Tom Chandler</Typography>
                        </Box>
                        <Box
                            src={menuIcon.default}
                            onClick={() => { this.hamburgerMenu() }}
                            component="img"
                            sx={{
                                cursor: "pointer",
                                marginRight: "70px",
                            }}
                            alt="icon"
                        />
                    </Box>
                </Grid>

                <Grid
                    item
                    style={{
                        marginTop: '10px',
                        margin: '10px',
                        height: 'auto',
                        minHeight: '100vh',
                        width: '98.5%',
                        marginBottom: '10px',
                        borderRadius: '15px',
                        backgroundColor: 'white',
                        position: 'relative',

                    }}
                >
                    <Box onClick={() => this.goalsPage()} sx={{ cursor: 'pointer', paddingTop: '30px' }} data-test-id="back-btn-goals">
                        <Box
                            alt="Back Button"
                            sx={{ paddingTop: '6px', left: 60, position: "absolute", }}
                            component="img"
                            src={arrow.default}
                        />
                        <Typography
                            sx={{
                                cursor: "pointer",
                                fontFamily: 'Chromatica-Bold',
                                left: 82,
                                color: '#252525',
                                position: "absolute",
                            }}>
                            Back</Typography>
                    </Box>

                    <Box sx={{ top: 19, marginLeft: '50px', position: "relative", }}>
                        <Box
                            alt="Img"
                            component="img"
                            src={three.default}
                            sx={{ width: '120px', height: '180px' }}
                        />
                        <Typography
                            sx={{
                                marginTop: '112px',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                fontFamily: 'Josefin Sans',
                                fontWeight: '900',
                                fontSize: '32px',
                                color: '#00A7D1'
                            }}
                        >
                            Substance Related Goals
                        </Typography>
                        <Typography sx={{
                            color: '#252525',
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            marginBottom: '20px'
                        }}>
                            This is a place for you to make your substance use goals even more concrete by setting them for a certain day.
                        </Typography>
                        <Typography sx={{
                            paddingBottom: '10px',
                            fontFamily: 'Chromatica-Bold',
                            fontSize: '20px',
                            color: '#252525'
                        }}>You said you wanted to..</Typography>
                        <div style={{ position: 'relative', width: '691px', marginBottom: '15px' }}>
                            <TextField
                                value={this.state.setValue}
                                onChange={this.handleInputChange}
                                defaultValue="Reduce your typical drinking amount from 6 to 3 drinks a day!"
                                style={{
                                    width: '100%',
                                    height: '107px',
                                    background: `url(${quotes.default})`,
                                    backgroundSize: 'auto',
                                    backgroundPosition: 'top left',
                                    backgroundRepeat: 'no-repeat',
                                    borderRadius: '16px',
                                }}
                                inputProps={{
                                    style: {
                                        fontSize: '16px',
                                        fontFamily: 'Chromatica-Regular',
                                    }
                                }}
                                sx={{
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: '16px',
                                        height: '100%',
                                        '&:hover fieldset': {
                                            borderColor: '#E3E3E3',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#E3E3E3',
                                        },
                                    },
                                    '& .MuiInputBase-input': {
                                        padding: '10px',
                                    },
                                }}
                            />
                            <div style={{
                                position: 'absolute',
                                bottom: '10px',
                                right: '10px',
                                display: 'flex',
                                alignItems: 'center',
                            }}>
                                <img src={edit.default} alt="Edit Goal" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                                <span style={{
                                    color: '#00A7D1',
                                    fontSize: '16px',
                                    fontFamily: 'Chromatica-Regular',

                                }}>
                                    Edit Goal
                                </span>
                            </div>
                        </div>
                        <div>
                            <Typography sx={{
                                color: '#252525',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                                marginTop: '30px',
                                marginBottom: '20px'
                            }}>
                                When do you want to make it happen?
                            </Typography>
                            <Backdrop open={this.handlePopupData(this.state.openCalender)} style={{ zIndex: 100, color: '#FFF' }}>
                                {this.state.openCalender && (

                                    <Card style={{
                                        padding: '25px 10px 15px 10px', width: '400px', display: 'flex', flexDirection: 'column',
                                        borderRadius: '16px',
                                        gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center'
                                    }}>

                                        <DatePickerData onChange={this.handleDataCalendar} navigation={undefined} id={''} onAccept={undefined} value={undefined} onClose={undefined} />
                                        <CardActions style={{ display: 'flex', gap: '26px' }}>
                                            <Button style={{
                                                background: '#FFFFFF', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '43px',
                                                border: '1px solid #00A7D1',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#0075BF',
                                                borderRadius: '8px',
                                            }}

                                                onClick={() => this.closePopUp()}
                                                data-test-id="closebtn"
                                            > Back
                                            </Button>
                                            <Button style={{
                                                background: '#00A7D1', fontFamily: 'Chromatica-Bold', fontSize: '14px',
                                                textAlign: 'center', textTransform: 'none', width: '90px', height: '40px',
                                                boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)', color: '#FFFFFF',
                                                borderRadius: '8px',
                                            }}
                                                data-test-id="confirmBtns"
                                                onClick={() => this.confirmCal()}
                                            > Confirm
                                            </Button>
                                        </CardActions>
                                    </Card>
                                )}
                            </Backdrop>
                            <FormControl component="fieldset" style={{ width: '100%' }}>
                                <div style={{
                                    flexWrap: 'wrap',
                                    display: 'flex',
                                    justifyContent: 'start',
                                    gap: '10px'
                                }}>
                                    {this.state.options.map((option: any) => {
                                        const isSelected = this.state.formattedDateInter === option.date || (shouldSelectEmptyDate && option.date === '');
                                        return (
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'inline-flex',
                                                    border: isSelected ? '1px solid #00A7D1' : '1px solid #E3E3E3',
                                                    backgroundColor: isSelected ? '#EFF8FB' : 'transparent',
                                                    borderRadius: '32px',
                                                    padding: '8px 12px',
                                                    transition: 'background-color 0.3s, border-color 0.3s',
                                                    whiteSpace: 'nowrap',
                                                }}
                                                key={option.value}
                                            >
                                                <FormControlLabel
                                                    label={<span style={{ fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>{option.label}</span>}
                                                    control={
                                                        <Radio
                                                            sx={{
                                                                '&.Mui-checked': {
                                                                    color: '#00A7D1',
                                                                },
                                                                color: '#E3E3E3',
                                                            }}
                                                            onChange={() => this.handleChange(option)}
                                                            value={option.value}
                                                            checked={isSelected}
                                                        />
                                                    }
                                                />
                                            </Box>
                                        );
                                    })}
                                </div>
                            </FormControl>
                            {(this.state.valueOther === 'other' || shouldSelectEmptyDate) && (
                                <div style={{ marginTop: '20px', alignItems: 'center', display: 'flex', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                    <input
                                    data-test-id="popip"
                                        onClick={() => this.openPopUp(1)}
                                        type="text"
                                        placeholder="Choose Day"
                                        value={shouldSelectEmptyDate ? this.state.formattedDateInter : ''}
                                        style={{
                                            outline: 'none',
                                            width: '100%',
                                            fontFamily: 'Chromatica-Regular',
                                            fontSize: '16px',
                                            padding: '10px 0',
                                            border: 'none',
                                            marginRight: '10px',
                                            backgroundColor: 'transparent',
                                        }}
                                        readOnly
                                    />
                                    <img
                                      data-test-id="popimg"
                                        onClick={() => this.openPopUp(1)}
                                        src={calendar.default}
                                        alt="Calendar"
                                        style={{ cursor: 'pointer', width: '24px', height: '24px' }}
                                    />
                                </div>
                            )}
                        </div>
                        <Typography sx={{
                            color: '#252525',
                            fontFamily: 'Chromatica-Regular',
                            fontSize: '16px',
                            marginBottom: '10px',
                            marginTop: '30px'
                        }}
                        > At what time you need to be reminded?</Typography>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', marginTop: '20px', width: '286px', borderBottom: '1px solid #E3E3E3' }}>
                                <input
                                    placeholder="Select Time"
                                    value={this.state.timeSet}
                                    type="text"
                                    style={{

                                        border: 'none',
                                        width: '100%',
                                        fontFamily: 'Chromatica-Regular',
                                        fontSize: '16px',
                                        padding: '10px 0',
                                        outline: 'none',
                                        marginRight: '10px',
                                        backgroundColor: 'transparent',
                                    }}
                                    readOnly
                                />
                                <img
                                data-test-id="timer"
                                onClick={()=>{this.openTimer()}}
                                    src={time.default}
                                    alt="Open Calendar"
                                    style={{ cursor: 'pointer', width: '24px', height: '24px' }}

                                />
                            </div> {this.state.timeSet && (
                                <span style={{ marginLeft: '10px', color: '#189718', fontFamily: 'Chromatica-Regular', fontSize: '16px' }}>
                                    {`Great! We'll remind you at ${this.state.timeSet}`}
                                </span>
                            )}
                        </div>

                        <Backdrop open={this.handlePopupTime(this.state.openTime)} style={{ zIndex: 100, color: '#FFF' }}>
                            {this.state.openTime && (
                                <Card style={{
                                    gap: '24px', backgroundColor: '#FFFFFF', alignItems: 'center',
                                    width: '328px', height: '450px', display: 'flex', flexDirection: 'column',
                                    borderRadius: '28px', padding: '25px 10px 15px 10px',
                                }}>
                                    <CardContent style={{
                                        gap: '35px', alignItems: 'center', height: "415px",
                                        display: 'flex', flexDirection: 'column',
                                    }}>
                                   <TimePickerData value={this.state.setTimeValue} onAccept={this.handleUserTime} onChange={this.handleDataChangeTime} navigation={undefined} id={''} onClose={this.handleClose}/>
                                    </CardContent>
                                </Card>
                            )}
                        </Backdrop>
                        <div style={{ display: 'flex' }}>
                            <Checkbox
                                checked={this.state.setCheckbox || false}
                                onChange={() => this.handleCheckboxChange()}
                                sx={{

                                    color: this.state.setCheckbox ? '#00A7D1' : '#E3E3E3',
                                    '&.Mui-checked': {
                                        color: '#00A7D1',
                                    },
                                    marginBottom: '10px',
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '6px',
                                }}
                            />
                            <Typography sx={{

                                color: '#252525',
                                marginLeft: '10px',
                                fontFamily: 'Chromatica-Regular',
                                fontSize: '16px',
                            }}
                            >  Do you want to make it happen on a daily basis?</Typography>
                        </div>

                        <Button
                            onClick={() => { this.customForm() }}
                            variant="contained"
                            sx={{
                                padding: "10px",
                                borderRadius: "16px",
                                boxShadow: 'none',
                                background:
                                    "linear-gradient(91.40deg, #BACA08 0%, #00A7D1 100%)",
                                textTransform: "none",
                                backgroundColor: '#EFF8FB',
                                fontSize: "18px",
                                lineHeight: "20px",
                                marginTop: '30px',
                                marginBottom: '30px',
                                cursor: 'pointer',
                                width: '348px',
                                height: '56px',
                                fontWeight: 400,
                                fontFamily: 'Chromatica-Bold',
                            }}
                            data-test-id="wellspring"
                        >
                            <span style={{ marginRight: '5px' }}>Next</span>
                            <img src={arrowright.default} />
                        </Button>
                        <Box
                            sx={{
                                height: "auto",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                px: 4,
                            }}
                        >

                        </Box>
                    </Box>
                    <Box
                        alt="image" component="img" src={substanceBottom.default}
                        sx={{
                            bottom: 0,
                            right: 0,
                            width: '100%',
                            position: "absolute",
                            height: 'auto',
                            maxWidth: '600px',
                        }}

                    />
                </Grid>
            </Grid>
        )
    }
}

